var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"TicketSetup"}},[_c('v-card',{staticClass:"mx-8 my-3 style-card",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":"","color":"primary"},on:{"click":_vm.clearFilter}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-broom ")]),_vm._v("Filters ")],1)]}}])},[_c('span',[_vm._v("Clear all filters")])]),(_vm.showAxessTickets)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showAxessTickets)?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","dark":"","loading":_vm.loadingBtn},on:{"click":_vm.exportAxessConfig}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-database-export ")]),_vm._v("Export ")],1):_vm._e()]}}],null,false,1725796150)},[_c('span',[_vm._v("Export to Promtec config ")])]):_vm._e(),(_vm.showAxessTickets)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showAxessTickets)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":"","loading":_vm.loadingBtn},on:{"click":_vm.reLoadAxessConfig}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-database-refresh ")]),_vm._v("Reload ")],1):_vm._e()]}}],null,false,9501029)},[_c('span',[_vm._v("Reload Table")])]):_vm._e()],1),(!_vm.showAxessTickets)?_c('tableMultifilter',{ref:"tableList",attrs:{"headers":_vm.headersPromtec,"desserts":_vm.configPromtec,"idkey":'row_num',"showSelect":_vm.showSelect,"loading":_vm.loadingList},on:{"clicRowTable":_vm.clicRowTable}}):_c('tableMultifilter',{ref:"tableRowCheck",attrs:{"headers":_vm.headersAxess,"desserts":_vm.configAxess,"idkey":'row_num',"showSelect":true,"loading":_vm.loadingBtn},on:{"getRowCheck":_vm.getRowCheck}})],1),_c('v-dialog',{ref:"formDialog",attrs:{"max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"background":"#272727","color":"white"}},[_c('span',{staticClass:"text-h7"},[_vm._v("Editar Registro")])]),_c('v-card-text',[_c('formularyPromtec',{ref:"formPromtec",attrs:{"ticketInfo2":_vm.ticketInfo,"entities":_vm.entities,"sites":_vm.sites},on:{"dialogClose":_vm.dialogClose,"formPromtecSave":_vm.formPromtecSave}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }