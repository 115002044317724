<template>
  <div id="ShipmentPending">
    <v-toolbar
      height="45px"
      dark
      elevation="0"
      color="#1e1e1e"
    >
      <v-spacer />
      <div class="mr-4">
        <InputDatePickerReport
          v-model="selectedDate"
          :forcedMax="yesterdayDate"
          :disabled="loading"
          @change="getTransactionsDate"
        />
      </div>
      <v-text-field
        v-model="search"
        placeholder="Escribe lo que deseas buscar"
        v-bind="attrsSearching"
        :disabled="loading || !items.length"
      />
    </v-toolbar>

    <div class="content-with-fixed-toolbar">
      <v-card>
        <v-card-text>
          <v-skeleton-loader
            v-if="loading"
            type="table"
          />
          <v-alert
            v-if="!loading && !items.length"
            class="mb-0"
            icon="mdi-folder-alert-outline"
            prominent
            text
            type="warning"
          >
            No hay bitacoras para el día {{ selectedDate }}
          </v-alert>
          <v-data-table
            v-if="!loading && items.length"
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            multi-sort
            mobile-breakpoint="Infinity"
          >
            <template v-slot:body="{ items: itemsSearched }">
              <tbody v-if="!itemsSearched.length">
                <td colspan="20">
                  <v-alert
                    class="mb-0 text"
                    align="center"
                  >
                    Sin datos para esta búsqueda
                  </v-alert>
                </td>
              </tbody>
              <tbody v-if="itemsSearched.length">
                <tr
                  v-for="item in itemsSearched"
                  :key="`shipment-pending-${item.sucursal}-${item.name}-${item.caja}-${item.IDCajero}`"
                >
                  <td align="center">
                    {{ item.sucursal }}
                  </td>
                  <td align="center">
                    {{ item.name }}
                  </td>
                  <td align="center">
                    {{ item.caja }}
                  </td>
                  <td align="center">
                    {{ item.IDEquipo }}
                  </td>
                  <td align="center">
                    {{ item.IDCajero }}
                  </td>
                  <td align="center">
                    {{ item.NombreCajero }}
                  </td>
                  <td
                    v-if="userData.subrol != 4"
                    align="center"
                  >
                    <v-btn
                      small
                      dark
                      color="red"
                      class="ma-2"
                      @click="sentNoRegistBitaAafy(item)"
                    >
                      <v-icon>mdi-cloud-upload</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
// Components
import InputDatePickerReport from '../tools/reports/InputDatePickerReport.vue'

export default {
  name: 'ShipmentPending',
  components: { InputDatePickerReport },
  props: ['yesterdayDate', 'isActive'],
  data () {
    return {
      attrsSearching: {
        label: '',
        outlined: true,
        dark: true,
        clearable: true,
        dense: true,
        color: 'orange',
        'prepend-icon': 'mdi-magnify',
        class: 'search-text-field',
        'hide-details': true
      },
      selectedDate: null,
      search: '',
      loading: false,
      items: [],
      headers: [
        { text: 'Sucursal', value: 'sucursal', width: '', filterable: true },
        { text: 'Nombre Sucursal', value: 'name', width: '', filterable: true },
        { text: 'Caja', value: 'caja', width: '', filterable: true },
        { text: 'Nombre Caja', value: 'IDEquipo', width: '', filterable: true },
        { text: 'Cajero', value: 'IDCajero', width: '', filterable: true },
        { text: 'Nombre Cajero', value: 'NombreCajero', width: '', filterable: true },
        { text: 'Enviar', value: '', width: '', filterable: false }
      ]
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData'
    })
  },
  watch: {
    isActive (val) {
      if (val) this.getTransactionsDate()
    }
  },
  created () {
    this.selectedDate = this.yesterdayDate
    this.getTransactionsDate()
  },
  methods: {
    ...mapMutations({
      setLoadingMainApp: 'globalStates/setLoadingMainApp',
      setTextLoadingMainApp: 'globalStates/setTextLoadingMainApp'
    }),
    getTransactionsDate () {
      this.loading = true
      this.items = []
      this.search = ''

      const data = { selectedDate: this.selectedDate }
      this.$root.post('/aafy/POSNoSent', data)
        .then((response) => {
          const data = response?.data
          if (data.success === 'FALSE') return this.$swalAlert('error', null, data.message)

          this.items = data || []
        })
        .finally(() => { this.loading = false })
    },
    sentNoRegistBitaAafy: async function name (item) {
      const response = await this.$ConfirmBox('warning', '¿Desea enviar la informacion?')
      if (!response?.isConfirmed) return

      const date = {
        selectedDate: this.selectedDate,
        place: item.sucursal,
        pos: item.caja,
        cashier: item.IDCajero
      }

      this.setLoadingMainApp(true)
      this.setTextLoadingMainApp('Enviando')

      this.$root.post('/aafy/sendPOS', date)
        .then((response) => {
          const info = response?.data
          if (info.success === 'FALSE') return this.$swalAlert('error', info.message, null)

          this.$swalAlert('success', 'Enviado correctamente')
        }).finally(() => {
          this.setLoadingMainApp(false)
          this.setTextLoadingMainApp('')
          setTimeout(() => {
            this.getTransactionsDate()
          }, 100)
        })
    }
  }
}
</script>

<style scoped>
.search-text-field {
  max-width: 800px !important;
}

.content-with-fixed-toolbar {
  height: calc(100vh - var(--h-navbar) - 45px - 45px);
  overflow-y: auto;
  padding: 20px;
}
</style>

<style scoped src="../../assets/css/style-tables-dark.css">
</style>
