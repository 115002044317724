<template>
  <div id="ReportTicketSalesAllPlaces">
    <TitleReportsCard
      title="Boletos vendidos con detalle de todos los Paradores"
      :loading="loading"
      @generateReport="generateReport"
    >
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateStart"
          :newMax="dateEnd"
          label="Seleccione una fecha de inicio"
          type="month"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateEnd"
          label="Seleccione una fecha de fin"
          type="month"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
      <v-col
        v-if="this.canEditEntity"
        cols="12"
        sm="12"
        md="6"
      >
        <v-select
          v-model="entitySelected"
          prepend-icon="mdi-office-building"
          dense
          outlined
          :items="entities"
          name="id"
          item-text="name"
          item-value="id"
          label="Entidad"
          background-color="white"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
    </TitleReportsCard>

    <ContentReportsCard
      :loading="loading"
      :generated="generated"
      :fileName="fileName"
      :noData="!ticketsDetails.length"
    >
      <ReportTicketSalesAllPlacesTable
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :titleReport="getTitleReport"
        :ticketsDetails="ticketsDetails"
        :soldTickets="soldTickets"
        :exchangedTickets="exchangedTickets"
        :totalTickets="totalTickets"
        :totalSales="totalSales"
      />
    </ContentReportsCard>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

// Components
import TitleReportsCard from '../tools/reports/TitleReportsCard.vue'
import InputDatePickerReport from '../tools/reports/InputDatePickerReport.vue'
import ContentReportsCard from '../tools/reports/ContentReportsCard.vue'
import ReportTicketSalesAllPlacesTable from './ReportTicketSalesAllPlacesTable.vue'

export default {
  name: 'ReportTicketSalesAllPlaces',
  components: { TitleReportsCard, InputDatePickerReport, ContentReportsCard, ReportTicketSalesAllPlacesTable },
  data () {
    return {
      dateStart: '',
      dateEnd: '',
      loading: false,
      generated: false,
      entitySelected: null,
      ticketsDetails: [],
      soldTickets: 0,
      exchangedTickets: 0,
      totalTickets: 0,
      totalSales: 0
    }
  },
  computed: {
    ...mapState('dashboard', ['entities']),
    ...mapGetters({
      userData: 'auth/userData'
    }),
    canEditEntity: function () {
      return this.userData.rol === 0
    },
    nameEntitySelected: function () {
      let name = ''
      this.entities.forEach(element => {
        if (element.id === this.entitySelected) name = element.name
      })
      return name
    },
    fileName: function () {
      return 'Boletos_vendidos_todos_paradores_' + this.nameEntitySelected + '_del_' + this.dateStart + '_hasta_' + this.dateEnd
    },
    getTitleReport: function () {
      return `Boletos vendidos de todos los paradores de "${this.nameEntitySelected}"`
    }
  },
  mounted () {
    this.dateStart = this.$sumarDias2(new Date())
    this.dateEnd = this.$sumarDias2(new Date())

    if (!this.canEditEntity) {
      this.entitySelected = this.userData.rol
    }
  },
  methods: {
    generateReport: function () {
      if (!this.entitySelected) {
        this.$swalAlert('warning', 'Seleccione un entidad!')
        return
      }

      this.ticketsDetails = []
      this.loading = true
      this.generated = false

      const data = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        entity: this.entitySelected
      }
      this.$root.post('/reports/ticketSalesAllPlaces', data)
        .then(response => {
          const data = response.data
          const ticketsDetails = data[0]

          let soldTickets = 0
          let exchangedTickets = 0
          let totalTickets = 0
          let totalSales = 0

          ticketsDetails.forEach(element => {
            const { Cantidad, Canje, Boleto, total } = element
            const amount = Number(Cantidad) || 0

            soldTickets += amount
            exchangedTickets += Number(Canje) || 0
            totalTickets += Number(Boleto) || 0
            totalSales += Number(total) || 0
          })

          this.ticketsDetails = ticketsDetails
          this.soldTickets = soldTickets
          this.exchangedTickets = exchangedTickets
          this.totalTickets = totalTickets
          this.totalSales = totalSales
        })
        .finally(() => {
          this.loading = false
          this.generated = true
        })
    }
  }
}
</script>