<template>
  <div class="CardInput">
    <div class="Lebox">
      <div>
        <v-btn
          class="Lebtn LeRigth"
          outlined
          color="indigo"
          @click="lessInput()"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </div>
      <div>
        <input
          v-model="computedAmount"
          class="MyInputNumber"
          maxlength="8"
          @keypress="$input.isNumber($event)"
          @keyup="changeInput()"
        >
      </div>
      <div>
        <v-btn
          class="Lebtn LeLeft"
          outlined
          color="indigo"
          @click="addInput()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputAmountTickets',
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    checkFirtsPress: {
      required: false,
      type: Boolean,
      default: true
    },
    amountAddOrLess: {
      type: Number,
      required: false,
      default: 5
    },
    checkMaxValue: {
      required: false,
      type: Boolean,
      default: false
    },
    max: {
      required: false,
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      firtsPress: true
    }
  },
  computed: {
    computedAmount: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  },
  methods: {
    changeInput: function () {
      if (this.checkFirtsPress && this.firtsPress) {
        const value = String(this.computedAmount)?.replace('0', '') || 0
        this.computedAmount = 0
        this.computedAmount = Number(value)
        this.firtsPress = false
        return
      }
      if (this.computedAmount === '' || isNaN(Number(this.computedAmount))) {
        this.computedAmount = 0
        return
      }

      if (this.checkMaxValue && this.computedAmount > this.max) {
        this.computedAmount = Number(this.max)
        return
      }

      this.computedAmount = Number(this.computedAmount)
    },
    addInput: function () {
      this.firtsPress = false
      const tempAmount = Number(this.computedAmount) + this.amountAddOrLess
      if (this.checkMaxValue && tempAmount > this.max) {
        this.computedAmount = Number(this.max)
        return
      }
      this.computedAmount = tempAmount
    },
    lessInput: function () {
      const tempAmountTicket = Number(this.computedAmount) - this.amountAddOrLess
      this.computedAmount = tempAmountTicket < 0 ? 0 : tempAmountTicket
      this.firtsPress = false
    }
  }
}
</script>

<style scoped>
.CardInput {
  padding: 0px;
  min-width: 200px;
}

.Lebox {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  flex-wrap: nowrap;
}

.Lebtn {
  width: 30px !important;
  min-width: 30px !important;
  margin: 0 !important;
  height: 35px !important;
  background: #ffffff;
}

.Lebtn:hover {
  background: orange !important;
}

.LeLeft {
  border-radius: 0px 5px 5px 0px;
  border-left: none;
}

.MyInputNumber {
  font-size: 18px;
  background: white;
  padding: 3px;
  border: 1px solid black;
  width: 100%;
  text-align: right;
  height: 35px !important;
}

.LeRigth {
  border-radius: 5px 0px 0px 5px !important;
  border-right: none !important;
}
</style>
