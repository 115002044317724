<template>
  <div id="ReportCanceledTransactionsTable">
    <StyledTableReport
      :headers="headersReport"
      :details="detailsReport"
      :title="titleReport"
      :showTotals="false"
      center
    >
      <template v-for="(detUserList, index) in transactionsDetails">
        <tr
          v-for="(item, index1) in detUserList.details"
          :key="`canceled-transactions-details-report-${index}${index1}`"
        >
          <td align="center">
            {{ detUserList.NombreCajero }}
          </td>
          <td align="center">
            {{ detUserList.caja }}
          </td>
          <td align="center">
            {{ item.FechaOperacion }}
          </td>
          <td align="center">
            {{ item.NTRANSNR }}
          </td>
          <td align="center">
            {{ item.CTRANSNR }}
          </td>
          <td align="right">
            ${{ $formatPrice(item.total) }}
          </td>
        </tr>
        <tr :key="`canceled-transactions-details-report-total-${index}`">
          <td
            :colspan="headersReport.length - 1"
            align="right"
          >
            <strong>Total</strong>
          </td>
          <td
            align="right"
          >
            <strong>${{ $formatPrice(detUserList.total) }}</strong>
          </td>
        </tr>
        <tr
          :key="`spacer-canceled-transactions-details-user-${index}`"
          class="spacer-styled-table"
        >
          <td :colspan="headersReport.length" />
        </tr>
      </template>
      <tr>
        <td
          colspan="5"
          align="right"
        >
          <strong>Total cancelaciones</strong>
        </td>
        <td align="right">
          <strong>${{ $formatPrice(toltalCancelations) }}</strong>
        </td>
      </tr>
    </StyledTableReport>
  </div>
</template>

<script>
import StyledTableReport from '../tools/reports/StyledTableReport.vue'
export default {
  name: 'ReportCanceledTransactionsTable',
  components: { StyledTableReport },
  props: [
    'dateStart',
    'dateEnd',
    'titleReport',
    'transactionsDetails',
    'toltalCancelations'
  ],
  data () {
    return {
      headersReport: [
        { name: 'Nombre del cajero', attrs: { } },
        { name: 'No. Caja', attrs: { } },
        { name: 'Fecha', attrs: { } },
        { name: 'Transacción venta', attrs: { } },
        { name: 'Transacción cancelación', attrs: { } },
        { name: 'Importe', attrs: { } }
      ],
      detailsReport: [
        {
          key: 'Fecha',
          value: `${this.$formatFecha(this.dateStart)} al ${this.$formatFecha(this.dateEnd)}`,
          attrs: { class: 'pt-2' }
        }
      ]
    }
  }

}
</script>

<style>

</style>
