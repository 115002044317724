<template>
  <div class="py-10">
    <div v-if="activeDrop">
      <div
        class="dropzone"
        :class="{ dragleavezone: active }"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
        v-if="!percentage && !activeProgressBar"
      >
        <v-icon size="60px" color="blue lighten-1">
          mdi-cloud-arrow-up-outline
        </v-icon>
        <span>Suelto el documento <strong>CVS</strong> aqui</span>
        <span>o</span>
        <v-btn color="blue lighten-1" rounded dark @click="handleFileImport">
          Seleccionar archivo excel
        </v-btn>
        <form enctype="multipart/form-data">
          <input
            ref="uploader"
            class="d-none"
            accept=".csv"
            type="file"
            @change="onFileChange"
          />
        </form>
      </div>
    </div>
    <div v-if="activeProgressBar && activeDrop" class="style_description">
      <div>
        <span class="loader"></span>
      </div>
      <div>
        <strong>{{ text }}</strong>
      </div>
      <div class="style_status">
        {{ filename }}
      </div>
      <div class="style_status">
        <strong>{{ consecutive.toLocaleString() }}</strong> de
        <strong>{{ totalData.toLocaleString() }}</strong> registros
      </div>
      <div class="style_status">
        {{ error }}
      </div>
    </div>

    <div style="width: 100vw; " v-if="!activeDrop">
      <v-skeleton-loader v-if="loading" type="table" />
      <v-alert
        v-if="!loading && dataTable.length == 0"
        class="mb-0"
        icon="mdi-folder-alert-outline"
        prominent
        text
        type="warning"
        align="left"
      >
        No hay registros
      </v-alert>
      <v-card v-if="dataTable.length" class="mx-8 mb-8">
        <v-card-title>
          <v-btn color="success" class="ma-2 ml-7" @click="exportTable1" v-if="format == 'ticketRepet'">
            <v-icon>mdi-file-excel</v-icon> Exportar Lista
          </v-btn>
          <v-btn color="success" class="ma-2 ml-7" @click="exportTable2" v-if="format == 'ticketRepet'">
            <v-icon>mdi-file-excel</v-icon> Exportar Grupo
          </v-btn>
          <v-btn color="success" class="ma-2 ml-7" @click="exportTable3" v-if="format == 'TicketsNoUse'">
            <v-icon>mdi-file-excel</v-icon> Exportar Lista
          </v-btn>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <DropZoneDataTable
          :dataTable="dataTable"
          :search="search"
          :loading="loading"
          :headers="headers"
          v-if="format == 'ticketRepet'"
        />
        <div v-if="format == 'TicketsNoUse'">
          <v-data-table
            v-if="!loading && dataTable.length"
            :headers="headers"
            :items="dataTable"
            item-key="clave"
            class=" m-4 mt-2"
            :search="search"
            multi-sort
            mobile-breakpoint="Infinity"
          ></v-data-table>
        </div>
      </v-card>
    </div>

    <div id="content-for-excel1" style="display: none;" v-if="format == 'ticketRepet'">
      <DropZoneTableList :dataTable="dataTable" />
    </div>

    <div id="content-for-excel2" style="display: none;" v-if="format == 'ticketRepet'">
      <DropZoneTableGroup :dataTable="dataTable" :headers="headers" />
    </div>

    <div id="content-for-excel3" style="display: none;" v-if="format == 'TicketsNoUse'">
      <table border="1">
        <thead>
          <tr>
            <td class="place-title f12">
              Folio
            </td>
            <td class="place-title f12">
              Barcode
            </td>
            <td class="place-title f12">
              Msg
            </td>
          </tr>
        </thead>
        <tbody>
          <template v-for="(ticket, index) in dataTable">
              <tr>
                <td>
                  {{ ticket.folio }}
                </td>
                <td>
                  '{{ ticket.barcode }}
                </td>
                <td>{{ ticket.msg }}</td>
              </tr>
          </template>
        </tbody>
      </table>
    </div>

    <v-progress-linear
      bottom
      fixed
      :active="activeProgressBar"
      :value="percentage"
      color="cyan"
      height="25"
    >
      <template v-slot:default="{ value }">
        <strong>{{ Math.ceil(value) }}%</strong>
      </template>
    </v-progress-linear>

    <div class="style_btn" v-if="showBtn">
      <v-btn dark color="blue lighten-1" class="ma-2 ml-7" @click="reload()">
        <v-icon>mdi-file</v-icon> Procesar Nuevo
      </v-btn>
    </div>
  </div>
</template>

<script>
import DropZoneDataTable from "./DropZoneDataTable.vue";
import DropZoneTableList from "./DropZoneTableList.vue";
import DropZoneTableGroup from "./DropZoneTableGroup.vue";
export default {
  name: "DropZone",
  components: { DropZoneDataTable, DropZoneTableList, DropZoneTableGroup },
  data() {
    return {
      filename:'',
      text: "Procesando archivo",
      showBtn: false,
      loading: false,
      dataTable: [],
      dataTableU: [],
      dataTableExcel: [],
      index: 4,
      totalData: 0,
      consecutive: 0,
      percentage: 0,
      longArray: 1000,
      active: false,
      threads: 1,
      activeProgressBar: false,
      lote: 0,
      allLotes: 0,
      timeawait: 200,
      activeDrop: true,
      error: "",
      search: "",
      headers: [
        { text: "UID No.", value: "c10", align: "left" },
        { text: "Ticket Type", value: "c11", align: "left" },
        { text: "Person Type", value: "c12", align: "left" },
        { text: "Transaction", value: "c13", align: "left" },
      ],
      maxPeticionAxess: 100,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showBtn = true;
    }, 400);
  },
  props: ["format"],
  methods: {
    reload() {
      this.loading = false;
      this.dataTable = [];
      this.totalData = 0;
      this.consecutive = 0;
      this.percentage = 0;
      this.active = false;
      this.activeProgressBar = false;
      this.lote = 0;
      this.allLotes = 0;
      this.activeDrop = true;
      this.search = "";
    },
    getFiltered(e) {
      console.log(e); //output the filtered items
      this.dataTableExcel = e;
    },
    handleFileImport() {
      this.$refs.uploader.click();
    },
    dragover(e) {
      e.preventDefault();
      this.active = true;
    },
    dragleave() {
      this.active = false;
    },
    drop(e) {
      e.preventDefault();
      this.active = false;
      this.beginProcess(e.dataTransfer.files);
    },
    beginProcess(files) {
      if (!files.length) return;
      this.filename = files[0].name
      this.createInput(files[0]);
    },
    onFileChange(e) {
      let files =
        e.target.files || e.dataTransfer.files || this.$refs.uploader.files;
      this.beginProcess(files);
    },
    createInput(file) {
      let reader = new FileReader();
      let packages = [];

      reader.onload = (e) => {
        let cont = 1;
        let data = [];
        let lines = reader.result.split("\r" + "\n");
        let headers = lines[0].split(",");
        this.totalData = 0;
        //console.log(headers)

        for (let i = 1; i < lines.length; i++) {
          if (!lines[i]) continue;
          let date;
          this.totalData += 1;
          let currentline = lines[i];
          let re = /"/g;
          currentline = re[Symbol.replace](currentline, "");
          currentline = currentline.split(",");

          switch (this.format) {
            case "ticketRepet":
              date = new Date(currentline[this.index]);
              currentline[this.index] = date;
              break;
            case "TicketsNoUse":
              currentline = currentline[0].split("-");
              break;
          }

          data.push(currentline);

          if (cont == this.longArray) {
            packages.push(data);
            data = [];
            cont = 0;
          }
          cont += 1;
        }
        if (data.length > 0) {
          packages.push(data);
        }
        data = null;

        this.allLotes = packages.length;
        //console.log(packages);
        this.percentage = 0;
        this.processSending(packages);
      };
      reader.readAsText(file);
    },
    processSending(packages) {
      let packages2 = [];
      this.consecutive = 0;

      switch (true) {
        case this.totalData % 5 === 0:
          this.threads = 5;
          break;
        case this.totalData % 4 === 0:
          this.threads = 4;
          break;
        case this.totalData % 3 === 0:
          this.threads = 3;
          break;
        case this.totalData % 2 === 0:
          this.threads = 2;
          break;
        default:
          this.threads = 1;
      }
      console.log(this.threads);
      let dos = parseInt(packages.length / this.threads);

      if (dos == 0) {
        this.threads = packages.length;
        packages2.push(packages);
      } else {
        for (let x = 0; x <= this.threads; x++) {
          let packege = packages.slice(x * dos, (x + 1) * dos);
          if (packege.length) {
            packages2.push(packege);
          }
        }
      }

      packages = null;
      this.activeProgressBar = true;
      this.lote = 0;
      this.loopProcess(packages2, 0);
    },
    loopProcess(packege, index) {
      if (index < packege.length) {
        this.LoopData(packege[index], 0);
        index += 1;
        this.loopProcess(packege, index);
      }
    },
    LoopData(packege, index) {
      if (index < packege.length) {
        this.consecutive += packege[index].length;
        this.percentage = Math.round((this.consecutive * 100) / this.totalData);
        this.sendData(packege, index);
      }
    },
    async sendData(packege, index) {
      this.lote += 1;
      let myPackege = packege[index];
      let parameters = {
        format: this.format,
        allpackage: 0,
        total: this.totalData,
        package: this.lote,
        data: myPackege, //packege,
        day: 1,
      };

      this.$root
        .post("/tools/processFile", parameters)
        .then((response) => {
          const data = response.data;
          console.log(data);

          index += 1;
          this.LoopData(packege, index);

          if (this.allLotes == data.package) {
            this.getserviceprocess();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    getserviceprocess() {
      switch (this.format) {
        case "ticketRepet":
          this.activeProgressBar = false;
          this.getRepeatTickets();
          break;
        case "TicketsNoUse":
          this.headers = [
            { text: "folio", value: "folio", align: "left" },
            { text: "barcode", value: "barcode", align: "left" },
            { text: "msg", value: "msg", align: "left" },
          ];
          this.getCodesTickes();
          break;
      }
    },
    async getCodesTickes() {
      this.loading = true;
      this.$root
        .post("/tools/codesTickes")
        .then((response) => {
          this.dataTableU = [];
          const data = response.data.codesTickes;
          this.text = "Verificando tickets";
          this.activeProgressBar = true
          this.percentage = 0
          setTimeout(() => {
            this.processCodesTickes(data, 0, this.maxPeticionAxess);
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    async processCodesTickes(dataF, index, limit) {
      let dataT = dataF.slice(index * limit, (index + 1) * limit);
      if (dataT.length == 0) {
        console.log(this.dataTableU);
        this.dataTable = this.dataTableU;
        this.activeProgressBar = false
        this.activeDrop = false;
        this.loading = false;
        this.percentage = 0
        return false;
      }
      this.consecutive = index * limit + dataT.length;
      this.totalData = dataF.length;
      //this.percentage = Math.round((this.consecutive * 100) / this.totalData);
      
      let data = {
        credential: "WTP",
        verifiUsed: true,
        tickets: dataF.slice(index * limit, (index + 1) * limit),
      };
      //console.log(dataT);

      this.$root
        .post2("/PromtecWTP/VeriBarcode/", data)
        .then((response) => {
          
          this.percentage = (this.consecutive * 100) / this.totalData;

          const data = response.data;
          console.log(data);

          this.dataTableU = this.dataTableU.concat(data.tickets)
          if (data.success) {
            index += 1;
            this.error = "";
          } else {
            this.error = "Error: " + data.msg + ", volviendo a solicitar";
          }
          this.processCodesTickes(dataF, index, limit);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    async getRepeatTickets() {
      this.activeDrop = false;
      this.loading = true;
      this.$root
        .post("/tools/repeatTickets")
        .then((response) => {
          const data = response.data.repeatTickets;

          data.map((item) => {
            item.tickets_repeat = JSON.parse(item.tickets_repeat);
          });
          //console.log(data);
          this.dataTable = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    exportTable1: function(event) {
      this.$exportExcelFromJs(
        event,
        "repeatTickets-List",
        "content-for-excel1"
      );
    },
    exportTable2: function(event) {
      this.$exportExcelFromJs(
        event,
        "repeatTickets-Group",
        "content-for-excel2"
      );
    },
    exportTable3: function(event) {
      this.$exportExcelFromJs(
        event,
        "TicketUse",
        "content-for-excel3"
      );
    },
  },
};
</script>

<style scoped>
.dropzone {
  width: 800px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  transition: 0.3s ease all;
  border: 2px dashed #42a5f5;
  background-color: #f5f5f5;
}

.dragleavezone {
  opacity: 0.5;
}
.style_btn {
  position: absolute;
  top: 3px;
  left: calc(100vw - 240px);
  z-index: 999999;
}
.style_description {
  text-align: center;
  font-size: 24px;
  margin-top: calc(50vh - 214px);
}
.style_status {
  font-size: 20px;
  color: gray;
}
.loader {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #5188ff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border-left: 4px solid #ff3d00;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<style scoped src="../../assets/css/style-tables-dark.css"></style>