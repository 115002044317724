// Vue
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import globalStates from './global-states'
import wholesaler from './wholesaler'
import dashboard from './dashboard'
import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    globalStates,
    wholesaler,
    dashboard,
    auth
  }
})
