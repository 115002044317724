export default {
  data () {
    return {
      flag: false
    }
  },
  methods: {
    validateTransaction: async function (item) {
      this.flag = true
      const self = this

      const result = await this.$swal({
        title: 'Confirmación de la línea de referencia',
        input: 'text',
        inputLabel: 'Escriba la línea de referencia',
        inputPlaceholder: 'Línea de referencia',
        showDenyButton: true,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonColor: 'primary',
        confirmButtonText: 'Aceptar',
        denyButtonText: 'Cancelar',
        denyButtonColor: '#dd8159',
        preConfirm: (value) => {
          if (value !== item.RefOri) {
            self.$swal.showValidationMessage('Las líneas de referencia no coinciden!')
          }
        }
      })

      if (!result.isConfirmed) return
      this.updateTransactionStatus(item, 2)
    },
    lockTransaction: async function (item) {
      this.flag = true
      const self = this

      const result = await this.$swal({
        icon: 'warning',
        title: '¿Desea bloquear esta Orden de Pago?',
        text: item.CorpName + ': ' + item.RefOri,
        input: 'textarea',
        inputLabel: 'Escriba el motivo del bloqueo',
        inputPlaceholder: 'Escriba motivo',
        inputAttributes: {
          maxlength: 250,
          'aria-label': 'Type your message here'
        },
        showDenyButton: true,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonColor: 'primary',
        confirmButtonText: 'SI',
        denyButtonColor: '#dd8159',
        preConfirm: (value) => {
          if (!value) {
            self.$swal.showValidationMessage('Se necesita un motivo!')
          }
        }
      })

      if (!result.isConfirmed) return
      this.updateTransactionStatus(item, 3, result.value)
    },
    unlockTransaction: async function (item) {
      this.flag = true
      const result = await this.$ConfirmBox('warning', '¿Desea desbloquear esta Orden de Pago?', item.CorpName + ': ' + item.RefOri)
      if (!result.isConfirmed) return
      this.updateTransactionStatus(item, 4)
    },
    lockInformation: function (item) {
      this.flag = true

      const info = item.info.replaceAll('\\n', '\n')
      this.$swal({
        title: 'Motivo',
        html: `<textarea rows="5" cols="33" disabled  style="resize: none; border: 1px solid #b7b7b7; margin: 0; padding: 10px; width: 100%;">${info}</textarea>`,
        showDenyButton: false,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonColor: 'primary',
        confirmButtonText: 'OK',
        denyButtonColor: '#dd8159'
      })
    },
    updateTransactionStatus (item, estatusX, info) {
      this.$root
        .post('/UpdateTransMayo', {
          idX: item.id_cartrans,
          estatusX,
          info
        })
        .then(() => {
          this.getData()
        })
    }
  }
}
