var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"UsersConfigurations"}},[_c('v-card',[_c('v-card-title',[_c('v-btn',{staticClass:"primary",attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.openModal(_vm.itemModel)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Agregar usuario ")],1),_c('v-spacer'),(!_vm.loading && _vm.items.length)?_c('v-text-field',_vm._b({attrs:{"placeholder":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},'v-text-field',_vm.attrsSearching,false)):_vm._e()],1),_c('v-card-text',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(!_vm.loading && !_vm.items.length)?_c('v-alert',{staticClass:"mb-0",attrs:{"icon":"mdi-folder-alert-outline","prominent":"","text":"","type":"warning","align":"left"}},[_vm._v(" No hay usuarios registrados! ")]):_vm._e(),(!_vm.loading && _vm.items.length)?_c('v-data-table',{staticClass:"elevation-1 clickable-item-table",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"id","mobile-breakpoint":"Infinity","search":_vm.search},on:{"click:row":_vm.openModal},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.name) + " " + (item.name2) + " " + (item.name3)))+" ")]}},{key:"item.pertenencia",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.placeUser(item.pertenencia))+" ")]}},{key:"item.rol",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRolName(item.rol))+" ")]}},{key:"item.activo",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.activo ? 'green darken-3' : 'orange'}},[_vm._v(" mdi-account"+_vm._s(item.activo ? '' : '-off')+" ")])]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [(item.admin)?_c('v-icon',{attrs:{"color":"green darken-3"}},[_vm._v(" mdi-check-bold ")]):_vm._e()]}}],null,true)}):_vm._e()],1)],1),_c('DialogUserConfigurations',{attrs:{"places":_vm.places,"catalogueUsers":_vm.catalogueUsers,"item":_vm.itemSelected},on:{"refresh":_vm.getUsers},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }