<template>
  <div>
    <div
      v-if="items.length"
      align="right"
    >
      <p class="text-h5 font-weight-bold mb-1">
        ${{ $formatPrice(totalPrecart) }} <ExchangeCurrency />
      </p>
      <v-btn
        v-if="canEdit"
        color="warning"
        dark
        @click="generatePaymentOrder"
      >
        Generar Orden de Pago
      </v-btn>
    </div>

    <v-simple-table
      v-for="placeData in items"
      :key="`table-precart-place-${placeData.sitio}`"
      class="mb-4"
    >
      <thead>
        <tr>
          <td class="place-title">
            {{ placeData.name }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr style="background-color: rgba(255, 165, 0, 0.4);">
          <td>
            <v-simple-table>
              <thead>
                <tr style="background: rgb(253 142 48 / 22%);">
                  <td style="width:40%;">
                    <strong>Nombre del Boleto</strong>
                  </td>
                  <td
                    align="center"
                    style="width:10%"
                  >
                    <strong>Fecha Limite de uso</strong>
                  </td>
                  <td
                    align="center"
                    style="width:10%"
                  >
                    <strong>Cantidad</strong>
                  </td>
                  <td
                    align="right"
                    style="width:20%;"
                  >
                    <strong>Precio Unitario</strong>
                  </td>
                  <td
                    align="right"
                    style="width:20%;"
                  >
                    <strong>Precio total</strong>
                  </td>
                  <td
                    v-if="canEdit"
                    align="center"
                    style="width:20%;"
                  >
                    <strong>Eliminar</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="ticketData in placeData.place"
                  :key="`table-precart-place-items-${ticketData.id_ticket}-${ticketData.persontype}-${ticketData.npool}`"
                >
                  <td>
                    {{ ticketData.nombre }}
                    <span v-if="ticketData.alias">({{ ticketData.alias }})</span>
                  </td>
                  <td
                    align="center"
                    class="red--text font-weight-bold"
                  >
                    {{ ticketData.Ffin }}
                  </td>
                  <td
                    align="center"
                    class="CardFormat"
                  >
                    <InputAmountTickets
                      v-if="canEdit"
                      v-model="ticketData.cantidad"
                      :checkFirtsPress="false"
                      @change="$emit('changeOnPrecart')"
                    />
                    <strong v-else> {{ ticketData.cantidad }}</strong>
                  </td>
                  <td
                    align="right"
                    class="text-body-1 font-weight-bold"
                  >
                    ${{ $formatPrice(ticketData.costo) }} <ExchangeCurrency />
                  </td>
                  <td
                    align="right"
                    class="text-body-1 font-weight-bold"
                  >
                    ${{ $formatPrice(ticketData.costo * ticketData.cantidad) }} <ExchangeCurrency />
                  </td>
                  <td
                    v-if="canEdit"
                    align="right"
                  >
                    <v-tooltip
                      bottom
                      color="black"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="red"
                          class="ma-2"
                          v-bind="attrs"
                          @click="deleteItemPrecart(ticketData)"
                          v-on="on"
                        >
                          <v-icon size="29px">
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar del carrito</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TablePreCarrito',
  props: ['totalPrecart', 'items'],
  data () {
    return {
      rol: 0
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData'
    }),
    canEdit () {
      return this.userData?.subrol < 3
    }
  },
  methods: {
    generatePaymentOrder: async function () {
      const result = await this.$ConfirmBox('warning', '¿Desea generar la orden de pago?')

      if (!result.isConfirmed) return
      this.$emit('generatePaymentOrder')
    },
    deleteItemPrecart: async function (ticket) {
      const result = await this.$ConfirmBox('warning', '¿Esta seguro?', '¡Eliminar del carrito!')

      if (!result.isConfirmed) return
      this.$emit('deleteItemPrecart', ticket)
    }
  }
}
</script>

<style scoped>
.place-title {
  background: white;
  padding: 5px !important;
  height: 40px;
  font-size: 20px !important;
  font-weight: bold;
}
</style>
