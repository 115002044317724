import Vue from 'vue'

Vue.prototype.$ = element => document.querySelector(element)

Vue.prototype.$formatPrice = function (value = 0, decimals = 2) {
  if (!value) value = 0
  if (typeof value === 'string' && value.trim() !== '') value = parseFloat(value)
  const formattedValue = value.toFixed(decimals)
  const [integer, decimal] = formattedValue.split('.')
  const integerFormatted = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return `${integerFormatted}.${decimal}`
}

Vue.prototype.$getSubtotal = function (total) {
  return total / 1.16
}

Vue.prototype.$getIVA = function (total) {
  return total / 1.16 * 0.16
}

Vue.prototype.$formatNum = function (value) {
  if (!value) value = 0
  if (typeof value === 'string') value = parseFloat(value)
  value = String(value)
  if (value.includes('.')) {
    const [integer, decimal] = value.split('.')
    const integerFormatted = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return `${integerFormatted}.${decimal}`
  }

  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

Vue.prototype.$sumarDias = function (fecha, dias = 0) {
  fecha.setDate(fecha.getDate() + dias)
  const fechaLocal = new Date(fecha.getTime() - (fecha.getTimezoneOffset() * 60000))
  const cadenaISO = fechaLocal.toISOString()
  return cadenaISO.substring(0, 10)
}

Vue.prototype.$sumarDias2 = function (date) {
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  let fecha1
  if (month < 10) {
    fecha1 = year + '-0' + month
  } else {
    fecha1 = year + '-' + month
  }
  return fecha1
}

Vue.prototype.$formatFecha = function (fecha, union = '/') {
  return fecha.split('-').reverse().join(union)
}

Vue.prototype.$swalUploadProfileImage = async function ({ executeClick = false } = {}) {
  const removeImg = () => {
    const content = this.$swal.getContent()
    if (!content) return
    const imgPreview = content.querySelector('img')
    if (imgPreview) imgPreview.remove()
  }

  const { value: file } = await this.$swal.fire({
    title: 'Cambiar imagen de perfil',
    input: 'file',
    inputAttributes: {
      accept: 'image/png, image/jpg, image/jpeg, image/svg, image/webp',
      'aria-label': 'Selecciona una imagen'
    },
    confirmButtonText: 'Guardar',
    inputValidator: (value) => {
      if (!value) return 'Por favor, selecciona una imagen'
      if (value.size > 1024 * 1024) return 'La imagen debe ser menor de 1MB'
    },
    willOpen: () => {
      const inputField = this.$swal.getInput()
      if (executeClick) inputField.click()
      inputField.addEventListener('change', () => {
        const file = inputField.files[0]
        if (!file) return

        const reader = new FileReader()
        reader.onload = (e) => {
          removeImg()

          const imgPreview = document.createElement('img')
          imgPreview.src = e.target.result
          imgPreview.style.maxWidth = '350px'
          imgPreview.style.maxHeight = '350px'
          this.$swal.getContent().appendChild(imgPreview)
        }
        reader.readAsDataURL(file)
      })
    },
    didClose: removeImg
  })

  return Promise.resolve(file)
}

Vue.prototype.$swalUploadPdf = async function ({
  executeClick = false,
  title = 'Subir archivo',
  text = '',
  label = 'Selecciona un archivo',
  confirmButtonText = 'Guardar',
  showDenyButton = true,
  denyButtonText = 'Cancelar'
} = {}) {
  const { value: file } = await this.$swal.fire({
    title,
    input: 'file',
    inputAttributes: {
      accept: 'application/pdf',
      'aria-label': label
    },
    text,
    reverseButtons: true,
    showDenyButton,
    showCancelButton: false,
    confirmButtonColor: '#2196F3',
    confirmButtonText,
    denyButtonText,
    denyButtonColor: '#EF5350',
    inputValidator: (value) => {
      if (!value) return 'Por favor, selecciona un archivo'
      if (value.size > 1024 * 1024) return 'El archivo debe ser menor de 1MB'
    },
    willOpen: () => {
      const inputField = this.$swal.getInput()
      if (executeClick) inputField.click()

      inputField.addEventListener('change', () => {
        const file = inputField.files[0]
        if (!file) return this.$swal.showValidationMessage('Por favor, selecciona un archivo')
        if (file.size > 1024 * 1024) return this.$swal.showValidationMessage('El archivo debe ser menor de 1MB')
      })
    }
  })

  return Promise.resolve(file)
}

Vue.prototype.$exportExcelFromJs = function (event, fileName = 'dowload-Table', id = 'content-for-excel') {
  const table = document.getElementById(id)
  const html = table.outerHTML
  fileName = fileName.replaceAll('.', '')

  const link = document.createElement('a')
  link.href = 'data:application/vnd.ms-excel,' + escape(html)
  link.download = fileName
  link.click()
  link.remove()
  event.preventDefault()
}
