<template>
  <div
    id="ReportsDashboard"
    class="pa-5"
  >
    <template v-for="itemReport in contentReports">
      <v-row
        v-if="validateShowReports(itemReport.validateShow, itemReport.title)"
        :key="itemReport.name"
        v-bind="itemReport.attrs"
      >
        <v-col cols="12">
          <div class="container-title-reports">
            <div class="title-reports">
              {{ itemReport.title }}
            </div>
          </div>
        </v-col>
        <v-col
          v-for="report in itemReport.reports"
          :key="'container-card-'+itemReport.name+'-'+report.title+'-'+report.title2"
          cols="12"
          sm="12"
          md="6"
          lg="4"
          xl="3"
          class="container-card-report"
          @click="openReport(report)"
        >
          <v-card
            class="card-report"
            :elevation="2"
          >
            <v-card-title>
              <img
                class="icon-report"
                :src="'img/reports/' + report.icon"
              >
              <span>{{ report.title }}<br>{{ report.title2 }}</span>
            </v-card-title>

            <v-card-subtitle class="subtitle-card-report">
              {{ report.subtext }}
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import VanillaTilt from 'vanilla-tilt'
import { mapGetters } from 'vuex'

export default {
  name: 'ReportsDashboard',
  data () {
    return {
      contentReports: [
        {
          name: 'report-promtec',
          title: 'PROMTEC',
          validateShow: true,
          attrs: { class: 'mb-7 mt-1' },
          reports: [
            {
              title: 'Reporte comisiones',
              title2: 'con detalle',
              subtext: 'Informe general de comisiones promtec',
              icon: 'ticket2.png',
              link: 'commissions'
            },
            {
              title: 'Reporte comisiones',
              title2: 'Interno',
              subtext: 'Informe general de comisiones promtec',
              icon: 'ticket2.png',
              link: '13'
            },
            {
              title: 'Reporte comisiones parking',
              title2: 'con detalle',
              subtext: 'Informe general de comisiones parking promtec',
              icon: 'ticket2.png',
              link: 'commissions-parking'
            },
          ]
        },
        {
          name: 'report-aafy',
          title: 'RECAUDACIÓN POR',
          validateShow: false,
          attrs: { class: 'mb-7 mt-1' },
          reports: [
            {
              title: 'Parador',
              title2: '',
              subtext: 'Relacion de fichas de depositos de la unidad recaudadora.',
              icon: 'Site.png',
              link: 'place'
            },
            {
              title: 'Caja',
              subtext: 'Informe de recaudacion',
              icon: 'POS.png',
              link: 'pos'
            },
            {
              title: 'Cajero',
              title2: '',
              subtext: 'Informe de recaudacion',
              icon: 'Cashier.png',
              link: 'cashier'
            }

          ]
        },
        {
          name: 'report-tickets',
          title: 'BOLETOS',
          validateShow: false,
          attrs: { class: 'mb-7 mt-1' },
          reports: [
            {
              title: 'Boletos vendidos',
              title2: 'sin detalle',
              subtext: 'Cantidad total de boletos vendidos',
              icon: 'ticket1.png',
              link: 'ticket-sales'
            },
            {
              title: 'Boletos vendidos',
              title2: 'con detalle',
              subtext: 'Cantidad total de boletos vendidos con detalle',
              icon: 'ticket2.png',
              link: 'ticket-sales-details'
            },
            {
              title: 'Boletos vendidos',
              title2: 'Paradores',
              subtext: 'Cantidad total de boletos vendidos de todos los Paradores',
              icon: 'ticket3.png',
              link: 'ticket-sales-all-places'
            }
          ]
        },
        {
          name: 'report-transactions',
          title: 'TRANSACCIONES',
          validateShow: false,
          attrs: { class: 'mb-7 mt-1' },
          reports: [
            {
              title: 'Transacciones canceladas',
              title2: '',
              subtext: 'Detalle de los boletos cancelados',
              icon: 'ticket4.png',
              link: 'canceled-transactions'
            }
          ]
        },
        {
          name: 'report-wholesalers',
          title: 'MAYORISTAS',
          validateShow: false,
          attrs: { class: 'mb-3 mt-1' },
          reports: [
            {
              title: 'Reporte de ventas',
              title2: '',
              subtext: 'Informe de venta sin detalles',
              icon: 'trans1.png',
              link: 'wholesale-sales'
            },
            {
              title: 'Cajero',
              title2: '',
              subtext: 'Informe de transacciones autorizadas',
              icon: 'Cashier.png',
              link: 'wholesale-cashier-sales'
            },
            {
              title: 'Boletos vendidos',
              title2: 'con detalle',
              subtext: 'Cantidad total de boletos vendidos con detalle',
              icon: 'ticket2.png',
              link: 'wholesale-tickets-sales'
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData'
    }),
    rol () {
      return this.userData.rol
    }
  },
  mounted () {
    this.vailla()
  },
  methods: {
    vailla () {
      VanillaTilt.init(document.querySelectorAll('.container-card-report'), {
        max: 10,
        speed: 400,
        gyroscope: false
      })
    },
    validateShowReports: function (validateShow, title) {
      if (!validateShow) return true
      if (title === 'PROMTEC') return this.rol === 0 || this.rol === 7
    },
    openReport: function (item) {
      this.$router.push('/reports/' + item.link)
    }
  }
}
</script>

<style scoped>
#ReportsDashboard {
  overflow-x: hidden;
}

.container-title-reports {
  --margin-left: 45px;
  width: calc(90% - var(--margin-left));
  height: 34px;
  background: orange;
  -webkit-transform: skew(311deg);
  -moz-transform: skew(311deg);
  -ms-transform: skew(311deg);
  -o-transform: skew(311deg);
  transform: skew(311deg);
  margin: 5px 0px 0px calc(var(--margin-left) * -1) !important;
}

.container-title-reports .title-reports{
  position: absolute;
  top: 0;
  left: 49px;
  -webkit-transform: skew(47deg);
  -moz-transform: skew(47deg);
  -ms-transform: skew(47deg);
  -o-transform: skew(47deg);
  transform: skew(47deg);
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.card-report{
  min-height: 130px;
  background: url('../../assets/images/bg-ticket-orange.png');
  background-size: 100%;
  height: 100%;
  opacity: 0.8;
  transition: opacity .4s ease-out;
  position: relative;
}

.container-card-report:hover .card-report {
  cursor: pointer;
  opacity: 1;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
}

.icon-report {
  max-width: 80px !important;
  max-height: 80px;
  position: absolute;
  right: 15px;
  top: 2px;
  opacity: 0.7;
}

.subtitle-card-report {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 10px;
  font-weight: bold;
}
</style>
