<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-card style="box-shadow: none !important">
    <v-data-table
      :headers="headers[tab]"
      :items="items"
      item-key="id"
      :sort-by="['fecha']"
      :sort-desc="[true]"
      :search="search"
      multi-sort
      mobile-breakpoint="Infinity"
    >
      <template v-slot:no-data />
      <template v-slot:body="{ items }">
        <tbody v-if="!items.length">
          <td colspan="20">
            <v-alert
              class="mb-0 text"
              align="center"
            >
              Sin datos para esta búsqueda
            </v-alert>
          </td>
        </tbody>
        <tbody v-else>
          <template v-for="(cartTransaction, index) in items">
            <tr
              :key="`cart-transaction-item-${cartTransaction.id}`"
              class="row-transaction-data"
              :style="{ 'background-color': $backgroundRowTable(index) }"
              @click="clickrow(cartTransaction.id)"
            >
              <td style="max-width: 10px">
                <v-icon class="ma-2">
                  mdi-{{ cartTransaction.id === expanded ? 'minus' : 'plus' }}
                </v-icon>
              </td>
              <td class="font-weight-bold">
                {{ cartTransaction.linearef }}
              </td>
              <td :style="tab === 4 ? 'color: red; font-weight: bold': ''">
                {{ cartTransaction.fvenci }}
              </td>
              <td :style="tab === 1 ? 'color: green; font-weight: bold' : ''">
                {{ cartTransaction.fecha }}
              </td>
              <td align="right">
                <strong>${{ $formatPrice(cartTransaction.total) }} <ExchangeCurrency /></strong>
              </td>
              <td
                v-if="tab === 1 || tab === 2"
                align="center"
                :style="tab === 1 ? 'color: green; font-weight: bold' : ''"
              >
                {{ cartTransaction.solicito }}
              </td>

              <td
                v-if="tab === 2 || tab === 3"
                align="center"
              >
                <strong :style="tab === 2 ? 'color: blue' : ''">
                  {{ cartTransaction.f_autorizacion }}
                </strong>
              </td>

              <td
                v-if="tab === 1"
                align="center"
                style="min-width: 170px;"
              >
                <v-tooltip
                  v-if="canEdit"
                  bottom
                  color="black"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="blue"
                      dark
                      v-bind="attrs"
                      @click="print(cartTransaction)"
                      v-on="on"
                    >
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                  </template>
                  <span>Imprimir orden de pago</span>
                </v-tooltip>
              </td>

              <td
                v-if="tab === 2"
                align="center"
              >
                <v-tooltip
                  v-if="canEdit"
                  bottom
                  color="black"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="blue"
                      dark
                      v-bind="attrs"
                      @click="print(cartTransaction)"
                      v-on="on"
                    >
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                  </template>
                  <span>Imprimir recibo de pagos</span>
                </v-tooltip>
              </td>

              <td
                v-if="tab === 3"
                align="center"
              >
                <strong style="color:red;">{{ cartTransaction.f_bloqueo }}</strong>
              </td>
            </tr>

            <tr
              v-if="cartTransaction.id === expanded"
              :key="`cart-transaction-details-${cartTransaction.id}`"
              style="background-color: white !important"
            >
              <TicketsDetailsTransactions
                :placeDetails="cartTransaction.place"
                :transactionId="cartTransaction.id"
              />
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

// Components
import TicketsDetailsTransactions from '../tools/TicketsDetailsTransactions.vue'

export default {
  components: {
    TicketsDetailsTransactions
  },
  props: ['items', 'tab', 'search'],
  data () {
    return {
      rol: 0,
      flag: false,
      expanded: '',
      headers: [
        [],
        [
          { text: '', value: 'icon' },
          { text: 'Línea de Referencia', value: 'linearef' },
          { text: 'Fecha de Vencimiento', value: 'fvenci' },
          { text: 'Fecha de Creacion', value: 'fecha' },
          { text: 'Costo Total', value: 'total', align: 'right' },
          { text: 'Solicito', value: 'solicito', align: 'center' },
          { text: 'Imprimir Orden', value: '', align: 'center', sortable: false }
        ],
        [
          { text: '', value: 'icon', sortable: false },
          { text: 'Línea de Referencia', value: 'linearef' },
          { text: 'Fecha de Vencimiento', value: 'fvenci' },
          { text: 'Fecha de Creacion', value: 'fecha' },
          { text: 'Costo Total', value: 'total', align: 'right' },
          { text: 'Solicito', value: 'solicito', align: 'center' },
          { text: 'Fecha de Autorizacion', value: 'f_autorizacion', align: 'center' },
          { text: 'Imprimir Recibo', value: '', align: 'center', sortable: false }
        ],
        [
          { text: '', value: 'icon', sortable: false },
          { text: 'Línea de Referencia', value: 'linearef' },
          { text: 'Fecha de Vencimiento', value: 'fvenci' },
          { text: 'Fecha de Creacion', value: 'fecha' },
          { text: 'Costo Total', value: 'total', align: 'right' },
          { text: 'Fecha de Autorizacion', value: 'f_autorizacion', align: 'center' },
          { text: 'Fecha de Bloqueo', value: 'f_bloqueo', align: 'center' }
        ],
        [
          { text: '', value: 'icon', sortable: false },
          { text: 'Línea de Referencia', value: 'linearef' },
          { text: 'Fecha de Vencimiento', value: 'fvenci' },
          { text: 'Fecha de Creacion', value: 'fecha' },
          { text: 'Costo Total', value: 'total', align: 'right' }
        ]
      ]
    }
  },
  computed: {
    ...mapGetters({
      token: 'auth/token',
      userData: 'auth/userData'
    }),
    canEdit () {
      return this.userData?.subrol < 3
    }
  },
  watch: {
    tab: function () {
      this.expanded = ''
    },
    search: function () {
      this.expanded = ''
    }
  },
  methods: {
    clickrow: function (id) {
      if (!this.flag) {
        this.expanded = this.expanded !== id ? id : ''
      } else {
        this.flag = false
      }
    },
    print: function (item) {
      this.flag = true

      this.$session.remove('tipo')
      const tipoX = { id: item.id, estatus: item.estatus, token: this.token, idCorp: this.userData.idCorp, format: 1 }
      this.$session.set('tipo', tipoX)

      setTimeout(() => {
        const routeData = this.$router.resolve({ name: 'PdfGenerator' })
        window.open(routeData.href, '_blank')
      }, 10)
    }
  }
}
</script>

<style scoped>
.row-transaction-data {
  color: black;
  cursor: pointer;
  border-top: 2px solid #75695f
}

.row {
  background-color: #e2e2e2;
}
</style>

<style scoped src="../../assets/css/style-tables-dark.css">
</style>
