<template>
  <v-data-table
    v-if="!loading && dataTable.length"
    :headers="headers"
    :items="dataTable"
    item-key="clave"
    class=" m-4 mt-2"
    :search="search"
    multi-sort
    mobile-breakpoint="Infinity"
  >
    <template v-slot:body="{ items: itemSearched }">
      <tbody v-if="!dataTable.length">
        <td colspan="20">
          <v-alert class="mb-0 text" align="center">
            Sin datos para esta búsqueda
          </v-alert>
        </td>
      </tbody>
      <tbody v-else>
        <template v-for="(ticket, index) in itemSearched">
          <tr
            class="pointman"
            :style="{
              'background-color': $backgroundRowTable(index),
              'border-top': '2px solid #75695f',
            }"
            @click="clickrow(ticket.c10)"
          >
            <td style="max-width: 10px">
              <strong>{{ ticket.c10 }}</strong>
            </td>
            <td style="max-width: 10px">
              <strong>{{ ticket.c11 }}</strong>
            </td>
            <td style="max-width: 10px">
              <strong>{{ ticket.c12 }}</strong>
            </td>
            <td style="max-width: 10px">
              <strong>{{ ticket.c13 }}</strong>
            </td>
          </tr>
          <tr
            v-if="ticket.c10 === expanded"
            :key="'tickets-generated-details-' + ticket.c10"
            style="background-color: white !important"
          >
            <td colspan="5">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="place-title f12">
                      Transaction Type
                    </th>
                    <th class="place-title f12">
                      Trans. No.
                    </th>
                    <th class="place-title f12">
                      CPU-Number
                    </th>
                    <th class="place-title f12">
                      CPU-Trans. No.
                    </th>
                    <th class="place-title f12">
                      Usage Time
                    </th>
                    <th class="place-title f12">
                      POE #
                    </th>
                    <th class="place-title f12">
                      Lane
                    </th>
                    <th class="place-title f12">
                      Ticket Number
                    </th>
                    <th class="place-title f12">
                      Validation
                    </th>
                  </tr>
                </thead>
                <template v-for="repeat in ticket.tickets_repeat">
                  <tbody>
                    <tr style="background-color: rgba(255, 165, 0, 0.4);">
                      <td>
                        {{ repeat.c1 }}
                      </td>
                      <td>
                        {{ repeat.c2 }}
                      </td>
                      <td>
                        {{ repeat.c3 }}
                      </td>
                      <td>
                        {{ repeat.c4 }}
                      </td>
                      <td>
                        <strong>{{ repeat.c5 }}</strong>
                      </td>
                      <td>
                        {{ repeat.c6 }}
                      </td>
                      <td>
                        {{ repeat.c7 }}
                      </td>
                      <td>
                        {{ repeat.c8 }}
                      </td>
                      <td>
                        {{ repeat.c14 }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </tr>
        </template>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "DropZoneDataTable",
  data() {
    return {
      expanded: null,
    };
  },
  props: ["dataTable", "loading", "search", "headers"],
  methods: {
    clickrow(id) {
      this.expanded = this.expanded === id ? "" : id;
    },
  },
};
</script>

<style scoped>
.place-title {
  background: white;
  color: black;
  padding: 0 !important;
  padding-left: 5px !important;
  font-size: 16px !important;
  font-weight: bold;
}

.f12 {
  font-size: 12px !important;
}
</style>

<style scoped src="../../assets/css/style-tables-dark.css"></style>
