<template>
    <div class="home">
      <DropZone :format="'TicketsNoUse'"/>
    </div>
  </template>
  
  <script>
    import DropZone from '../../components/dashboard/DropZone.vue'
  
    export default {
      name: 'TicketsNoUse',
      components: {
        DropZone,
      },
    }
  </script>
  
  <style scoped>
  .home {
    height: calc(100vh - 62px);
    display: flex;
    justify-content: center;
    /* 
    align-items: center; */
    background-color: #FFF;
    overflow-x:hidden
    
  }
  </style>