<template>
  <v-card
    id="SalesByTicketType"
    class="pa-2 pb-4"
  >
    <v-skeleton-loader
      v-if="loading"
      type="image"
      style="padding:20px;"
    />
    <PieChart
      v-if="!loading"
      :chartData="chartData"
      :options="options"
    />
  </v-card>
</template>

<script>
// Components
import { PieChart } from 'vue-chart-3'

export default {
  name: 'SalesByTicketType',
  components: { PieChart },
  props: ['placeSelected'],
  data () {
    return {
      loading: false,
      chartData: {
        labels: [''],
        datasets: [
          {
            data: [],
            backgroundColor: [
              '#FFD750',
              '#FFD700',
              '#FFA500',
              '#FF8C00',
              '#FF4500',
              '#FF4570'
            ]
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom'
          },
          title: {
            display: true,
            text: 'Ventas por tipo de boleto - Anual',
            font: {
              size: 20
            }
          }
        }
      }
    }
  },
  watch: {
    placeSelected: function (place) {
      if (!place) return

      this.getSalesByTicketType(place)
    }
  },
  methods: {
    getSalesByTicketType: function (placeSelected) {
      this.loading = true

      const data = { placeSelected }
      this.$root.post('/dashboard/getSalesByTicketType', data)
        .then((response) => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data?.message)
            return
          }

          const temp1L = []
          const temp1D = []

          data.forEach(element => {
            temp1L.push(element.Descripcion)
            temp1D.push(element.total)
          })

          this.chartData.labels = temp1L
          this.chartData.datasets[0].data = temp1D
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style>

</style>
