<template>
  <v-app>
    <v-main>
      <LoadingAnimation
        v-if="loadingMainApp"
        :textLoading="textLoadingMainApp"
        :fullScreen="true"
      />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// Vue
import { mapState } from 'vuex'

// Components
import LoadingAnimation from './components/tools/LoadingAnimation.vue'

export default {
  name: 'App',
  components: { LoadingAnimation },
  computed: { ...mapState('globalStates', ['loadingMainApp', 'textLoadingMainApp']) },
  created () {
    this.redirectToHttps()
  },
  methods: {
    redirectToHttps () {
      const dominio = 'controlpanel.ticket2pass.com'
      const { protocol, hostname, pathname } = window.location

      const needRedirect = protocol === 'http:' && hostname === dominio
      if (!needRedirect) return

      const newUrl = `https://${dominio}${pathname}`
      window.location.replace(newUrl)
    }
  }
}
</script>

<style src="./assets/css/style.css">
</style>
