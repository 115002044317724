<template>
  <div id="DasboardMain">
    <NavBarDashboard />
    <v-slide-y-transition mode="out-in">
      <router-view class="container-dashboard-views" />
    </v-slide-y-transition>
  </div>
</template>

<script>
// Vue
import { mapGetters, mapMutations } from 'vuex'

// Mixins
import SOCKET from '../../minixs/socket'

// Components
import NavBarDashboard from '../../components/dashboard/NavBarDashboard.vue'

export default {
  name: 'DasboardMain',
  components: {
    NavBarDashboard
  },
  mixins: [
    SOCKET
  ],
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  created () {
    this.ejectSocket()
  },
  mounted () {
    if (this.user?.rol === 4) {
      if (this.$root.socket) this.$root.socket.disconnect()
      this.$router.push({ name: 'Login' })
      this.logoutUser()
    }

    this.loadPlacesForUser()
  },
  methods: {
    ...mapMutations({
      logoutUser: 'auth/logoutUser',
      setPlacesForUser: 'dashboard/setPlacesForUser',
      setEntities: 'dashboard/setEntities'
    }),
    loadPlacesForUser: function () {
      this.$root.post('/dashboard/availableSites')
        .then((response) => {
          const data = response.data
          const dataStore = data?.[0]

          if (data?.success === 'FALSE' || dataStore?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data.message || dataStore?.message)
            return
          }

          this.setPlacesForUser(data?.[0])
          this.setEntities(data?.[1])
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {})
    }
  }
}
</script>

<style scoped>
.container-dashboard-views {
  margin-top: var(--h-navbar) !important;
  height: calc(100vh - var(--h-navbar));
  background-color: var(--bg-gray);
  overflow: auto;
}
</style>
