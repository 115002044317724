<template>
  <v-card id="TransactionsCorpTable">
    <v-card-title
      v-if="!loading && items.length"
      class="pb-2"
    >
      <v-switch
        v-model="typeTransac"
        inset
        label="solo referencias Autorizadas"
      />
      <v-spacer />
      <v-text-field
        v-model="search"
        placeholder="Buscar"
        v-bind="attrsSearching"
      />
    </v-card-title>
    <v-card-text class="card-tickets-generated">
      <v-skeleton-loader
        v-if="loading"
        type="table"
      />
      <v-alert
        v-if="!loading && !items.length"
        class="mb-0"
        icon="mdi-folder-alert-outline"
        prominent
        text
        type="warning"
        align="left"
      >
        No hay boletos generados!
      </v-alert>

      <v-data-table
        v-if="!loading && items.length"
        :headers="headers"
        :items="itemsComputed"
        :search="search"
        sort-by="f_creacion"
        :sort-desc="true"
        multi-sort
        mobile-breakpoint="Infinity"
      >
        <template v-slot:body="{ items: itemsSearched }">
          <tbody v-if="!itemsSearched.length">
            <td colspan="20">
              <v-alert
                class="mb-0 text"
                align="center"
              >
                Sin datos para esta búsqueda
              </v-alert>
            </td>
          </tbody>
          <tbody v-else>
            <template v-for="(transac, index) in itemsSearched">
              <tr
                :key="'history-transactions-' + transac.linearef + '-' + index"
                style="color: black"
                :style="{ 'background-color': $backgroundRowTable(index), 'border-top':'2px solid #75695f', 'color': transac.f_autorizacion ? '' : 'red' }"
              >
                <td align="center">
                  <strong>{{ transac.linearef }}</strong>
                </td>
                <td align="center">
                  <strong>{{ $formatFecha(transac.f_creacion, '/') }}</strong>
                </td>
                <td align="center">
                  <strong>{{ transac.NAME }}</strong>
                </td>
                <td align="center">
                  <strong>{{ transac.f_autorizacion ? $formatFecha(transac.f_autorizacion, '/') : '' }}</strong>
                </td>
                <td align="center">
                  <strong>{{ transac.total }}</strong>
                </td>
                <td align="center">
                  <strong>{{ transac.usados }}</strong>
                </td>
                <td align="center">
                  <strong>{{ transac.disponibles }}</strong>
                </td>
                <td align="center">
                  <v-btn
                    small
                    color="blue lighten-1"
                    class="ma-2"
                    @click="$emit('detailOfTransac', transac)"
                  >
                    <v-icon color="white">
                      mdi-format-list-checkbox
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TransactionsCorpTable',
  props: ['selectedCorp', 'items', 'loading', 'nowDate'],
  data () {
    return {
      typeTransac: false,
      attrsSearching: {
        label: '',
        clearable: true,
        dense: true,
        color: 'orange',
        'prepend-icon': 'mdi-magnify',
        class: 'search-text-field mr-4',
        'hide-details': true
      },
      search: '',
      headers: [
        { text: 'Línea de Referencia', value: 'linearef', align: 'center' },
        { text: 'Fecha Orden de Pago', value: 'f_creacion', align: 'center' },
        { text: 'Autorizó', value: 'NAME', align: 'center' },
        { text: 'Fecha de Autorización', value: 'f_autorizacion', align: 'center' },
        { text: 'Comprado(s)', value: 'total', align: 'center' },
        { text: 'Impreso(s)', value: 'usados', align: 'center' },
        { text: 'Disponible(s)', value: 'disponibles', align: 'center' },
        { text: 'Detalles', value: '', align: 'center', sortable: false }
      ]
    }
  },
  computed: {
    itemsComputed: function () {
      let items = []
      if (this.typeTransac) {
        const tempArray = this.items.filter(transac => transac.f_autorizacion !== '')
        items = Object.assign([], tempArray)
      } else items = Object.assign([], this.items)
      return items
    }
  }
}
</script>

<style scoped>
.card-tickets-generated {
  color: black !important;
}

.search-text-field {
  max-width: 400px !important;
}

.place-title {
  background: white;
  color: black;
  padding: 0 !important;
  padding-left: 5px !important;
  font-size: 16px !important;
  font-weight: bold;
}

.container-details, .container-details:hover {
  background-color: rgba(255, 165, 0, 0.4) !important;
}

.header-details {
  background: rgb(253 142 48 / 22%); color: black
}
</style>

<style scoped src="../../../assets/css/style-tables-dark.css">
</style>
