<template>
  <div id="MiCartWholesalers">
    <v-tabs
      v-model="cartItemActive"
      height="45px"
      dark
      grow
      show-arrows
    >
      <v-tabs-slider color="orange" />
      <v-tab
        v-for="(item, index) in itemsForCartTabs"
        :id="`tab${index+1}`"
        :key="`tab-${index+1}`"
        @click="changeRoute(index)"
      >
        <v-icon class=" ma-2">
          {{ item.icon }}
        </v-icon>
        {{ item.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="cartItemActive"
      touchless
    >
      <v-tab-item
        v-for="(item, index) in itemsForCartTabs"
        :key="`tab-${index+1}`"
      >
        <v-card
          tile
          flat
        >
          <v-toolbar
            v-if="item.type && items.length && !loading"
            height="45px"
            dark
            elevation="0"
            color="#1e1e1e"
          >
            <v-spacer />
            <v-text-field
              v-model="search"
              label=""
              outlined
              dark
              clearable
              dense
              placeholder="Escribe lo que desea buscar"
              prepend-icon="mdi-magnify"
              class="search-text-field"
              hide-details
            />
          </v-toolbar>
          <v-skeleton-loader
            v-if="loading"
            class="ma-4"
            type="table"
          />
          <v-card-text
            v-else
            :class="`container-items-cart ${item.type ? 'have-toolbar' : ''}`"
          >
            <v-alert
              v-if="!items.length"
              icon="mdi-folder-alert-outline"
              prominent
              text
              type="warning"
              align="left"
              class="ma-4"
            >
              {{ !item.type ? 'No hay boletos agregados al carrito!' : `${item.title} sin datos!` }}
            </v-alert>
            <TablePreCart
              v-if="!item.type && cartItemActive === index && items.length"
              :items="items"
              :totalPrecart="totalPrecart"
              @changeOnPrecart="getTotalForPrecart"
              @deleteItemPrecart="deleteItemPrecart"
              @generatePaymentOrder="generatePaymentOrder"
            />
            <TableCartTransaction
              v-if="item.type && cartItemActive === index && items.length"
              :ref="`TableCartTransaction${index}`"
              :items="items"
              :search="search"
              :tab="cartItemActive"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// Vue
import { mapMutations } from 'vuex'

// Mixins
import MYCART from '../../minixs/my-cart'

// Components
import TablePreCart from '../../components/wholesalers/TablePreCart.vue'
import TableCartTransaction from '../../components/wholesalers/TableCartTransaction.vue'

export default {
  name: 'MiCartWholesalers',
  components: {
    TablePreCart,
    TableCartTransaction
  },
  mixins: [
    MYCART
  ],
  beforeRouteUpdate (to, from, next) {
    this.cartItemActive = Number(to.params.id)
    next()
  },
  data () {
    return {
      cartItemActive: null,
      itemsForCartTabs: [
        { title: 'Mi carrito', icon: 'mdi-cart', type: 0 },
        { title: 'Ordenes de pago', icon: 'mdi-clipboard-text-clock-outline', type: 1 },
        { title: 'Autorizados', icon: 'mdi-clipboard-check-multiple-outline', type: 1 },
        { title: 'Bloqueados', icon: 'mdi-cancel', type: 1 },
        { title: 'Vencidos', icon: 'mdi-calendar-clock', type: 1 }
      ],
      loading: false,
      items: [],
      search: '',
      totalPrecart: 0,
      nameRoute: null
    }
  },
  watch: {
    cartItemActive: function (val) {
      this.items = []
      this.search = ''

      if (val === 0) this.getPrecartData()
      else this.getTransactionsData()
    }
  },
  mounted () {
    this.setHadAddCart(0)
    this.cartItemActive = Number(this.$route.params.id)
  },
  methods: {
    ...mapMutations({
      setHadAddCart: 'wholesaler/setHadAddCart'
    }),
    changeRoute: function (index) {
      if (this.$route.params.id === index) return
      this.$router.push({ params: { id: index } })
    }
  }
}
</script>

<style scoped>
.container-items-cart {
  max-height: calc(100vh - var(--h-navbar) - 45px);
  overflow: auto;
  color: #222 !important;
}

.container-items-cart.have-toolbar {
  max-height: calc(100vh - var(--h-navbar) - 45px - 45px);
}

.search-text-field {
  max-width: 600px !important;
}

@media (max-width: 600px) {
  .container-items-cart {
    padding: 15px 8px;
  }
}
</style>
