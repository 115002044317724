<template>
  <div id="ReportTicketsMayoTable">
    <StyledTableReport
      :headers="headers"
      :showTotals="false"
      :details="detailsReport"
      :title="titleReport"
      center
    >
      <tr
        v-for="(item, index1) in ticketsDetails"
        :key="`DetUser-${index1}`"
      >
        <td align="center">
          <strong>{{ item.Fecha }}</strong>
        </td>
        <td>
          <strong>{{ item.Descripcion }}</strong>
        </td>
        <td align="center">
          <strong>{{ $formatNum(item.Cantidad) }}</strong>
        </td>
        <td align="right">
          <strong>${{ $formatPrice(item.unitario) }}</strong>
        </td>
        <td align="right">
          <strong>${{ $formatPrice(item.total) }}</strong>
        </td>
        <td align="right">
          <strong>{{ item.IDNodoCuentaContable }}</strong>
        </td>
        <td align="left">
          <strong>{{ item.CuentaContable }}</strong>
        </td>
      </tr>
      <tr class="spacer-styled-table">
        <td
          align="right"
          colspan="2"
        >
          <strong>Total general</strong>
        </td>
        <td align="center">
          <strong>{{ $formatNum(totalTickets) }}</strong>
        </td>
        <td align="center">
          <strong />
        </td>
        <td align="center">
          <strong>${{ $formatPrice(totalSales) }}</strong>
        </td>
        <td align="center">
          <strong />
        </td>
        <td align="center">
          <strong />
        </td>
      </tr>
    </StyledTableReport>
  </div>
</template>

<script>
import StyledTableReport from '../tools/reports/StyledTableReport.vue'
export default {
  name: 'ReportTicketsMayoTable',
  components: { StyledTableReport },
  props: [
    'dateStart',
    'dateEnd',
    'titleReport',
    'ticketsDetails',
    'totalTickets',
    'totalSales'
  ],
  data () {
    return {
      headers: [
        { name: 'Fecha venta', attrs: {} },
        { name: 'Tipo boleto', attrs: {} },
        { name: 'Vendidos', attrs: {} },
        { name: 'Unitario', attrs: {} },
        { name: 'Total', attrs: {} },
        { name: 'Id tipo boleto', attrs: {} },
        { name: 'Cuenta contable', attrs: {} }
      ],
      detailsReport: [
        {
          key: 'Fecha',
          value: `${this.$formatFecha(this.dateStart)} al ${this.$formatFecha(this.dateEnd)}`,
          attrs: { class: 'pt-2' }
        }
      ]
    }
  }
}
</script>

<style scoped></style>
