import { mapState } from 'vuex'

// Components
import TitleReportsCard from '../components/tools/reports/TitleReportsCard.vue'
import InputDatePickerReport from '../components/tools/reports/InputDatePickerReport.vue'
import ContentReportsCard from '../components/tools/reports/ContentReportsCard.vue'
import ReportsCollectionsTable from '../components/reports/ReportsCollectionsTable.vue'

export default {
  data () {
    return {
      dateStart: '',
      dateEnd: '',
      loading: false,
      generated: false,
      placeSelected: null,

      fideA: 0,
      fideB: 0,
      usersDetails: [],
      ticketsDetails: [],
      totalPlace: 0,
      totalPlaceAtTickets: 0,
      receiptDetails: [],
      receiptTotal: [],
      anexos: []
    }
  },
  components: { TitleReportsCard, InputDatePickerReport, ContentReportsCard, ReportsCollectionsTable },
  computed: {
    ...mapState('dashboard', ['placesForUser']),
    namePlaceSelected: function () {
      let name = ''
      this.placesForUser.forEach(element => {
        if (element.id === this.placeSelected) name = element.name
      })
      return name
    }
  },
  mounted () {
    this.dateStart = this.$sumarDias(new Date(), -1)
    this.dateEnd = this.$sumarDias(new Date(), -1)
  },
  methods: {
    generateReport: function () {
      if (!this.placeSelected) {
        this.$swalAlert('warning', 'Seleccione un parador!')
        return
      }
      if (this.needPOS && !this.posSelected) {
        this.$swalAlert('warning', 'Seleccione una caja!')
        return
      }
      if (this.needCashier && !this.cashierSelected) {
        this.$swalAlert('warning', 'Seleccione un cajero!')
        return
      }

      this.loading = true
      this.generated = false

      const data = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        placeSelected: this.placeSelected,
        posSelected: this.posSelected,
        cashierSelected: this.cashierSelected
      }
      this.$root.post(`/reports/${this.apiName}`, data)
        .then(response => {
          const data = response.data
          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }

          const usersDetails = []
          let totalPlace = 0
          let totalPlaceAtTickets = 0
          const userDetails = data?.[0]
          this.ticketsDetails = data?.[1]
          const DetRep = data?.[2]
          const Fido = data?.[3]
          this.anexos = data?.[4]

          let textExpedidos = ''

          for (let i = 0; i < userDetails.length; i++) {
            let userDetailItem = userDetails?.[i]?.Jsonx
            if (!userDetailItem) continue
            userDetailItem = JSON.parse(userDetailItem)

            let totalForeign = 0
            const dataX = []
            let totalUser = 0

            for (let x = 0; x < userDetailItem.length; x++) {
              totalForeign += userDetailItem[x].B
            }

            totalUser += totalForeign

            const itemForeign = {
              Usuario: userDetailItem[0].Usuario,
              Caja: userDetailItem[0].Caja,
              total: totalForeign,
              CuentaBancaria: userDetailItem[0].CuentaBancaria,
              TipoPago: 'EFECTIVO',
              Cuota: 'Excedente Cuotas Extranjero'
            }

            const tempItemCash = userDetailItem.find(itemx => itemx.TipoPago === 'EFECTIVO')

            const itemCash = {
              Usuario: tempItemCash?.Usuario ?? userDetailItem[0].Usuario,
              Caja: tempItemCash?.Caja ?? userDetailItem[0].Caja,
              total: (tempItemCash?.total ?? 0) - totalForeign,
              CuentaBancaria: tempItemCash?.CuentaBancaria ?? '-',
              TipoPago: 'EFECTIVO',
              Cuota: 'Propios'
            }
            totalUser += itemCash.total

            const tempItemCard = userDetailItem.find(itemx => itemx.TipoPago === 'TARJETA')
            const itemCard = {
              Usuario: tempItemCard?.Usuario ?? userDetailItem[0].Usuario,
              Caja: tempItemCard?.Caja ?? userDetailItem[0].Caja,
              total: tempItemCard?.total ?? 0,
              CuentaBancaria: tempItemCard?.CuentaBancaria ?? 0,
              TipoPago: 'TARJETA',
              Cuota: 'Propios'
            }
            totalUser += itemCard.total
            totalPlace += totalUser

            if (this.placeSelected === 39) dataX.push(itemForeign)
            dataX.push(itemCash)
            dataX.push(itemCard)
            usersDetails.push({ dataX, total: totalUser })
          }

          for (let i = 0; i < this.ticketsDetails.length; i++) totalPlaceAtTickets += this.ticketsDetails[i].Importe
          for (let i = 0; i < DetRep.length; i++) {
            if (i > 0) textExpedidos += ', '
            textExpedidos += DetRep[i].Expedidos
          }

          this.fideB = Fido?.[0]?.B || 0
          this.fideA = Fido?.[0]?.A || 0
          this.totalPlace = totalPlace
          this.usersDetails = usersDetails
          this.totalPlaceAtTickets = totalPlaceAtTickets
          this.receiptDetails = textExpedidos
          this.receiptTotal = DetRep.length
        })
        .finally(() => {
          this.loading = false
          this.generated = true
        })
    }
  }
}
