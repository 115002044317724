import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { en, es } from 'vuetify/lib/locale'

Vue.use(Vuetify)
const options = {
  lang: {
    locales: { en, es },
    current: 'es'
  }
}
export default new Vuetify(options)
