<template>
  <div>
    <TitleReportsCard
      title="Boletos vendidos mayoristas con detalle"
      :loading="loading"
      @generateReport="generateReport"
    >
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateStart"
          :newMax="dateEnd"
          label="Seleccione una fecha de inicio"
          type="month"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateEnd"
          label="Seleccione una fecha de fin"
          type="month"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-select
          v-model="placeSelected"
          prepend-icon="mdi-bank"
          dense
          outlined
          :items="filtratedPlaces"
          name="id"
          item-text="name"
          item-value="id"
          label="Seleccione un parador"
          background-color="white"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
    </TitleReportsCard>
    <ContentReportsCard
      :loading="loading"
      :generated="generated"
      :fileName="fileName"
      :noData="false"
    >
      <TicketsMayoTable
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :titleReport="getTitleReport"
        :ticketsDetails="ticketsDetails"
        :totalTickets="totalTickets"
        :totalSales="totalSales"
      />
    </contentreportscard>
  </div>
</template>

<script>
// Vuex
import { mapState } from 'vuex'

// Components
import ContentReportsCard from '../tools/reports/ContentReportsCard.vue'
import InputDatePickerReport from '../tools/reports/InputDatePickerReport.vue'
import TitleReportsCard from '../tools/reports/TitleReportsCard.vue'
import TicketsMayoTable from './ReportTicketsMayoTable.vue'

export default {
  components: {
    TicketsMayoTable,
    TitleReportsCard,
    InputDatePickerReport,
    ContentReportsCard
  },
  data () {
    return {
      dateStart: '',
      dateEnd: '',
      loading: false,
      generated: false,
      placeSelected: false,
      ticketsDetails: [],
      totalTickets: 0,
      totalSales: 0
    }
  },
  computed: {
    ...mapState('dashboard', ['placesForUser']),
    filtratedPlaces: function () {
      const places = []
      this.placesForUser.forEach(element => {
        if (element.entidad === 1) {
          places.push(element)
        }
      })
      return places
    },
    namePlaceSelected: function () {
      let name = ''
      this.placesForUser.forEach(element => {
        if (element.id === this.placeSelected) name = element.name
      })
      return name
    },
    fileName: function () {
      return 'Boletos_vendidos_con_detalle_' + this.namePlaceSelected + '_del_' + this.dateStart + '_hasta_' + this.dateEnd
    },
    getTitleReport: function () {
      return `Boletos vendidos con detalle de "${this.namePlaceSelected}"`
    }
  },
  mounted () {
    this.dateStart = this.$sumarDias2(new Date())
    this.dateEnd = this.$sumarDias2(new Date())
  },
  methods: {
    generateReport: function () {
      if (!this.placeSelected) return this.$swalAlert('warning', 'Seleccione un parador!')

      this.loading = true
      this.generated = false
      this.ticketsDetails = []
      this.totalSales = 0
      this.totalTickets = 0

      const data = {
        placeSelected: this.placeSelected,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd
      }
      this.$root.post('/reports/ticketWholesalersMonth', data)
        .then(response => {
          const data = response.data
          const info = data?.[0]

          if (data?.success === 'FALSE' || info?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message || info.message)
            return
          }

          this.ticketsDetails = info
          let totalTickets = 0
          let totalSales = 0

          for (let x = 0; x < info.length; x++) {
            totalTickets += parseInt(info?.[x]?.Cantidad || 0)
            totalSales += parseInt(info?.[x]?.total || 0)
          }

          this.totalTickets = totalTickets
          this.totalSales = totalSales
        })
        .finally(() => {
          this.loading = false
          this.generated = true
        })
    }
  }
}
</script>

<style scoped>
</style>
