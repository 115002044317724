<template>
  <v-dialog
    id="TwoFactorAuth"
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Autenticación de dos factores
        <v-tooltip
          bottom
          color="black"
          max-width="350px"
        >
          <template v-slot:activator="{ on, attrs }">
            <sup
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small> mdi-help-circle-outline </v-icon>
            </sup>
          </template>
          <span>
            La autenticación de dos factores (2FA) es una capa adicional de seguridad para tus cuentas en línea, también beneficia la seguridad de tus acciones, como enviar boletos a imprimir. Añade una protección extra para asegurarte de que solo tú, como el dueño de la cuenta, puedas llevar a cabo estas tareas importantes. A pesar de la mayor seguridad, el proceso de inicio de sesión sigue siendo rápido y sencillo, lo que garantiza que tus cuentas estén más seguras y tus acciones estén protegidas.
          </span>
        </v-tooltip>
      </v-card-title>

      <v-card-text class="pt-1">
        <p>
          Para poder {{ message || 'continuar' }}, abra la aplicación
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=es"
            target="_blank"
          >
            Google Authenticator
          </a>
          en su dispositivo móvil. Ingrese este código en el campo 'Código de Autenticación' y haga clic en 'Aceptar' para completar el proceso de acceso a su cuenta. Asegúrese de ingresar el código correcto.
        </p>

        <v-form
          ref="authCodeForm"
          @submit.prevent="validateAuthCode"
        >
          <v-text-field
            v-model="authCode"
            label="Código de autenticación"
            :rules="[$rules.required, $rules.equalsLength(authCode, 6, 'Código de autenticación'), $rules.isNumber]"
            clearable
            hide-details="auto"
            outlined
            :disabled="loading"
            maxlength="6"
            @keypress="$input.isNumber"
          />
        </v-form>
      </v-card-text>

      <v-divider class="pt-0" />

      <v-card-actions class="container-actions-buttons">
        <v-btn
          :disabled="loading"
          :loading="loading"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="primary"
          color="#df8157 !important"
          :disabled="loading"
          :loading="loading"
          @click="validateAuthCode"
        >
          {{ textButton || 'Aceptar' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'TwoFactorAuth',
  props: ['value', 'message', 'textButton', 'user'],
  data () {
    return {
      loading: false,
      authCode: ''
    }
  },
  computed: {
    dialog: {
      get () { return this.value },
      set (value) {
        this.$emit('input', value)
        this.resetForm()
      }
    }
  },
  methods: {
    ...mapMutations({
      setLoadingMainApp: 'globalStates/setLoadingMainApp',
      setTextLoadingMainApp: 'globalStates/setTextLoadingMainApp'
    }),
    resetForm: function () {
      this.authCode = ''
      this.$refs.authCodeForm.reset()
      this.$refs.authCodeForm.resetValidation()
    },
    validateAuthCode: function () {
      if (!this.$refs.authCodeForm.validate()) return this.$Toast('error', 'Verifique los campos')

      this.loading = true
      this.setLoadingMainApp(true)
      this.setTextLoadingMainApp('Validando código de autenticación')

      const data = {
        authCode: this.authCode,
        userId: this.user?.id
      }
      this.$root.post('/auth/validateAuthCode', data).then(
        response => {
          const data = response.data

          if (data.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data.message)
            return
          }

          this.dialog = false
          this.$emit('success')
        }, () => { }
      ).finally(() => {
        this.loading = false
        this.setLoadingMainApp(false)
        this.setTextLoadingMainApp('')
      })
    }
  }
}
</script>

<style scoped>
.container-actions-buttons {
  justify-content: space-evenly;
}
</style>
