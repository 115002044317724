<template>
  <v-card flat>
    <div class="text-h7 mt-3 mb-0">
      <strong>Promtec Config</strong>
    </div>
    <v-divider />
    <v-container v-if="ticketInfo2">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="py-0"
        >
          <v-select
            v-model="ticketInfo.entidad"
            :items="entities"
            hide-details
            name="id"
            item-text="name"
            label="Entity"
            class="text-caption"
            @change="EntiChange()"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="py-0"
        >
          <v-select
            v-model="ticketInfo.sucursal"
            :items="sites2"
            :disabled="disableSitio"
            hide-details
            name="id"
            item-text="name"
            label="sites"
            class="text-caption"
            @change="SucuChange()"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="py-0"
        >
          <v-text-field
            v-model="ticketInfo.promtec"
            hide-details
            label="Cuota Promtec"
            class="text-caption"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="py-1"
        >
          <v-text-field
            v-model="ticketInfo.alias"
            hide-details
            label="Alias wholesalers"
            class="text-caption"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="py-1"
        >
          <v-text-field
            v-model="ticketInfo.nameReport"
            hide-details
            label="Alias reports"
            class="text-caption"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="py-0"
        >
          <v-checkbox
            v-model="mayo"
            label="Active in wholesalers"
            color="green"
            hide-details
            value="1"
            class="my-checkbox"
          />
        </v-col>
      </v-row>
    </v-container>

    <formularyAxess :ticketInfo="ticketInfo" />

    <v-card-actions class="mt-3">
      <v-btn
        dark
        color="secundary"
        :loading="loadingBtn"
        @click="dialogClose"
      >
        CANCELAR
      </v-btn>
      <v-spacer />
      <v-btn
        dark
        color="success"
        :loading="loadingBtn"
        @click="formPromtecSave"
      >
        GUARDAR
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import formularyAxess from './formularyAxess.vue'

export default {
  name: 'FormularyPromtec',
  components: {
    formularyAxess
  },
  props: ['ticketInfo2', 'entities', 'sites'],
  data () {
    return {
      ticketInfo: {
        IdSitio: null,
        NTARIFBLATTNR: null,
        aafy_name: null,
        alias: null,
        cuenta: null,
        entidad: null,
        id: null,
        idEntidad: null,
        mayo: null,
        nameReport: null,
        npool: null,
        person: null,
        persontype: null,
        pool: null,
        promtec: null,
        season: null,
        sucursal: null,
        ticket: null,
        tickettype: null,
        web: null
      },
      site: null,
      disableSitio: true,
      sites2: [],
      loadingBtn: false,
      mayo: ''
    }
  },
  methods: {
    formPromtecLoad () {
      this.loadingBtn = false
      this.ticketInfo = Object.assign({}, this.ticketInfo2)
      this.mayo = '' + this.ticketInfo.mayo
      this.EntiChange()
    },
    EntiChange () {
      this.disableSitio = true      
      const entidad = this.entities.find(itemx => itemx.id === this.ticketInfo.entidad)

      if (entidad) {
        this.ticketInfo.entidad = entidad.name
        this.ticketInfo.idEntidad = entidad.id
        this.disableSitio = false
        this.sites2 = this.sites.filter(itemx => itemx.entidad === entidad.id)
        this.SucuChange()
      }else{
        if(this.ticketInfo.entidad){
          const entidad = this.entities.find(itemx => itemx.name === this.ticketInfo.entidad)
          this.disableSitio = false
          this.sites2 = this.sites.filter(itemx => itemx.entidad === entidad.id)
        }
      }
    },
    SucuChange () {
      const sucu = this.sites.find(itemx => itemx.name === this.ticketInfo.sucursal)
      this.ticketInfo.IdSitio = sucu.id
    },
    dialogClose () {
      this.$emit('dialogClose')
    },
    formPromtecSave () {
      this.loadingBtn = true
      this.ticketInfo.mayo = this.mayo
      this.$emit('formPromtecSave', this.ticketInfo)
    }
  }
}
</script>

<style scoped>
::v-deep .my-checkbox {
  padding-top: 0px !important;
}
::v-deep .my-checkbox .v-label {
    font-size: 14px;
}
</style>
