<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <table v-if="spacer">
      <tr /><tr />
    </table>
    <table
      v-if="title || details.length"
      :id="`${idTable ? idTable+'-header' : ''}`"
      :class="center ? 'mx-auto' : 'w-100'"
    >
      <tr
        v-if="title"
        :align="center ? 'center' : ''"
      >
        <td
          :colspan="titleColspan ? titleColspan : headers.length"
          style="font-size: 20px"
        >
          <strong v-html="title" />
        </td>
      </tr>
      <tr
        v-for="itemDetails in details"
        :key="`item-details-table-${itemDetails.title}${itemDetails.value}`"
        :align="center ? 'center' : ''"
      >
        <td
          :colspan="titleColspan ? titleColspan : headers.length"
          style="font-size: 20px;"
          v-bind="itemDetails.attrs"
        >
          <strong>{{ itemDetails.key }}: </strong> {{ itemDetails.value }}
        </td>
      </tr>
      <tr v-if="center" />
    </table>
    <table
      v-if="showTable"
      :id="`${idTable}`"
      border="1"
      bordercolor="#272727"
      style="border-collapse: collapse !important;"
      :class="`styled-table ${center ? 'mx-auto' : 'w-100'}`"
    >
      <thead>
        <tr v-if="titleTable">
          <th
            :colspan="headers.length"
            class="text-center"
            align="center"
          >
            <strong v-html="titleTable" />
          </th>
        </tr>
        <tr v-if="headers.length">
          <th
            v-for="headerItem in headers"
            :key="`item-styled-table-${headerItem.name}`"
            v-bind="headerItem.attrs"
          >
            {{ headerItem.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <slot />
        <tr
          v-if="showTotals"
          class="spacer-styled-table"
        >
          <td
            :colspan="includeCajes ? totalColspan : 1"
            class="total-for-jspdf"
            align="right"
            style="border-top: 2px double black !important;"
          >
            <strong>Totales:</strong>
          </td>
          <td
            v-if="includeCajes"
            class="total-for-jspdf"
            align="center"
            style="border-top: 2px double black !important;"
          >
            <strong>{{ $formatNum(totals.tickets) }}</strong>
          </td>
          <td
            v-if="includeCajes"
            class="total-for-jspdf"
            align="center"
            style="border-top: 2px double black !important;"
          >
            <strong>{{ $formatNum(totals.canje) }}</strong>
          </td>
          <td
            class="total-for-jspdf"
            align="center"
            style="border-top: 2px double black !important;"
          >
            <strong>{{ $formatNum(totals.totalTickets) }}</strong>
          </td>
          <td
            v-if="includeTotalSales"
            align="right"
            class="total-for-jspdf"
            style="border-top: 2px double black !important;"
          >
            <strong>Total</strong>
          </td>
          <td
            v-if="includeTotalSales"
            class="total-for-jspdf"
            align="right"
            style="border-top: 2px double black !important;"
          >
            <strong>${{ $formatPrice(totals.total) }}</strong>
          </td>
          <td
            v-if="includeComission"
            class="total-for-jspdf"
            align="left"
            style="border-top: 2px double black !important;"
          >
            <strong>Subtotal</strong>
          </td>
          <td
            v-if="includeComission"
            class="total-for-jspdf"
            align="right"
            style="border-top: 2px double black !important;"
          >
            <strong>${{ $formatPrice($getSubtotal(total)) }}</strong>
          </td>
        </tr>
        <tr
          v-if="showIVA"
          class="spacer-styled-table"
        >
          <td
            :rowspan="includeSubtotal ? 3 : 2"
            class="total-for-jspdf total-write-for-jspdf"
            align="center"
            :colspan="(lettersColspan ? lettersColspan : headers.length) -2"
            :style="!showTotals ? 'border-top: 2px double black !important;' : ''"
          >
            <strong>SON: {{ $getTotalInletters(total) }}/100 MXN</strong> <br>
          </td>
          <td
            class="total-for-jspdf"
            align="left"
            :style="!showTotals ? 'border-top: 2px double black !important;' : ''"
          >
            <strong>{{ includeSubtotal ? 'Subtotal' : 'IVA' }}</strong>
          </td>
          <td
            class="total-for-jspdf"
            align="right"
            :style="!showTotals ? 'border-top: 2px double black !important;' : ''"
          >
            <strong>${{ includeSubtotal ? $formatPrice($getSubtotal(total)) : $formatPrice($getIVA(total)) }}</strong>
          </td>
        </tr>
        <tr
          v-if="showIVA"
          class="spacer-styled-table"
        >
          <td
            class="total-for-jspdf"
            align="left"
          >
            <strong>{{ includeSubtotal ? 'IVA' : 'Total' }}</strong>
          </td>
          <td
            class="total-for-jspdf"
            align="right"
          >
            <strong>${{ includeSubtotal ? $formatPrice($getIVA(total)) : $formatPrice($getSubtotal(total) + $getIVA(total)) }}</strong>
          </td>
        </tr>
        <tr
          v-if="showIVA && includeSubtotal"
          class="spacer-styled-table"
        >
          <td
            class="total-for-jspdf"
            align="left"
          >
            <strong>Total</strong>
          </td>
          <td
            align="right"
            class="total-for-jspdf"
          >
            <strong>${{ $formatPrice($getSubtotal(total) + $getIVA(total)) }}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'StyledTableReport',
  props: {
    idTable: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    titleColspan: {
      type: [String, Number],
      required: false,
      default: ''
    },
    details: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    titleTable: {
      type: String,
      required: false,
      default: ''
    },
    showTable: {
      type: Boolean,
      required: false,
      default: true
    },
    headers: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    total: {
      type: Number,
      required: false,
      default: 0
    },
    showIVA: {
      type: Boolean,
      required: false,
      default: false
    },
    showTotals: {
      type: Boolean,
      required: false,
      default: true
    },
    totals: {
      type: Object,
      required: false,
      default () {
        return { tickets: 0, canje: 0, totalTickets: 0, total: 0 }
      }
    },
    includeCajes: {
      type: Boolean,
      required: false,
      default: true
    },
    totalColspan: {
      type: Number,
      required: false,
      default: 2
    },
    includeTotalSales: {
      type: Boolean,
      required: false,
      default: true
    },
    includeComission: {
      type: Boolean,
      required: false,
      default: false
    },
    lettersColspan: {
      type: Number,
      required: false,
      default: 0
    },
    includeSubtotal: {
      type: Boolean,
      required: false,
      default: false
    },
    center: {
      type: Boolean,
      required: false,
      default: false
    },
    spacer: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>
.styled-table {
  border-collapse: collapse !important;
  border: 1px solid #272727;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table th {
  background-color: #009879;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}

.styled-table td {
  font-size: 14px;
}

.styled-table th,
.styled-table td {
  padding: 6px 8px !important;
  border: 1px solid #272727;
}

.styled-table tr:not(.spacer-styled-table):nth-child(2n + 0) {
  background: rgba(160, 255, 211, 0.24);
  height: 30px;
}

.styled-table tr:not(.spacer-styled-table):nth-child(2n + 1) {
  background: white;
  height: 30px;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.styled-table tbody tr:hover {
  background: rgba(160, 255, 211, 0.6);
}

.styled-table tbody tr.footer-styled-table td {
  border-top: 2px double black;
}

.spacer-styled-table {
  background-color: #009879 !important;
  padding: 0px !important;
  height: 20px;
  color: white;
}

.w-100 {
  width: 100% !important;
}
</style>
