import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(VueSweetalert2)

/* Icons => ['success', 'error', 'warning', 'info', 'question'] */

Vue.prototype.$swalAlert = function (icon, title, text = '') {
  this.$swal({ icon, title, text })
}

Vue.prototype.$Toast = function (icon, title, text = '') {
  if (title === 'Sin Registros') return
  this.$swal({
    icon,
    title,
    text,
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    showCloseButton: true,
    customClass: {
      container: 'container-toast-custome',
      closeButton: 'close-toast-button-custome'
    },
    didOpen: (toast) => {
      toast.addEventListener('click', this.$swal.close)
    }
  })
}

Vue.prototype.$ConfirmBox = function (icon, title, text = '', denyButtonText = 'No', confirmButtonText = 'SI') {
  return new Promise((resolve, reject) => {
    this.$swal({
      title,
      text,
      icon,
      showDenyButton: true,
      showCancelButton: false,
      reverseButtons: true,
      confirmButtonColor: '#2196F3',
      confirmButtonText,
      denyButtonText,
      denyButtonColor: '#EF5350'
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default new VueSweetalert2()
