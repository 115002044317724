<template>
  <v-dialog
    id="DialogUsersSpecifications"
    v-model="dialog"
    fullscreen
    hide-overlay
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
          dark
        >
          <v-toolbar-title>Especificaciones de usuarios</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <div class="container-user-description mt-6">
          <h2 class="text-center title-user">
            Roles
          </h2>
          <p class="text-center subtitle-user">
            Los roles indican clases de usuarios que tienen asignados ciertos subconjuntos de tareas, ya sea por elección propia o como resultado de la organización en la que se encuentran
          </p>
          <v-simple-table
            class="mt-6 mb-1"
          >
            <tbody>
              <tr style="background-color: rgba(255, 165, 0, 0.4)">
                <td>
                  <v-simple-table>
                    <thead>
                      <tr style="background: rgb(253 142 48 / 22%); color: black">
                        <td
                          class="MyTdPadding"
                          align="center"
                          style="font-size: 19px"
                        >
                          <strong>Funciones</strong>
                        </td>
                        <td
                          class="MyTdPadding"
                          align="center"
                        >
                          <strong>Cuenta Maestra </strong>
                          <v-tooltip
                            bottom
                            max-width="350px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <sup
                                v-bind="attrs"
                                v-on="on"
                              ><v-icon small> mdi-help-circle-outline </v-icon></sup>
                            </template>
                            <span>
                              Es la cuenta principal, para cada corporación solo puede existir una, esta es creada al momento del registro del mayorista. <br>
                              Por eso no aparece para ser establecida en un rol.
                            </span>
                          </v-tooltip>
                        </td>
                        <td
                          class="MyTdPadding"
                          align="center"
                        >
                          <strong>Administrador</strong>
                        </td>
                        <td
                          class="MyTdPadding"
                          align="center"
                        >
                          <strong>Coordinador</strong>
                        </td>
                        <td
                          class="MyTdPadding"
                          align="center"
                        >
                          <strong>Supervisor</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in contentTable"
                        :key="`${index}`"
                      >
                        <td class="MyTdPadding">
                          {{ item.title }}
                          <v-tooltip
                            right
                            max-width="350px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <sup><v-icon
                                v-bind="attrs"
                                small
                                v-on="on"
                              > mdi-help-circle-outline </v-icon></sup>
                            </template>
                            <span>
                              {{ item.description }}
                            </span>
                          </v-tooltip>
                        </td>
                        <td
                          class="MyTdPadding"
                          align="center"
                        >
                          <v-icon :color="item.cuentaMaestra ? 'success' : 'warning'">
                            mdi-{{ item.cuentaMaestra ? 'check-bold': 'close-thick' }}
                          </v-icon>
                        </td>
                        <td
                          class="MyTdPadding"
                          align="center"
                        >
                          <v-icon :color="item.administrador ? 'success' : 'warning'">
                            mdi-{{ item.administrador ? 'check-bold': 'close-thick' }}
                          </v-icon>
                        </td>
                        <td
                          class="MyTdPadding"
                          align="center"
                        >
                          <v-icon :color="item.coordinador ? 'success' : 'warning'">
                            mdi-{{ item.coordinador ? 'check-bold': 'close-thick' }}
                          </v-icon>
                        </td>
                        <td
                          class="MyTdPadding"
                          align="center"
                        >
                          <v-icon :color="item.supervisor ? 'success' : 'warning'">
                            mdi-{{ item.supervisor ? 'check-bold': 'close-thick' }}
                          </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogUsersSpecifications',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      contentTable: [
        {
          title: 'Administrar de usuarios',
          description: 'Crear, actualizar y desactivar usuarios pertenecientes al corporativo, así como brindarles roles.',
          cuentaMaestra: true,
          administrador: false,
          coordinador: false,
          supervisor: false
        },
        {
          title: 'Modificar logo corporativo',
          description: 'El logo de corporativo es la imagen representativa de él y sus miembros.',
          cuentaMaestra: true,
          administrador: false,
          coordinador: false,
          supervisor: false
        },
        {
          title: 'Administrar corporativo',
          description: 'Administración de la cuenta maestra, datos empresariales y accesos.',
          cuentaMaestra: true,
          administrador: false,
          coordinador: false,
          supervisor: false
        },
        {
          title: 'Visualizar catálogo',
          description: 'El catálogo es la variedad de boletos que el sistema ofrece, distribuido entre los distintos sitios.',
          cuentaMaestra: true,
          administrador: true,
          coordinador: true,
          supervisor: true
        },
        {
          title: 'Administrar carrito',
          description: 'En el carrito se almacenan las futuras órdenes de pago, es decir los boletos que la empresa desea adquirir.',
          cuentaMaestra: true,
          administrador: true,
          coordinador: true,
          supervisor: false
        },
        {
          title: 'Generar orden de pago',
          description: 'Es el proceso de adquirir los elementos del carrito.',
          cuentaMaestra: true,
          administrador: true,
          coordinador: true,
          supervisor: false
        },
        {
          title: 'Visualizar órdenes de pago',
          description: 'Las órdenes de pago es el proceso de vinculación de la compra de boletos del corporativo y la entrega de los mismo brindada por los administrativos de Aafy.',
          cuentaMaestra: true,
          administrador: true,
          coordinador: true,
          supervisor: true
        },
        {
          title: 'Visualizar estado de las órdenes de pago',
          description: 'Las órdenes de pago cuenta con diferentes estados los cuales son generadas, enviadas, autorizadas, bloqueadas.',
          cuentaMaestra: true,
          administrador: true,
          coordinador: true,
          supervisor: true
        },
        {
          title: 'Imprimir órdenes de pago',
          description: 'Se imprime la orden de pago para proceder al depósito bancario.',
          cuentaMaestra: true,
          administrador: true,
          coordinador: true,
          supervisor: false
        },
        {
          title: 'Capturar línea de referencia',
          description: 'Es la línea de referencia de la orden de pago. Al capturar la línea de referencia se hace el envió de la orden de pago a los administrativos de Aafy.',
          cuentaMaestra: true,
          administrador: true,
          coordinador: true,
          supervisor: false
        },
        {
          title: 'Visualizar boletos disponibles',
          description: 'Boletos disponibles es suma total de boletos en órdenes de pago autorizadas.',
          cuentaMaestra: true,
          administrador: true,
          coordinador: true,
          supervisor: true
        },
        {
          title: 'Generar boletos',
          description: 'Proceso de poner los boletos disponibles en lista de impresión, para su proximo próximo.',
          cuentaMaestra: true,
          administrador: true,
          coordinador: false,
          supervisor: false
        },
        {
          title: 'Visualizar boletos generados',
          description: 'Lista de impresión de boletos, es el módulo del sistema donde podremos imprimir boletos.',
          cuentaMaestra: true,
          administrador: true,
          coordinador: true,
          supervisor: true
        },
        {
          title: 'Imprimir boletos generados',
          description: 'Impresión de los boletos generados.',
          cuentaMaestra: true,
          administrador: true,
          coordinador: false,
          supervisor: false
        }
      ]
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>
.title-user{
  color: #444;
}

.subtitle-user{
  margin: 0px auto;
  max-width: 700px;
}

.container-user-description{
  max-width: 1000px;
  margin: 0px auto;
}
</style>
