<template>
  <v-card id="TicketsGeneratedTable">
    <v-card-title
      v-if="!loading && items.length"
      class="pb-2"
    >
      <v-spacer />
      <v-text-field
        v-model="search"
        placeholder="Buscar"
        v-bind="attrsSearching"
      />
      <v-btn
        color="success"
        @click="exportTable"
      >
        Exportar
      </v-btn>
    </v-card-title>
    <v-card-text class="card-tickets-generated">
      <v-skeleton-loader
        v-if="loading"
        type="table"
      />
      <v-alert
        v-if="!loading && !items.length"
        class="mb-0"
        icon="mdi-folder-alert-outline"
        prominent
        text
        type="warning"
        align="left"
      >
        No hay boletos generados!
      </v-alert>

      <v-data-table
        v-if="!loading && items.length"
        :headers="headers"
        :items="items"
        item-key="clave"
        class=" m-4 mt-2"
        :search="search"
        multi-sort
        mobile-breakpoint="Infinity"
      >
        <template v-slot:body="{ items: itemSearched }">
          <tbody v-if="!itemSearched.length">
            <td colspan="20">
              <v-alert
                class="mb-0 text"
                align="center"
              >
                Sin datos para esta búsqueda
              </v-alert>
            </td>
          </tbody>
          <tbody v-else>
            <template v-for="(ticket, index) in itemSearched">
              <tr
                :key="'tickets-generated-' + ticket.clave"
                class="pointman"
                :style="{ 'background-color': $backgroundRowTable(index), 'border-top':'2px solid #75695f' }"
                @click="clickrow(ticket.id)"
              >
                <td style="max-width: 10px">
                  <v-icon class="ma-2">
                    mdi-{{ ticket.id === expanded ? 'minus' : 'plus' }}
                  </v-icon>
                </td>
                <td>
                  <strong>{{ ticket.clave }}</strong>
                </td>
                <td align="center">
                  <strong>{{ ticket.f_solicitud }}</strong>
                </td>
                <td align="center">
                  <strong>{{ $formatNum(ticket.cantidad) }}</strong>
                </td>
                <td align="center">
                  {{ ticket.descript }}
                </td>
              </tr>
              <tr
                v-if="ticket.id === expanded"
                :key="'tickets-generated-details-' + ticket.clave"
                style="background-color: white !important"
              >
                <td colspan="5">
                  <v-simple-table
                    v-for="sitio in ticket.data"
                    :key="'tickets-generated-details-' + ticket.clave + '-place-' + sitio.idSitio"
                  >
                    <thead>
                      <tr>
                        <td class="place-title">
                          {{ sitio.nameSitio }}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="background-color: rgba(255, 165, 0, 0.4);">
                        <td>
                          <v-simple-table>
                            <thead>
                              <tr
                                style="background: rgb(253 142 48 / 22%); color: black"
                              >
                                <td align="center">
                                  <strong>Tipo de boleto</strong>
                                </td>
                                <td
                                  align="center"
                                  width="35%;"
                                >
                                  <strong>Nombre del Boleto</strong>
                                </td>
                                <td align="center">
                                  <strong>Cantidad</strong>
                                </td>
                                <td align="center">
                                  <strong>Conteo de impresiones</strong>
                                </td>
                                <td align="center">
                                  <strong>Historial de impresiones</strong>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="ticketType in sitio.tickets"
                                :key="'tickets-generated-details-' + ticket.clave + '-place-' + sitio.idSitio +'-id_ticket-' + ticketType.id_ticket"
                                class="w-100"
                              >
                                <td align="center">
                                  <strong>{{ ticketType.id_ticket }}</strong>
                                </td>
                                <td
                                  class="MyTdPadding"
                                  width="35%"
                                >
                                  <strong>{{ ticketType.descripcion }} </strong>
                                  <span v-if="ticketType.alias"> ({{ ticketType.alias }})</span>
                                </td>
                                <td align="center">
                                  <strong>{{ $formatNum(ticketType.cantidad) }}</strong>
                                </td>
                                <td align="center">
                                  <v-badge
                                    :content="ticketType.printed"
                                    :value="ticketType.printed"
                                    color="orange"
                                    overlap
                                  >
                                    <v-icon
                                      v-if="ticketType.printed"
                                      style="font-size: 30px"
                                    >
                                      mdi-printer
                                    </v-icon>
                                  </v-badge>
                                </td>
                                <td align="center">
                                  <v-btn
                                    small
                                    color="blue lighten-1"
                                    class="ma-2"
                                    :disabled="ticketType.printed < 1"
                                    @click="$emit('usedHistory', ticket, ticketType)"
                                  >
                                    <v-icon color="white">
                                      mdi-printer-eye
                                    </v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card-text>

    <div
      v-if="!loading && items.length"
      v-show="false"
      :id="idPrint"
    >
      <table>
        <tbody>
          <tr>
            <td
              align="right"
              colspan="4"
            >
              <strong>Boletos generados de: </strong> {{ selectedCorp.name }}
            </td>
          </tr>
          <tr>
            <td
              align="right"
              colspan="4"
            >
              <strong>Al día: </strong> {{ $formatFecha(nowDate) }}
            </td>
          </tr>
          <tr />
          <tr />
        </tbody>
      </table>

      <div
        v-for="ticket in items"
        :key="'tickets-generated-print-' + ticket.clave"
      >
        <table
          border="1"
          bordercolor="#272727"
          class="styled-table"
          style="border-collapse: collapse; width:100%;"
        >
          <thead>
            <tr>
              <td>
                <strong>Clave de transaccion</strong>
              </td>
              <td align="center">
                <strong>Fecha de solicitud</strong>
              </td>
              <td align="center">
                <strong>Cantidad</strong>
              </td>
              <td align="center">
                <strong>Descripcion</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ ticket.clave }}
              </td>
              <td align="center">
                {{ ticket.f_solicitud }}
              </td>
              <td align="center">
                {{ $formatNum(ticket.cantidad) }}
              </td>
              <td align="center">
                {{ ticket.descript }}
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div
                  v-for="sitio in ticket.data"
                  :key="'tickets-generated-details-' + ticket.clave + '-place-' + sitio.idSitio"
                >
                  <table style="width:100%;">
                    <tr>
                      <td
                        colspan="4"
                        style="font-size: 15px;"
                      >
                        <strong>{{ sitio.nameSitio }}</strong>
                      </td>
                    </tr>
                  </table>
                  <table
                    border="1"
                    bordercolor="#272727"
                    class="styled-table"
                    style="border-collapse: collapse; width:100%;"
                  >
                    <thead>
                      <tr>
                        <td align="center">
                          <strong>Tipo de boleto</strong>
                        </td>
                        <td>
                          <strong>Nombre del Boleto</strong>
                        </td>
                        <td align="center">
                          <strong>Cantidad</strong>
                        </td>
                        <td align="center">
                          <strong>Conteo de impresiones</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="ticketType in sitio.tickets"
                        :key="'tickets-generated-details-' + ticket.clave + '-place-' + sitio.idSitio +'-id_ticket-' + ticketType.id_ticket"
                      >
                        <td align="center">
                          {{ ticketType.id_ticket }}
                        </td>
                        <td>
                          {{ ticketType.descripcion }}
                          <span v-if="ticketType.alias"> ({{ ticketType.alias }})</span>
                        </td>
                        <td align="center">
                          {{ $formatNum(ticketType.cantidad) }}
                        </td>
                        <td align="center">
                          {{ ticketType.printed }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'TicketsGeneratedTable',
  props: ['selectedCorp', 'items', 'loading', 'nowDate'],
  data () {
    return {
      attrsSearching: {
        label: '',
        clearable: true,
        dense: true,
        color: 'orange',
        'prepend-icon': 'mdi-magnify',
        class: 'search-text-field mr-4',
        'hide-details': true
      },
      headers: [
        { text: '', value: 'icon', sortable: false },
        { text: 'Clave de transaccion', align: 'start', value: 'clave' },
        { text: 'Fecha de solicitud', value: 'f_solicitud', align: 'center' },
        { text: 'Cantidad', value: 'cantidad', align: 'center' },
        { text: 'Descripcion', value: 'descript', align: 'center' }
      ],
      search: '',
      expanded: null
    }
  },
  computed: {
    idPrint: function () {
      return `tickets-generated-for-corp-${this.selectedCorp.idcorp}`
    }
  },
  watch: {
    items: function () {
      this.expanded = null
      this.search = ''
    },
    search: function () {
      this.expanded = null
    }
  },
  methods: {
    clickrow (id) {
      this.expanded = this.expanded === id ? '' : id
    },
    exportTable: function () {
      let fileName = `${this.selectedCorp.name}-boletos_generados-a-${this.$formatFecha(this.nowDate, '-')}`
      fileName = fileName.replaceAll(' ', '_')
      this.$exportExcelFromJs(event, fileName, this.idPrint)
    }
  }
}
</script>

<style scoped>
.card-tickets-generated {
  color: black !important;
}

.search-text-field {
  max-width: 400px !important;
}

.place-title {
  background: white;
  color: black;
  padding: 0 !important;
  padding-left: 5px !important;
  font-size: 16px !important;
  font-weight: bold;
}

.container-details, .container-details:hover {
  background-color: rgba(255, 165, 0, 0.4) !important;
}

.header-details {
  background: rgb(253 142 48 / 22%); color: black
}
</style>

<style scoped src="../../../assets/css/style-tables-dark.css">
</style>
