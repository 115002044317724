<template>
  <div id="ReportByPOS">
    <TitleReportsCard
      title="Recaudación por caja"
      justify="start"
      :loading="loading"
      @generateReport="generateReport"
    >
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateStart"
          :newMax="dateEnd"
          label="Seleccione una fecha de inicio"
          :disabled="loading"
          @change="getListPOS"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateEnd"
          label="Seleccione una fecha de fin"
          :disabled="loading"
          @change="getListPOS"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-select
          v-model="placeSelected"
          prepend-icon="mdi-bank"
          dense
          outlined
          :items="placesForUser"
          name="id"
          item-value="id"
          item-text="name"
          label="Seleccione un parador"
          :disabled="loading"
          hide-details
          @change="getListPOS"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-select
          v-model="posSelected"
          prepend-icon="mdi-printer-pos"
          dense
          outlined
          :items="listPOS"
          name="id"
          item-value="id"
          item-text="name"
          label="Seleccione una caja"
          :disabled="loading || loadingPOS"
          :loading="loadingPOS"
          hide-details
          @change="generated = false"
        />
      </v-col>
    </TitleReportsCard>

    <ContentReportsCard
      :loading="loading"
      :generated="generated"
      :fileName="fileName"
      :noData="!usersDetails.length"
    >
      <ReportsCollectionsTable
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :titleReport="getTitleReport"
        :usersDetails="usersDetails"
        :ticketsDetails="ticketsDetails"
        :totalPlace="totalPlace"
        :totalPlaceAtTickets="totalPlaceAtTickets"
        :receiptDetails="receiptDetails"
        :receiptTotal="receiptTotal"
        :fideA="fideA"
        :fideB="fideB"
        :anexos="anexos"
        :placeId="placeSelected"
      />
    </ContentReportsCard>
  </div>
</template>

<script>
// Mixins
import COLLECTIONREPORTS from '../../minixs/collection-reports'

export default {
  name: 'ReportByPOS',
  mixins: [COLLECTIONREPORTS],
  data () {
    return {
      apiName: 'collectionByPOS',
      posSelected: null,
      listPOS: [],
      loadingPOS: false,
      needPOS: true,
      needCashier: false
    }
  },
  computed: {
    namePOSSelected: function () {
      let name = ''
      this.listPOS.forEach(element => {
        if (element.id === this.posSelected) name = element.name
      })
      return name
    },
    getTitleReport: function () {
      const titleReport = `Recaudación de "${this.namePlaceSelected}" - caja - "${this.namePOSSelected}"`
      return titleReport
    },
    fileName: function () {
      return 'Recaudación_de_' + this.namePlaceSelected + '_caja_' + this.namePOSSelected + '_' + '_del_' + this.dateStart + '_hasta_' + this.dateEnd
    }
  },
  methods: {
    getListPOS: function () {
      this.generated = false
      this.posSelected = null
      if (!this.placeSelected) return

      this.listPOS = []
      this.loadingPOS = true
      const data = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        placeSelected: this.placeSelected
      }

      this.$root.post('/reports/getListPOS', data)
        .then(response => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }
          this.listPOS = data
        })
        .finally(() => { this.loadingPOS = false })
    }
  }
}
</script>

<style>

</style>
