<template>
  <div id="ReportSalesByCashierWholesalers">
    <TitleReportsCard
      title="Reporte de cajero en mayoristas"
      :loading="loading"
      @generateReport="generateReport"
    >
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateStart"
          :newMax="dateEnd"
          label="Seleccione una fecha de inicio"
          :disabled="loading"
          @change="loadCashiers"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateEnd"
          label="Seleccione una fecha de fin"
          :disabled="loading"
          @change="loadCashiers"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-select
          v-model="cashierSelected"
          :items="listCashiers"
          label="Seleccione un cajero"
          prepend-icon="mdi-bank"
          name="id"
          item-value="id"
          item-text="name"
          dense
          outlined
          :loading="loadingCashiers"
          :disabled="loadingCashiers"
        />
      </v-col>
    </TitleReportsCard>

    <ContentReportsCard
      style="min-width: 80%;"
      :loading="loading"
      :generated="generated"
      :fileName="fileName"
      :noData="!detMayo.length"
    >
      <ReportSalesByCashierWholesalersTable
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :titleReport="getTitleReport"
        :detMayo="detMayo"
        :detUserMayo="detUserMayo"
        :totalMayo="totalMayo"
        :detailTransactions="detailTransactions"
      />
    </ContentReportsCard>
  </div>
</template>

<script>
// Components
import TitleReportsCard from '../tools/reports/TitleReportsCard.vue'
import InputDatePickerReport from '../tools/reports/InputDatePickerReport.vue'
import ContentReportsCard from '../tools/reports/ContentReportsCard.vue'
import ReportSalesByCashierWholesalersTable from './ReportSalesByCashierWholesalersTable.vue'

export default {
  name: 'ReportSalesByCashierWholesalers',
  components: { TitleReportsCard, InputDatePickerReport, ContentReportsCard, ReportSalesByCashierWholesalersTable },
  data () {
    return {
      dateStart: '',
      dateEnd: '',
      loading: false,
      generated: false,
      loadingCashiers: false,
      listCashiers: [],
      cashierSelected: null,
      detMayo: [],
      detUserMayo: [],
      totalMayo: 0,
      detailTransactions: []
    }
  },
  computed: {
    getNameCashier: function () {
      let name = ''
      this.listCashiers.forEach(item => {
        if (item.id === this.cashierSelected) name = item.name
      })
      return name
    },
    fileName: function () {
      return `Reporte_de_cajero_${this.getNameCashier}-${this.cashierSelected}_mayoristas_del_` + this.dateStart + '_hasta_' + this.dateEnd
    },
    getTitleReport: function () {
      return `Reporte de cajero "${this.getNameCashier}-${this.cashierSelected}" en mayoristas`
    }
  },
  mounted () {
    this.dateStart = this.$sumarDias(new Date(), 0)
    this.dateEnd = this.$sumarDias(new Date(), 0)
    this.loadCashiers()
  },
  methods: {
    loadCashiers: function () {
      this.generated = false
      this.loadingCashiers = true
      this.cashierSelected = null
      this.listCashiers = []

      const data = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd
      }
      this.$root
        .post('/reports/getListCashierWholesalers', data)
        .then((response) => {
          const data = response.data
          this.listCashiers = data
        }).finally(() => { this.loadingCashiers = false })
    },
    generateReport: function () {
      if (!this.cashierSelected) {
        this.$swalAlert('warning', 'Seleccione un cajero!')
        return
      }
      this.loading = true
      this.generated = false

      const data = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        cashierSelected: this.cashierSelected
      }
      this.$root
        .post('/reports/salesCashierWholesalers', data)
        .then((response) => {
          const data = response.data
          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }

          const { salesWholesalers, totalWholesalers, users, detailTransactions } = data
          this.detMayo = salesWholesalers
          this.detUserMayo = users
          this.totalMayo = totalWholesalers
          this.detailTransactions = detailTransactions
        })
        .finally(() => {
          this.loading = false
          this.generated = true
        })
    }
  }
}
</script>

<style>

</style>
