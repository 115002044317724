<template>
  <SecctionFormStyled subtitle="Autenticación de dos factores">
    <template v-slot:additionalInfo>
      <v-tooltip
        bottom
        dark
        color="black"
        max-width="350px"
      >
        <template v-slot:activator="{ on, attrs }">
          <sup
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              small
              dark
            > mdi-help-circle-outline </v-icon>
          </sup>
        </template>
        <span>
          La autenticación de dos factores (2FA) es una capa adicional de seguridad para tus cuentas en línea, también beneficia la seguridad de tus acciones, como enviar boletos a imprimir. Añade una protección extra para asegurarte de que solo tú, como el dueño de la cuenta, puedas llevar a cabo estas tareas importantes. A pesar de la mayor seguridad, el proceso de inicio de sesión sigue siendo rápido y sencillo, lo que garantiza que tus cuentas estén más seguras y tus acciones estén protegidas.
        </span>
      </v-tooltip>
    </template>
    <v-expand-transition>
      <v-btn
        v-if="!userData.hasTwoFactorAuth && !isActivingTwoFactorAuth"
        class="mx-auto d-block"
        color="green"
        dark
        @click="activingTwoFactorAuth"
      >
        <v-icon
          class="desktop"
          left
        >
          mdi-lock-clock
        </v-icon>
        <span class="desktop">Activar autenticación de dos factores</span>
        <span class="mobile">Activar 2FA</span>
      </v-btn>
    </v-expand-transition>
    <v-expand-transition>
      <div v-if="isActivingTwoFactorAuth">
        <v-row>
          <v-col cols="12">
            Para poder activar la autenticación de dos factores, abra la aplicación
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=es"
              target="_blank"
            >
              Google Authenticator
            </a>
            en su dispositivo móvil. Escanee el codigo qr, ingrese el código en el campo 'Código de Autenticación' y haga clic en 'Activar' para completar el proceso de activación. Asegúrese de ingresar el código correcto.
          </v-col>
          <v-col cols="12">
            <v-skeleton-loader
              v-if="loading"
              type="image"
              height="150px"
              max-width="150px"
            />
            <v-img
              v-if="!loading"
              :src="qrCodeUrl"
              height="150px"
              max-width="150px"
              class="mx-auto"
            />
          </v-col>
          <v-col cols="12">
            <v-form
              ref="authCodeForm"
              @submit.prevent="saveTwoFactorAuth"
            >
              <v-text-field
                v-model="authCode"
                label="Código de autenticación"
                :rules="[$rules.required, $rules.equalsLength(authCode, 6, 'Código de autenticación'), $rules.isNumber]"
                clearable
                hide-details="auto"
                outlined
                :disabled="loading"
                :loading="loading"
                @keypress="$input.isNumber"
              />
            </v-form>
          </v-col>
        </v-row>
        <ActionsButtonsForms
          :loading="loading"
          acceptText="Activar"
          @cancel="cancelActivationTwoFactorAuth"
          @accept="saveTwoFactorAuth"
        />
      </div>
    </v-expand-transition>

    <v-btn
      v-if="userData.hasTwoFactorAuth && !isActivingTwoFactorAuth"
      class="mx-auto d-block"
      color="red"
      dark
      @click="showTwoFactorAuth = true"
    >
      <v-icon left>
        mdi-lock-clock
      </v-icon>
      Desactivar autenticación de dos factores
    </v-btn>

    <TwoFactorAuth
      v-model="showTwoFactorAuth"
      message="desactivar autenticación de dos factores"
      text-button="Desactivar"
      :user="userData"
      @success="handleRemoveTwoFactorAuth"
    />
  </SecctionFormStyled>
</template>

<script>
import ActionsButtonsForms from '../../tools/ActionsButtonsForms.vue'
import SecctionFormStyled from '../../tools/SecctionFormStyled.vue'
import TwoFactorAuth from '../../tools/TwoFactorAuth.vue'
export default {
  name: 'FormTwoFactorAuth',
  components: { TwoFactorAuth, ActionsButtonsForms, SecctionFormStyled },
  props: ['userData'],
  data () {
    return {
      loading: false,
      qrCodeUrl: '',
      twoFactorSecret: '',
      isActivingTwoFactorAuth: false,
      authCode: '',
      showTwoFactorAuth: false
    }
  },
  methods: {
    activingTwoFactorAuth: function () {
      this.loading = true
      this.isActivingTwoFactorAuth = true
      this.$root.post('/auth/getTwoFactorAuthQrCode').then(
        response => {
          const data = response.data

          if (data.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data.message)
            return
          }

          this.qrCodeUrl = data.url
          this.twoFactorSecret = data.secret
        }
      ).finally(() => {
        this.loading = false
      })
    },
    cancelActivationTwoFactorAuth: function () {
      this.qrCodeUrl = ''
      this.twoFactorSecret = ''
      this.authCode = ''
      this.isActivingTwoFactorAuth = false
    },
    saveTwoFactorAuth: function () {
      if (!this.$refs.authCodeForm.validate()) return

      this.loading = true
      const data = {
        authCode: this.authCode,
        secret: this.twoFactorSecret
      }
      this.$root.post('/auth/activateTwoFactorAuth', data).then(response => {
        const data = response.data

        if (data.success === 'FALSE') {
          this.$swalAlert('error', 'Error', data.message)
          return
        }

        this.$swalAlert('success', 'Éxito', data.message)
        this.cancelActivationTwoFactorAuth()
        this.$emit('success')
      }).catch(error => {
        this.$swalAlert('error', 'Error', error.message)
      }).finally(() => {
        this.loading = false
      })
    },
    handleRemoveTwoFactorAuth: function () {
      this.loading = true
      this.$root.post('/auth/removeTwoFactorAuth').then(response => {
        const data = response.data

        if (data.success === 'FALSE') {
          this.$swalAlert('error', 'Error', data.message)
          return
        }

        this.$swalAlert('success', 'Éxito', data.message)
        this.$emit('success')
      }).catch(error => {
        this.$swalAlert('error', 'Error', error.message)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.desktop {
  display: block;
}

.mobile {
  display: none;
}

@media (max-width: 599px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

}
</style>
