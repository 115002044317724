var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"CompaniesWholesalers"}},[_c('v-card',[(!_vm.loading && _vm.corpsList.length)?_c('v-card-title',{staticClass:"pb-2"},[_vm._l((_vm.filteredStatus),function(item){return _c('v-chip',{key:'chip-status-to-filter'+item,staticClass:"mx-2",attrs:{"small":"","close":"","color":_vm.stateColors[item]},on:{"click:close":function($event){return _vm.closeFilterStatus(item)}}},[_vm._v(" "+_vm._s(_vm.states[item].text)+" ")])}),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.filteredStatus.length && _vm.filteredDocumentSent.length),expression:"filteredStatus.length && filteredDocumentSent.length"}],attrs:{"vertical":""}}),_vm._l((_vm.filteredDocumentSent),function(item){return _c('v-chip',{key:'chip-document-sent-status-to-filter'+item,staticClass:"mx-2",attrs:{"small":"","close":"","color":_vm.stateColors[item]},on:{"click:close":function($event){return _vm.closeFilterDocumentSent(item)}}},[_vm._v(" "+_vm._s(_vm.documentSentStatus[item].text)+" ")])}),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"placeholder":"Buscar","append-icon":"mdi-filter-variant","append":attrs},on:{"append":on,"click:append":function($event){_vm.showFilters = !_vm.showFilters}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},'v-text-field',_vm.attrsSearching,false))]}}],null,false,3245233660),model:{value:(_vm.showFilters),callback:function ($$v) {_vm.showFilters=$$v},expression:"showFilters"}},[_c('v-card',[_c('v-subheader',[_vm._v(" Solo mostrar empresas con estado: ")]),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.filteredStatus),callback:function ($$v) {_vm.filteredStatus=$$v},expression:"filteredStatus"}},_vm._l((_vm.states),function(item){return _c('v-list-item',{key:'item-status-to-filter'+item.value,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"orange","input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)]}}],null,true)})}),1),_c('v-subheader',[_vm._v(" Envio de constancia: ")]),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.filteredDocumentSent),callback:function ($$v) {_vm.filteredDocumentSent=$$v},expression:"filteredDocumentSent"}},_vm._l((_vm.documentSentStatus),function(item){return _c('v-list-item',{key:'item-status-to-filter'+item.value,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"orange","input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)]}}],null,true)})}),1)],1)],1)],2):_vm._e(),_c('v-card-text',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(!_vm.loading && !_vm.corpsList.length)?_c('v-alert',{staticClass:"mb-0",attrs:{"icon":"mdi-folder-alert-outline","prominent":"","text":"","type":"warning","align":"left"}},[_vm._v(" No hay empresas registradas ")]):_vm._e(),(!_vm.loading && _vm.corpsList.length)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.corpsList,"search":_vm.search,"multi-sort":"","sort-by":['name'],"mobile-breakpoint":"Infinity"},scopedSlots:_vm._u([{key:"item.idcorp",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.idcorp))])]}},{key:"item.rfc",fn:function(ref){
var item = ref.item;
return [_c('strong',{class:((_vm.$rules.isRFC(item.rfc) === true ? 'success' : 'red') + "--text")},[_vm._v(" "+_vm._s(item.rfc)+" ")])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('strong',{class:((_vm.$rules.isPhone(item.phone) === true ? 'success' : 'red') + "--text")},[_vm._v(" "+_vm._s(item.phone)+" ")])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"d-block text-center mx-auto",attrs:{"color":_vm.stateColors[item.state],"small":""},on:{"click":function($event){return _vm.changeCompanyState(item)}}},[_c('strong',[_vm._v(_vm._s(_vm.states[item.state].text))])])]}},{key:"item.sentdocument",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.stateColors[item.sentdocument],"small":""},on:{"click":function($event){return _vm.changeCompanyState(item)}}},[_c('strong',[_vm._v(_vm._s(item.sentdocument === 1 ? 'Enviada' : 'No enviada'))])])]}}],null,true)}):_vm._e()],1)],1),_c('DialogStateCompany',{attrs:{"company":_vm.companySelected,"states":_vm.states},on:{"refreshData":_vm.getDataCorpsData},model:{value:(_vm.dialogChangeState),callback:function ($$v) {_vm.dialogChangeState=$$v},expression:"dialogChangeState"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }