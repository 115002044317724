<template>
  <div
    id="LoadingAnimation"
    align="center"
    :class="`Loading ${fullScreen ? '' : 'no-fullScreen'} ${small ? 'small' : ''}`"
  >
    <v-row class="ma-0">
      <v-col
        cols="12"
        :class="`${small ? 'mb-0 pb-0' : ''}`"
      >
        <div class="sk-folding-cube">
          <div class="sk-cube1 sk-cube" />
          <div class="sk-cube2 sk-cube" />
          <div class="sk-cube4 sk-cube" />
          <div class="sk-cube3 sk-cube" />
        </div>
      </v-col>
      <v-col cols="12">
        <div>
          {{ textLoading }}
          <span class="dots">.</span><span class="dots">.</span><span class="dots">.</span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'LoadingAnimation',
  props: [
    'textLoading',
    'fullScreen',
    'small'
  ]
}
</script>

<style scoped>
.Loading{
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
}

.Loading.no-fullScreen {
  background: white;
  color: rgba(0, 0, 0, 0.9);
  font-size: 20px;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.sk-folding-cube {
  margin: 20px auto;
  width: 80px;
  height: 80px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.no-fullScreen >>> .sk-folding-cube {
  margin: 10px auto;
  width: 50px;
  height: 50px;
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px 0px 0px 0px;
  background-color: #b10202;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
  border-radius: 5px 0px 0px 0px;
  background: #ff0500;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
  border-radius: 5px 0px 0px 0px;
  background: #fe4d00;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
  border-radius: 5px 0px 0px 0px;
  background: #ff9c00;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.dots {
  margin-right: 2px;
  font-size: 25px;
  display: inline-block;
  transform: translateY(-2px);
  animation-name: jumb;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.dots:nth-child(1) {
  animation-delay:0s;
}

.dots:nth-child(2) {
  animation-delay:.2s;
}

.dots:nth-child(3) {
  animation-delay:.4s;
}

@keyframes jumb {
  0% {
  transform: translateY(-2px);
  }
  50% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(-2px);
  }
}
</style>
