<template>
  <div id="ReportSalesWholesalers">
    <TitleReportsCard
      title="Reporte general de ventas mayoristas"
      :loading="loading"
      @generateReport="generateReport"
    >
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateStart"
          :newMax="dateEnd"
          label="Seleccione una fecha de inicio"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateEnd"
          label="Seleccione una fecha de fin"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
    </TitleReportsCard>

    <ContentReportsCard
      style="min-width: 80%;"
      :loading="loading"
      :generated="generated"
      :fileName="fileName"
      :noData="!detMayo.length"
    >
      <ReportSalesWholesalersTable
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :titleReport="getTitleReport"
        :detMayo="detMayo"
        :detUserMayo="detUserMayo"
        :totalMayo="totalMayo"
        :detailCashier="detailCashier"
      />
    </ContentReportsCard>
  </div>
</template>

<script>
// Components
import TitleReportsCard from '../tools/reports/TitleReportsCard.vue'
import InputDatePickerReport from '../tools/reports/InputDatePickerReport.vue'
import ContentReportsCard from '../tools/reports/ContentReportsCard.vue'
import ReportSalesWholesalersTable from './ReportSalesWholesalersTable.vue'

export default {
  name: 'ReportSalesWholesalers',
  components: { TitleReportsCard, InputDatePickerReport, ContentReportsCard, ReportSalesWholesalersTable },
  data () {
    return {
      dateStart: '',
      dateEnd: '',
      loading: false,
      generated: false,
      detMayo: [],
      detUserMayo: [],
      totalMayo: 0,
      detailCashier: []
    }
  },
  computed: {
    fileName: function () {
      return 'Reporte_general_de_mayoristas_del_' + this.dateStart + '_hasta_' + this.dateEnd
    },
    getTitleReport: function () {
      return 'Reporte general de ventas mayoristas'
    }
  },
  mounted () {
    this.dateStart = this.$sumarDias(new Date(), 0)
    this.dateEnd = this.$sumarDias(new Date(), 0)
  },
  methods: {
    generateReport: function () {
      this.loading = true
      this.generated = false

      const data = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd
      }
      this.$root.post('/reports/salesWholesalers', data)
        .then(response => {
          const data = response.data
          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }

          const { salesWholesalers, users, totalWholesalers, detailCashier } = data
          this.detMayo = salesWholesalers
          this.detUserMayo = users
          this.totalMayo = totalWholesalers
          this.detailCashier = detailCashier
        })
        .finally(() => {
          this.loading = false
          this.generated = true
        })
    }
  }
}
</script>

<style>

</style>
