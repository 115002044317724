<template>
  <table>
    <template v-for="(ticket, index) in dataTable">
      <tr>
        <td>
          <table border="1">
            <thead>
              <tr>
                <th class="place-title f12" align="left">
                  UID No.
                </th>
                <th class="place-title f12">
                  Ticket Type
                </th>
                <th class="place-title f12">
                  Person Type
                </th>
                <th class="place-title f12">
                  Transaction
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>'{{ ticket.c10 }}</td>
                <td>
                  {{ ticket.c11 }}
                </td>
                <td>
                  {{ ticket.c12 }}
                </td>
                <td>
                  {{ ticket.c13 }}
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <table>
                    <thead>
                      <tr>
                        <th class="place-title f12">
                          Transaction Type
                        </th>
                        <th class="place-title f12">
                          Trans. No.
                        </th>
                        <th class="place-title f12">
                          CPU-Number
                        </th>
                        <th class="place-title f12">
                          CPU-Trans. No.
                        </th>
                        <th class="place-title f12">
                          Usage Time
                        </th>
                        <th class="place-title f12">
                          POE #
                        </th>
                        <th class="place-title f12">
                          Lane
                        </th>
                        <th class="place-title f12">
                          Ticket Number
                        </th>
                        <th class="place-title f12">
                          Validation
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style="background-color: rgba(255, 165, 0, 0.4);"
                        v-for="repeat in ticket.tickets_repeat"
                      >
                        <td>
                          {{ repeat.c1 }}
                        </td>
                        <td>
                          {{ repeat.c2 }}
                        </td>
                        <td>'{{ repeat.c3 }}</td>
                        <td>
                          {{ repeat.c4 }}
                        </td>
                        <td>
                          <strong>{{ repeat.c5 }}</strong>
                        </td>
                        <td align="center">
                          {{ repeat.c6 }}
                        </td align="center">
                        <td>
                          {{ repeat.c7 }}
                        </td>
                        <td align="center">
                          {{ repeat.c8 }}
                        </td>
                        <td align="center">
                          {{ repeat.c14 }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
    </template>
  </table>
</template>

<script>
export default {
  name: "DropZoneTableGroup",
  data() {
    return {};
  },
  props: ["dataTable", "headers"],
};
</script>
