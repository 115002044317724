<template>
  <div class="cards-corps">
    <v-row v-if="!loading">
      <v-col v-if="!computedCorps.length">
        <v-card>
          <v-card-text>
            <v-alert
              class="mb-0"
              icon="mdi-folder-alert-outline"
              prominent
              text
              type="warning"
              align="left"
            >
              {{ noDataText ? noDataText : textNoData }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-for="(item, index) in computedCorps"
        :key="'item-corp-card' + index"
        cols="12"
        sm="6"
        md="3"
        lg="2"
      >
        <div class="container-card-corp">
          <v-card
            class="card-corp"
            @click="selectCorp(item)"
          >
            <figure class="container-img-corp">
              <!-- <img
                :src="item.avatar"
                class="img-corp"
              > -->
              <v-img
                :src="item.avatar"
                class="img-corp"
                contain
              />
            </figure>

            <div class="name-corp">
              {{ item.name }}
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col
        v-for="index in 6"
        :key="`skeletons-corps-${index}`"
        cols="12"
        sm="6"
        md="3"
        lg="2"
      >
        <v-skeleton-loader
          type="image"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'CardsCorps',
  props: ['corpsList', 'search', 'loading', 'isLockTrasactions', 'noDataText'],
  computed: {
    computedCorps: function () {
      if (!this.search) return this.corpsList
      const tempCorps = this.corpsList.filter((corp) =>
        corp?.name.toLowerCase().includes(this.search?.toLowerCase())
      )

      return tempCorps
    },
    textNoData: function () {
      let textNoData = 'No hay empresas con transacciones '
      textNoData += this.isLockTrasactions ? 'bloqueadas' : 'autorizadas'
      if (this.search) textNoData += ` para '${this.search}'`
      textNoData += '!'
      return textNoData
    }
  },
  methods: {
    selectCorp: function (item) {
      const corp = JSON.parse(JSON.stringify(item ?? {}))
      this.$emit('selectCorp', corp)
    }
  }
}
</script>

<style scoped>
.container-card-corp{
  height: 100% !important;
  background-color: transparent;
}

.card-corp {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  transition: all .1s ease;
}

.container-img-corp {
  width: 100%;
  height: 140px;
  display: grid;
  place-items: center;
  padding: 5px;
}

.img-corp {
  max-height: calc(140px - 10px);
  max-width: 100%;
  object-fit: contain;
}

.name-corp {
  padding: 10px;
  margin-bottom: 10px;
  background: orange;
  color: white;
  border-radius: 0 !important;
  font-weight: bold;
  text-align: center;
}

.card-corp::after {
  content: '';
  width: 100%;
  top: 0;
  height: 0px;
  position: absolute;
  border-radius: 0px 0px 200% 0%;
  background: rgba(245, 124, 0, 0.3);
  transition: all .3s ease;
  z-index: -1;
}

.container-card-corp:hover .card-corp {
  cursor: pointer;
  background: #ffdb9a;
  transform: translateY(-10px) scale(1.03);
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 12px 17px 2px rgba(0, 0, 0, 0.14),
    0px 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
}

.container-card-corp:hover .card-corp .name-corp {
  background: transparent !important;
  color: rgb(53, 53, 53);
}
.container-card-corp:hover .card-corp::after {
  height: 60%;
}
</style>
