<template>
  <div class="SecctionFormStyled mb-5">
    <div class="container-form">
      <p class="subtitle">
        {{ subtitle }}
        <slot name="additionalInfo" />
      </p>
      <div class="container-form-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecctionFormStyled',
  props: ['subtitle']
}
</script>

<style scoped>
.subtitle {
  background-color: #444;
  color: white;
  padding: 5px 20px;
  text-align: center;
  position: relative;
  border-bottom: 1px solid #CFD8DC;
  margin-bottom: 0px;
}

.container-form {
  border-radius: 5px;
  border: 1px solid #CFD8DC;
}

.container-form-content {
  padding: 20px 10px;
}
</style>
