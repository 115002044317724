<template>
  <div id="AccountSettingDashboard">
    <v-card
      class="mx-auto py-3 px-6 ma-xs-0 card-form-dashboard"
      max-width="730px"
      elevation="5"
    >
      <SecctionFormStyled subtitle="Imagen de perfil">
        <v-img
          :src="userData.avatar"
          max-width="350px"
          max-height="350px"
          min-width="150px"
          min-height="150px"
          class="mx-auto"
          alt="Imagen de perfil"
          contain
        >
          <v-btn
            class="change-image-button"
            fab
            dark
            color="green"
            @click="changeProfileImage()"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </v-img>
      </SecctionFormStyled>
      <v-form
        id="Form-profile"
        ref="formProfile"
        lazy-validation
      >
        <SecctionFormStyled subtitle="Datos de usuario">
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                :value="userData.name"
                name="Nombre"
                label="Nombre"
                readonly
                disabled
                outlined
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                :value="userData.cargo"
                name="Puesto"
                label="Puesto"
                readonly
                disabled
                outlined
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="password"
                :type="hidePassword ? 'password' : 'text'"
                :append-icon="`mdi-${hidePassword ? 'eye-off' : 'eye'}`"
                name="Nueva contraseña"
                label="Nueva contraseña"
                outlined
                :rules="[$rules.required]"
                hide-details="auto"
                @click:append="hidePassword = !hidePassword"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="passwordAgain"
                :type="hidePasswordAgain ? 'password' : 'text'"
                :append-icon="`mdi-${hidePasswordAgain ? 'eye-off' : 'eye'}`"
                name="Repita contraseña"
                label="Repita contraseña"
                outlined
                :rules="[$rules.required]"
                hide-details="auto"
                @click:append="hidePasswordAgain = !hidePasswordAgain"
              />
            </v-col>
          </v-row>
        </SecctionFormStyled>

        <v-row class="mt-0 mb-1 row-butons-form-profile">
          <v-btn @click="$router.go(-1)">
            Regresar
          </v-btn>
          <v-btn
            class="primary"
            color="#df8157 !important"
            @click="saveAccountSetting"
          >
            Guardar
          </v-btn>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import SecctionFormStyled from '../../components/tools/SecctionFormStyled.vue'

export default {
  name: 'AccountSettingDashboard',
  components: { SecctionFormStyled },
  data () {
    return {
      password: '',
      hidePassword: true,
      passwordAgain: '',
      hidePasswordAgain: true
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData'
    })
  },
  methods: {
    ...mapMutations({
      refreshUser: 'auth/refreshUser'
    }),
    changeProfileImage: async function () {
      const file = await this.$swalUploadProfileImage()
      if (!file) return

      let imgName = file.name.split('.')
      const userName = this.userData?.name.replaceAll(' ', '_')
      imgName = userName + '.' + imgName[1]
      const formData = new FormData()
      formData.append('file', file)
      formData.append('fileName', imgName)

      this.$root.post('/dashboard/uploadImageProfile', formData)
        .then(response => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }

          this.refreshUserData()
          this.$Toast('success', 'Imagen modificada', null)
        })
    },
    saveAccountSetting: function () {
      if (this.password === '' || this.passwordAgain === '') {
        this.$swalAlert('error', null, 'Ingrese una contraseña')
        return
      }

      if (this.password !== this.passwordAgain) {
        this.$swalAlert('error', null, 'Las contraseñas no coinciden')
        return
      }

      const data = { password: this.password }
      this.$root.post('/auth/updatePassword', data)
        .then(response => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }
          this.resetForm()
          this.$swalAlert('success', 'Contraseña modificada', null)
        })
    },
    refreshUserData: function () {
      this.$root.post('/auth/refreshUserData')
        .then((response) => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data.message)
            return
          }

          this.refreshUser(data)
        })
    },
    resetForm: function () {
      this.password = ''
      this.passwordAgain = ''
      this.$refs.formProfile.resetValidation()
    }
  }
}
</script>

<style scoped>
#AccountSettingDashboard {
  height: calc(100vh - var(--h-navbar));
  display: grid;
  place-items: center;
  overflow: auto;
  padding: 40px;
  background-color: var(--bg-gray);
}

.card-form-dashboard {
  min-width: 80%;
}

.row-butons-form-profile {
  justify-content: space-evenly;
}

.change-image-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

@media (max-width: 960px) {
  #AccountSettingDashboard {
    padding: 20px;
  }
}

@media (max-width: 500px) {
  #AccountSettingDashboard {
    padding: 20px 10px;
  }
}
</style>
