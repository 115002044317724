<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <div class="text-h7 mt-5 mb-0">
      <strong>Axess Config</strong>
    </div>
    <v-divider />
    <v-container v-if="ticketInfo">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="py-0"
        >
          <v-text-field
            v-model="ticketInfo.pool"
            readonly
            disabled
            hide-details
            label="Pool"
            class="text-caption"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="py-0"
        >
          <v-text-field
            v-model="ticketInfo.tickettype"
            readonly
            disabled
            hide-details
            label="Ticket Type"
            class="text-caption"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="py-0"
        >
          <v-text-field
            v-model="ticketInfo.persontype"
            readonly
            disabled
            hide-details
            label="Person Type"
            class="text-caption"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="py-0"
        >
          <v-text-field
            v-model="ticketInfo.season"
            readonly
            disabled
            hide-details
            label="Season"
            class="text-caption"
          />
        </v-col>
        <v-col
          v-if="fullData"
          cols="12"
          sm="6"
          md="3"
          class="py-0"
        >
          <v-text-field
            v-model="ticketInfo.day"
            readonly
            disabled
            hide-details
            label="Day"
            class="text-caption"
          />
        </v-col>
        <v-col
          v-if="fullData"
          cols="12"
          sm="6"
          md="3"
          class="py-0"
        >
          <v-text-field
            v-model="ticketInfo.time"
            readonly
            disabled
            hide-details
            label="Time"
            class="text-caption"
          />
        </v-col>
        <v-col
          v-if="fullData"
          cols="12"
          sm="6"
          md="3"
          class="py-0"
        >
          <v-text-field
            v-model="ticketInfo.FTARIF"
            readonly
            disabled
            hide-details
            label="Price"
            class="text-caption"
          />
        </v-col>
        <v-col
          v-if="fullData"
          cols="12"
          sm="6"
          md="3"
          class="py-0"
        >
          <v-text-field
            v-model="ticketInfo.BTARIFAKTIV"
            readonly
            disabled
            hide-details
            label="Active"
            class="text-caption"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="py-1"
        >
          <v-text-field
            v-model="ticketInfo.aafy_name"
            disabled
            hide-details
            label="Ticket Name"
            class="text-caption"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="py-1"
        >
          <v-text-field
            v-model="ticketInfo.cuenta"
            disabled
            hide-details
            label="No. Account Contable"
            class="text-caption"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'FormularyAxess',
  props: ['ticketInfo'],
  data () {
    return {
      fullData: false
    }
  }
}
</script>

<style scoped>
 .theme--light.v-input--is-disabled, .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
      color: #000 !important;
      opacity: 0.9;
  }

</style>
