<template>
  <div id="ReportsCollectionsTable">
    <StyledTableReport
      :title="titleReport"
      :details="detailsTitle"
      :titleColspan="headersTicketsDetails.length"
      :headers="[]"
      :showTable="false"
      :showTotals="false"
      center
    />
    <StyledTableReport
      v-if="anexos.length"
      :headers="headersAnexos"
      titleTable="Anexos"
      :showTotals="false"
    >
      <tr
        v-for="item in anexos"
        :key="'Anexos' + item.url"
      >
        <td>{{ item.cajero }}</td>
        <td align="center">
          {{ item.caja }}
        </td>
        <td>{{ item.description }}</td>
        <td align="center">
          {{ item.fecha }}
        </td>
        <td align="right">
          ${{ $formatPrice(item.monto) }}
        </td>
        <td>
          <v-btn
            class="success"
            :href="item.url"
            target="_black"
          >
            <v-icon> mdi-file-pdf-box </v-icon>
          </v-btn>
        </td>
      </tr>
    </StyledTableReport>

    <StyledTableReport
      :headers="headersUserDetails"
      :showTotals="false"
      :spacer="!!anexos.length"
    >
      <template v-for="(itemUserDetails, index1) in usersDetails">
        <tr
          v-for="(userTrasacctions, index) in itemUserDetails.dataX"
          :key="`user-details-${userTrasacctions.Usuario}-${userTrasacctions.Caja}-${userTrasacctions.TipoPago}-${index}`"
        >
          <td>
            {{ userTrasacctions.Usuario }}
          </td>
          <td align="center">
            {{ userTrasacctions.Caja }}
          </td>
          <td>{{ userTrasacctions.TipoPago }}</td>
          <td>{{ userTrasacctions.Cuota }}</td>
          <td align="center">
            {{ userTrasacctions.CuentaBancaria }}
          </td>
          <td align="right">
            ${{ $formatPrice(userTrasacctions.total) }}
          </td>
        </tr>
        <tr :key="`total-details-user-${index1}`">
          <td
            colspan="5"
            align="right"
          >
            <strong>Total</strong>
          </td>
          <td align="right">
            <strong>${{ $formatPrice(itemUserDetails.total) }}</strong>
          </td>
        </tr>
        <tr
          :key="`spacer-total-details-user-${index1}`"
          class="spacer-styled-table"
        >
          <td colspan="6" />
        </tr>
      </template>
      <tr>
        <td
          colspan="5"
          align="right"
        >
          <strong>Total recaudación</strong>
        </td>
        <td align="right">
          <strong>${{ $formatPrice(totalPlace) }}</strong>
        </td>
      </tr>
    </StyledTableReport>

    <StyledTableReport
      titleTable="Informe de Ventas"
      :headers="headersTicketsDetails"
      :totals="getTotalsTickets"
      spacer
    >
      <tr
        v-for="(item, index1) in ticketsDetails"
        :key="`tickets-details-${index1}`"
      >
        <td>{{ item.Cuenta }}</td>
        <td>{{ item.Concepto }}</td>
        <td align="center">
          {{ $formatNum(item.Cantidad) }}
        </td>
        <td align="center">
          {{ $formatNum(item.Canje) }}
        </td>
        <td align="center">
          {{ $formatNum(FixCanje(item.Cantidad) + FixCanje(item.Canje)) }}
        </td>
        <td align="right">
          ${{ $formatPrice(item.PrecioIndividual) }}
        </td>
        <td align="right">
          ${{ $formatPrice(item.Importe) }}
        </td>
      </tr>
    </StyledTableReport>

    <StyledTableReport
      v-if="placeId === 39"
      :headers="[{name: 'Concepto'}, {name: 'Importe'}]"
      :showTotals="false"
      center
      spacer
    >
      <tr>
        <td>
          <strong>Fideicomiso A:</strong>
        </td>
        <td align="right">
          ${{ $formatPrice(fideA) }}
        </td>
      </tr>
      <tr>
        <td>
          <strong>Fideicomiso B:</strong>
        </td>
        <td align="right">
          ${{ $formatPrice(fideB) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>TOTAL</strong>
        </td>
        <td align="right">
          <strong>${{ $formatPrice(fideA + fideB) }}</strong>
        </td>
      </tr>
    </StyledTableReport>

    <StyledTableReport
      :headers="[{ name: `Recibos expedidos: \t  ${ receiptTotal }`, attrs: {class: 'text-left'} }]"
      :showTotals="false"
      center
      spacer
    >
      <tr>
        <td>
          {{ receiptDetails || 'Sin Recibos expedidos' }}
        </td>
      </tr>
    </StyledTableReport>
  </div>
</template>

<script>
import StyledTableReport from '../tools/reports/StyledTableReport.vue'

export default {
  name: 'ReportsCollectionsTable',
  components: { StyledTableReport },
  props: [
    'dateStart',
    'dateEnd',
    'titleReport',
    'usersDetails',
    'ticketsDetails',
    'totalPlace',
    'totalPlaceAtTickets',
    'receiptDetails',
    'receiptTotal',
    'fideA',
    'fideB',
    'placeId',
    'anexos'
  ],
  data () {
    return {
      detailsTitle: [
        {
          key: 'Fecha',
          value: `${this.$formatFecha(this.dateStart)} al ${this.$formatFecha(this.dateEnd)}`,
          attrs: { class: 'pt-2' }
        }
      ],
      headersAnexos: [
        { name: 'Nombre del cajero', attrs: {} },
        { name: 'No. Caja', attrs: {} },
        { name: 'Descripcion', attrs: {} },
        { name: 'Fecha', attrs: { width: '100px' } },
        { name: 'Monto', attrs: { } },
        { name: 'Abrir', attrs: { width: '80px' } }
      ],
      headersUserDetails: [
        { name: 'Nombre del cajero', attrs: {} },
        { name: 'No. Caja', attrs: {} },
        { name: 'Tipo de pago', attrs: {} },
        { name: 'Cuota', attrs: {} },
        { name: 'Cuenta de deposito', attrs: {} },
        { name: 'Importe', attrs: { width: '150px' } }
      ],
      headersTicketsDetails: [
        { name: 'Cuenta', attrs: {} },
        { name: 'Concepto', attrs: { class: 'text-left' } },
        { name: 'Vendidos', attrs: {} },
        { name: 'Canjes', attrs: {} },
        { name: 'Total', attrs: {} },
        { name: 'Precio Individual', attrs: {} },
        { name: 'Importe', attrs: { width: '150px' } }
      ]
    }
  },
  computed: {
    getTotalsTickets: function () {
      let tickets = 0
      let canje = 0

      this.ticketsDetails.forEach(function (item) {
        tickets += item.Cantidad
        canje += item.Canje
      })
      return {
        tickets,
        canje,
        totalTickets: tickets + canje,
        total: this.totalPlaceAtTickets
      }
    }
  },
  methods: {
    FixCanje: (item = 0) => item
  }
}
</script>

<style scoped>

</style>
