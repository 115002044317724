<template>
  <div id="RemainingTickets">
    <v-card>
      <v-card-title>
        <v-spacer />
        <v-btn
          class="success"
          @click="getCashRegisters()"
        >
          <v-icon left>
            mdi-refresh
          </v-icon>
          Refrescar
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader
          v-if="loading"
          type="table"
        />
        <v-alert
          v-if="!loading && !items.length"
          class="mb-0"
          icon="mdi-folder-alert-outline"
          prominent
          text
          type="warning"
          align="left"
        >
          No hay datos para mostrar!
        </v-alert>

        <v-data-table
          v-if="!loading && items.length"
          :headers="headers"
          :items="items"
          sort-by="active"
          :sort-desc="true"
          class="elevation-1"
          mobile-breakpoint="Infinity"
        >
          <template v-slot:item="{ item }">
            <tr :style="item.active ? 'background: #b6edb699;' : ''">
              <td class="text-center">
                {{ item.caja === 0 ? 29 : item.caja }}
              </td>
              <td class="text-left">
                {{ item.catego }}
              </td>
              <td class="text-center">
                {{ item.total }}
              </td>
              <td class="text-center">
                {{ item.last_sell }}
              </td>
              <td :class="`text-center ${getClassToRemainingTickets(item.total)}`">
                <strong>{{ 100000 - item.total }}</strong>
              </td>
              <td class="text-center">
                {{ item.active }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'RemainingTickets',
  data () {
    return {
      headers: [
        { text: 'Caja', value: 'caja', align: 'center' },
        { text: 'Categoria', value: 'catego', align: 'left' },
        { text: 'Boletos Usados', value: 'total', align: 'center' },
        { text: 'Fecha', value: 'last_sell', align: 'center' },
        {
          text: 'Boletos Restantes',
          value: 'remainingTickets',
          align: 'center'
        },
        { text: 'active', value: 'active', align: 'center', sortable: true }
      ],
      items: [],
      loading: false
    }
  },
  mounted () {
    this.getCashRegisters()
  },
  methods: {
    getClassToRemainingTickets: function (total) {
      const remaining = (100000 - total)
      if (remaining <= 0) return ''
      if (remaining < 15000) return 'red--text'
      if (remaining < 30000) return 'orange--text'
      return 'green--text'
    },
    getCashRegisters: function () {
      this.loading = true
      this.$root.post('/CC/wholesalersPOS').then(
        (response) => {
          const data = response.data

          if (data.success === 'FALSE') {
            this.$swalAlert('error', '', data.message)
            return
          }
          this.items = data || []
        },
        () => {}
      ).finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped>
#RemainingTickets {
  max-height: calc(100vh - var(--h-navbar));
  overflow-y: auto;
  padding: 20px;
}
</style>

<style scoped src="../../assets/css/style-tables-dark.css">
</style>
