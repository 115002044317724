<template>
  <v-slide-y-reverse-transition>
    <div
      v-if="dialog"
      class="container-print-history"
    >
      <v-card
        class="dialog-print-history"
        outlined
      >
        <v-toolbar
          height="55px"
          dark
        >
          <v-toolbar-title>{{ transactionPrint.clave }} </v-toolbar-title>
          <v-spacer />
          <v-btn
            color="success"
            class="ma-2 ml-7"
            @click="exportTable"
          >
            Exportar
          </v-btn>
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="loading">
          <v-skeleton-loader type="table" />
        </v-card-text>

        <v-card-text v-if="!loading">
          <h2 class="mb-4 black--text">
            {{ ticketType.descripcion }} <span v-if="ticketType.alias"> ({{ ticketType.alias }})</span>
          </h2>

          <v-simple-table>
            <tbody>
              <tr style="background-color: rgba(255, 165, 0, 0.4)">
                <td>
                  <v-simple-table>
                    <thead>
                      <tr style="background: rgb(253 142 48 / 22%); color: black">
                        <td align="center">
                          <strong>Solicitó</strong>
                        </td>
                        <td align="center">
                          <strong>Fecha de Solicitud</strong>
                        </td>
                        <td align="center">
                          <strong>Hora de Solicitud</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(ticket, index) in transactionPrintData"
                        :key="`TicketsP-${ticket}-${index}`"
                      >
                        <td align="center">
                          <strong>{{ ticket.name }}</strong>
                        </td>
                        <td align="center">
                          <strong>{{ ticket.fecha }}</strong>
                        </td>
                        <td align="center">
                          <strong>{{ ticket.hora }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <div
            v-show="false"
            :id="idPrint"
          >
            <table style="width:100%;">
              <tr align="right">
                <td
                  colspan="3"
                  style="font-size: 16px;"
                >
                  <strong>Clave de transacción: </strong>  {{ transactionPrint.clave }}
                </td>
              </tr>
              <tr align="right">
                <td
                  colspan="3"
                  style="font-size: 16px;"
                >
                  <strong>Boleto: </strong>  {{ ticketType.descripcion }} <span v-if="ticketType.alias"> ({{ ticketType.alias }})</span>
                </td>
              </tr>
              <tr align="right">
                <td
                  colspan="3"
                  style="font-size: 16px;"
                >
                  <strong>Compañía: </strong>  {{ transactionPrint.name }}
                </td>
              </tr>
              <tr />
            </table>

            <table
              style="width:100%;"
              border="1"
              bordercolor="#272727"
              class="styled-table"
            >
              <thead>
                <tr>
                  <td align="center">
                    <strong>Solicitó</strong>
                  </td>
                  <td align="center">
                    <strong>Fecha de Solicitud</strong>
                  </td>
                  <td align="center">
                    <strong>Hora de Solicitud</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(ticket, index) in transactionPrintData"
                  :key="`TicketsPrinted-${ticket}-${index}`"
                >
                  <td align="center">
                    <strong>{{ ticket.name }}</strong>
                  </td>
                  <td align="center">
                    <strong>{{ ticket.fecha }}</strong>
                  </td>
                  <td align="center">
                    <strong>{{ ticket.hora }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-slide-y-reverse-transition>
</template>

<script>
export default {
  name: 'ImpressionHistoryTicketsCorps',
  props: ['value', 'loading', 'nowDate', 'transactionPrint', 'transactionPrintData', 'ticketType'],
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    idPrint: function () {
      return `history-tickets-generated-${this.transactionPrint.clave}_ticket_${this.ticketType.id_ticket}`
    },
    fileName: function () {
      return `Historial_impresiones_de_${this.transactionPrint.clave}_ticket_${this.ticketType.id_ticket}_a_${this.$formatFecha(this.nowDate, '-')}`
    }
  },
  methods: {
    exportTable: function (event) {
      this.$exportExcelFromJs(event, this.fileName, this.idPrint)
    }
  }
}
</script>

<style scoped>
.container-print-history {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
}

.dialog-print-history {
  min-width: 100%;
  min-height: 100%;
}

.place-name {
  background: white;
  color: black;
  padding: 0 !important;
  padding-left: 5px !important;
  font-size: 17px !important;
  font-weight: bold;
}
</style>
