<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-row
    class="FormUserData"
    justify-sm="center"
  >
    <v-col
      cols="12"
      sm="6"
    >
      <v-text-field
        v-model="formUser.email"
        append-icon="mdi-email"
        name="Correo Personal"
        label="Correo Personal"
        type="email"
        outlined
        :rules="[$rules.required, $rules.isEmail]"
        hide-details="auto"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <v-text-field
        v-model="formUser.clave"
        :type="hidePassword ? 'password' : 'text'"
        :append-icon="`mdi-${hidePassword ? 'eye-off' : 'eye'}`"
        name="Contrasena"
        label="Contraseña"
        outlined
        :rules="[$rules.required]"
        hide-details="auto"
        @click:append="hidePassword = !hidePassword"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model="formUser.name"
        append-icon="mdi-account"
        name="Nombre"
        label="Nombre"
        type="text"
        outlined
        :rules="[$rules.required]"
        hide-details="auto"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <v-text-field
        v-model="formUser.name2"
        append-icon="mdi-account"
        name="ApellidoPaterno"
        label="Apellido Paterno"
        type="text"
        outlined
        hide-details="auto"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <v-text-field
        v-model="formUser.name3"
        append-icon="mdi-account"
        name="ApellidoMaterno"
        label="Apellido Materno"
        type="text"
        outlined
        hide-details="auto"
      />
    </v-col>
    <v-col
      v-if="isAddUser"
      cols="12"
      sm="6"
    >
      <v-select
        v-model="formUser.rol"
        :items="roles"
        label="Rol"
        outlined
        item-text="name"
        item-value="subrol"
        :rules="[$rules.required]"
        hide-details="auto"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FormUserData',
  props: {
    formUser: {
      type: Object,
      require: true,
      default () {
        return {}
      }
    },
    isAddUser: {
      type: Boolean,
      required: false,
      default: true
    },
    roles: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      hidePassword: true
    }
  },
  watch: {
    formUser: function () {
      this.hidePassword = true
    }
  }
}
</script>

<style>

</style>
