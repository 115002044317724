<template>
  <v-row id="CorpStatusSettings">
    <v-col
      lg="6"
      md="7"
      cols="12"
    >
      <table class="table-details-corp">
        <tr>
          <td>
            Estado de la empresa<v-tooltip
              top
              dark
              color="black"
              max-width="350px"
            >
              <template v-slot:activator="{ on, attrs }">
                <sup
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    small
                  > mdi-help-circle-outline </v-icon>
                </sup>
              </template>
              <span>
                <strong>Tipos de estados de la empresa: </strong> <br><br>
                <strong>Activa:</strong> Empresa verificada, puede realizar compras. <br><br>
                <strong>Inactiva:</strong> La empresa no ha sido verificada y, al llegar la fecha de verificación, la empresa se suspenderá temporalmente. <br><br>
                <strong>En verificación:</strong> La empresa se encuentra en proceso de verificación, puede realizar compras. <br><br>
                <strong>Suspensión temporal:</strong> La empresa no ha sido verificada. Es necesario enviar la constancia de situación fiscal para que podamos verificar tu empresa. No podrá navegar en la plataforma hasta que se verifique su empresa; solo podrá ver la configuración de la cuenta.
              </span>
            </v-tooltip>:
          </td>
          <td>
            <v-chip
              class="desktop mx-3"
              :color="stateColor"
            >
              {{ stateText }}
            </v-chip>
            <strong :class="`mobile ${stateColor}--text`">{{ stateText }}</strong>
          </td>
        </tr>
        <tr>
          <td>
            Constancia de situación fiscal:
          </td>
          <td>
            <v-chip
              class="desktop mx-3"
              :color="sentDocumentColor"
            >
              {{ sentDocumentText }}
            </v-chip>
            <strong :class="`mobile ${sentDocumentColor}--text`">{{ sentDocumentText }}</strong>
          </td>
        </tr>
      </table>
    </v-col>
    <v-col
      lg="6"
      md="5"
      cols="12"
      class="d-flex align-center justify-center"
    >
      <p
        v-if="textMessage"
        class="text--disabled mb-0"
      >
        {{ textMessage }}
      </p>
      <v-btn
        v-if="showButtonSentDocument"
        class="d-block mx-auto"
        color="primary"
        dark
        @click="$emit('sendDocument')"
      >
        <v-icon left>
          mdi-file-document-arrow-right-outline
        </v-icon>
        Enviar constancia
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CorpStatusSettings',
  props: {
    formCorp: {
      type: Object,
      required: true
    }
  },
  computed: {
    stateColor: function () {
      const stateColor = {
        0: 'gray',
        1: 'success',
        2: 'warning',
        3: 'error'
      }
      const color = stateColor?.[this.formCorp?.companyState] || 'grey'
      return color
    },
    stateText: function () {
      const stateText = {
        0: 'Inactiva',
        1: 'Activa',
        2: 'En verificación...',
        3: 'Suspensión temporal'
      }
      const text = stateText?.[this.formCorp?.companyState] || 'No definido'
      return text
    },
    sentDocumentColor: function () {
      const sentDocumentColor = {
        0: 'gray',
        1: 'success'
      }
      const color = sentDocumentColor?.[this.formCorp?.companySentDocument] || 'gray'
      return color
    },
    sentDocumentText: function () {
      const sentDocumentText = {
        0: 'Documento no enviado',
        1: 'Documento enviado'
      }

      const text = sentDocumentText?.[this.formCorp?.companySentDocument] || 'No definido'
      return text
    },
    textMessage: function () {
      const messages = {
        0: 'Tu empresa se encuentra inactiva, envía tu constancia de situación fiscal para que podamos verificar tu empresa.',
        1: 'Tu empresa se encuentra activa.',
        2: 'Tu empresa se encuentra en proceso de verificación, en breve te notificaremos el resultado.',
        3: this.formCorp?.companySentDocument === 0
          ? ''
          : 'Tu empresa se encuentra suspendida temporalmente, en breve te notificaremos el resultado de la verificación.'
      }
      return messages?.[this.formCorp?.companyState] || ''
    },
    showButtonSentDocument: function () {
      if (this.formCorp?.companyState === 2) return false
      if (this.formCorp?.companyState === 3 && this.formCorp?.companySentDocument === 0) return true
      return this.formCorp?.companyState === 0 && this.formCorp?.companySentDocument === 0
    }
  }
}
</script>

<style scoped>
.mobile {
  display: none;
}

@media (max-width: 959px) {
  .table-details-corp {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  .table-details-corp td {
    border-bottom: 1px solid #ccc;
    padding: 5px;
  }
}

@media (max-width: 599px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}
</style>
