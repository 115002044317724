<template>
  <div
    id="HomeWholesalers"
    class="user-noselect"
  >
    <div class="container-home-catalogue">
      <div class="container-places-available user-noselect">
        <div
          v-for="(item, index1) in placesAvailable"
          :key="`nav-place-item-${index1}`"
          :data-page="index1"
          :class="`nav-place-item nav-place-${index1}`"
          :style="{ backgroundImage: 'url(\'' + item.img + '\')' }"
          @click="setPlaceActive(item, index1)"
        >
          <div class="nav-place-title">
            {{ item.name }}
          </div>
          <div class="nav-place-price">
            ${{ $formatPrice(item.cantidad) }} <ExchangeCurrency />
          </div>
        </div>
      </div>

      <BackgroundCatalogue
        :placesAvailable="placesAvailable"
        :activeNavPlace="activeNavPlace"
      />

      <div class="container-tickets-available">
        <v-select
          v-model="placeSelectedSelect"
          class="title-place-active-select"
          :items="placesAvailable"
          label="Seleccione un lugar"
          dark
          item-color="orange"
          color="orange"
          outlined
          hide-details
          item-text="name"
          item-value="id"
        />
        <div class="title-place-active">
          {{ placeSelected.name }}
        </div>
        <v-row class="row-container-tickets-available">
          <v-col
            v-for="(item, index1) in ticketsForSelectedPlace"
            :key="`ticketsAvailable-${index1}`"
            cols="12"
          >
            <v-card class="card-available-ticket">
              <div class="card-ticket-title">
                <strong>{{ item.alias }}</strong> <br>
                <small v-if="item.alias2">({{ item.alias2 }})</small>
              </div>
              <div
                align="right"
                style="padding-right: 15px; font-size:13px;"
              >
                <strong> Vencimiento: {{ item.fin }} </strong>
              </div>
              <div class="card-ticket-price">
                ${{ $formatPrice(item.costo) }} <ExchangeCurrency />
              </div>
              <InputAmountTickets
                v-if="canEdit"
                v-model="item.cantidad"
                class="ma-3 mt-0 amount-tickets-available"
                @change="changeAmountOfTicket"
              />
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="footer-home-catalogue">
      <a
        class="ref-terms-conditions"
        @click="showTermsConditions = true"
      >
        Términos y condiciones
      </a>
    </div>

    <DialogTermsConditions v-model="showTermsConditions" />
  </div>
</template>

<script>
// Vue
import { mapGetters, mapMutations, mapState } from 'vuex'

// Components
import DialogTermsConditions from '../../components/tools/DialogTermsConditions.vue'
import BackgroundCatalogue from '../../components/wholesalers/BackgroundCatalogue.vue'

export default {
  name: 'HomeWholesalers',
  components: {
    DialogTermsConditions,
    BackgroundCatalogue
  },
  data () {
    return {
      ticketsForSelectedPlace: [],
      activeNavPlace: null,
      placeSelected: {},
      rol: 0,
      showTermsConditions: false,
      isChangingPlace: false
    }
  },
  computed: {
    ...mapState('wholesaler', ['placesAvailable', 'ticketsAvailable']),
    ...mapGetters({
      userData: 'auth/userData'
    }),
    canEdit () {
      return this.userData?.subrol < 3
    },
    placeSelectedSelect: {
      get () {
        return this.placeSelected?.id
      },
      set (id) {
        const item = this.placesAvailable.find(place => place.id === id)
        const index = this.placesAvailable.findIndex(place => place.id === id)
        this.setPlaceActive(item, index)
      }
    }
  },
  watch: {
    placesAvailable: function () {
      this.initCatalogue()
    }
  },
  mounted () {
    this.initCatalogue()
  },
  methods: {
    ...mapMutations({
      setTotalForSale: 'wholesaler/setTotalForSale'
    }),
    initCatalogue: function () {
      if (!this.placesAvailable.length) return
      setTimeout(() => {
        this.setPlaceActive(this.placesAvailable[0], 0)
      }, 100)
    },
    setPlaceActive: function (item, indexActive) {
      if (!item) return
      if (this.activeNavPlace === indexActive) return false
      this.activeNavPlace = indexActive

      const navPlaceItems = document.querySelectorAll('.nav-place-item')
      navPlaceItems.forEach(item => { item.classList.remove('nav-place-item-active') })

      const activeNavItem = document.querySelector('.nav-place-' + this.activeNavPlace)
      if (activeNavItem) activeNavItem.classList.add('nav-place-item-active')

      const container = document.querySelector('.container-tickets-available')
      if (container) container.style.opacity = 0

      setTimeout(() => {
        this.placeSelected = item
        const resultado = this.ticketsAvailable.filter((itemx) => itemx.sitio === item.id)
        this.ticketsForSelectedPlace = resultado

        if (container) container.style.opacity = 1
      }, 500)
    },
    changeAmountOfTicket: function () {
      let totalForSelectedPlace = 0
      for (let x = 0; x < this.ticketsForSelectedPlace.length; x++) {
        const { cantidad, costo } = this.ticketsForSelectedPlace[x]
        totalForSelectedPlace += cantidad ? costo * parseInt(cantidad) : 0
      }

      const placeSeleted = this.placesAvailable.find(place => place.id === this.placeSelected.id)
      placeSeleted.cantidad = totalForSelectedPlace

      let totalForSale = 0
      this.placesAvailable.forEach(place => { totalForSale += place.cantidad })
      this.setTotalForSale(totalForSale)
    }
  }
}
</script>

<style scoped src="../../assets/css/home-wholesalers.css"></style>
