<template>
  <v-dialog
    v-model="dialog"
    max-width="700px"
    persistent
  >
    <v-card>
      <v-toolbar
        dark
        class="mt-n1"
      >
        <v-toolbar-title>
          {{ userForEdit ? 'Editar' : 'Añadir' }} usuario
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          :disabled="loading"
          @click="cancelEditOrAddUser(false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-6">
        <v-form ref="userForm">
          <FormUserData
            :formUser="formUser"
            :roles="roles"
          />
        </v-form>
      </v-card-text>

      <v-divider class="pt-0" />

      <v-card-actions class="container-actions-buttons">
        <v-btn
          :loading="loading"
          :disabled="loading"
          @click="cancelEditOrAddUser(false)"
        >
          Cancelar
        </v-btn>
        <v-btn
          :loading="loading"
          :disabled="loading"
          class="primary"
          color="#df8157 !important"
          @click="saveUserData"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Components
import FormUserData from './FormUserData.vue'

export default {
  name: 'DialogUserForm',
  components: {
    FormUserData
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    userForEdit: {
      type: Object,
      required: false,
      default: null
    },
    roles: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      formUser: null,
      loading: false
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.resetForm()
      }
    }
  },
  watch: {
    dialog: function () {
      this.formUser = this.userForEdit ?? {
        id: '',
        email: '',
        clave: '',
        name: '',
        name2: '',
        name3: '',
        rol: 0,
        active: 1
      }
    }
  },
  methods: {
    resetForm: function () {
      this.formUser = {
        id: '',
        email: '',
        clave: '',
        name: '',
        name2: '',
        name3: '',
        rol: 0,
        active: 1
      }

      this.$refs.userForm.reset()
      this.$refs.userForm.resetValidation()
    },
    cancelEditOrAddUser: function (needRefresh) {
      this.dialog = false
      this.resetForm()
      this.$emit('handleAddOrEditUser', needRefresh)
    },
    saveUserData: function () {
      if (!this.$refs.userForm.validate()) return
      this.loading = true

      this.$root.post('/wholesalers/updateOrSaveUser', this.formUser)
        .then((response) => {
          const data = response.data
          const dataStore = data?.[0]

          if (data?.success === 'FALSE' || dataStore?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data.message || dataStore?.message)
            return
          }

          this.$Toast('success', data.message ?? dataStore?.message)
          this.cancelEditOrAddUser(true)
        }).finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped>
.container-actions-buttons {
  justify-content: space-evenly;
}
</style>
