<template>
  <div id="ApiDashboard">
    <v-tabs
      v-model="apiItemActive"
      height="45px"
      dark
      grow
      show-arrows
      slider-color="orange"
    >
      <v-tab
        id="tab1"
        key="tab-1"
      >
        <v-icon class="ma-2">
          mdi-clipboard-text-outline
        </v-icon>
        Enviados
      </v-tab>
      <v-tab
        id="tab2"
        key="tab-2"
      >
        <v-icon class="ma-2">
          mdi-clipboard-text-clock-outline
        </v-icon>
        Fuera del registro
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="apiItemActive"
      touchless
      style="background-color: transparent !important;"
    >
      <v-tab-item key="tab-1">
        <ShipmentHistory
          v-if="apiItemActive === 0"
          :isActive="!apiItemActive"
          :yesterdayDate="yesterdayDate"
        />
      </v-tab-item>
      <v-tab-item key="tab-2">
        <ShipmentPending
          v-if="apiItemActive === 1"
          :isActive="!apiItemActive"
          :yesterdayDate="yesterdayDate"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// Components
import ShipmentHistory from '../../components/Promtec/ShipmentHistory.vue'
import ShipmentPending from '../../components/Promtec/ShipmentPending.vue'

export default {
  name: 'ApiDashboard',
  components: {
    ShipmentHistory,
    ShipmentPending
  },
  data () {
    return {
      apiItemActive: null,
      yesterdayDate: ''
    }
  },
  mounted () {
    this.yesterdayDate = this.$sumarDias(new Date(), -1)
  }
}
</script>

<style>

</style>
