<template>
  <v-slide-y-reverse-transition>
    <div
      v-if="dialog"
      class="container-print-history"
    >
      <v-card
        class="dialog-print-history"
        outlined
      >
        <v-toolbar
          height="55px"
          dark
        >
          <v-toolbar-title>{{ itemTransaction.linearef }} </v-toolbar-title>
          <v-spacer />
          <v-btn
            color="success"
            class="ma-2 ml-7"
            @click="exportTable"
          >
            Exportar
          </v-btn>
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="loading">
          <v-skeleton-loader type="table" />
        </v-card-text>

        <v-card-text
          v-if="!loading"
          class="contaienr-card-history-transactions"
        >
          <v-card-title style="padding: 5px 25px 5px 26px;">
            <v-spacer />
            <v-text-field
              v-model="search"
              placeholder="Buscar"
              v-bind="attrsSearching"
            />
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="itemTransactionData"
            item-key="brcode"
            class="m-4 mt-2"
            :search="search"
            multi-sort
            mobile-breakpoint="Infinity"
          >
            <template v-slot:body="{ items }">
              <tbody v-if="!items.length">
                <td colspan="20">
                  <v-alert
                    class="mb-0 text"
                    align="center"
                  >
                    Sin datos para esta búsqueda
                  </v-alert>
                </td>
              </tbody>
              <tbody v-else>
                <template v-for="(ticketTransac, index2) in items">
                  <tr
                    :key="'history-transact-corps-' + ticketTransac.folio + '-br-' + ticketTransac.brcode"
                    style="color: black"
                    :style="{ 'background-color': $backgroundRowTable(index2), 'border-top':'2px solid #75695f' }"
                  >
                    <td align="center">
                      <strong>{{ ticketTransac.folioimp ? ticketTransac.folio : '' }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.NJOURNALNO }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.brcode }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.folioimp }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.fecha_creacion ? $formatFecha(ticketTransac.fecha_creacion, '/') : '' }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.descripcion }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.alias }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.shortname }}</strong>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-data-table>

          <div
            v-show="false"
            :id="idPrint"
          >
            <table style="width:100%;">
              <tr align="right">
                <td
                  colspan="9"
                  style="font-size: 16px;"
                >
                  <strong>Boletos de: </strong> {{ selectedCorp.name }}
                </td>
              </tr>
              <tr align="right">
                <td
                  colspan="9"
                  style="font-size: 16px;"
                >
                  <strong>Línea de Referencia: </strong> {{ itemTransaction.linearef }}
                </td>
              </tr>
              <tr align="right">
                <td
                  colspan="9"
                  style="font-size: 16px;"
                >
                  <strong>Al día: </strong> {{ $formatFecha(nowDate, '/') }}
                </td>
              </tr>
              <tr />
            </table>

            <div>
              <table
                border="1"
                bordercolor="#272727"
                class="styled-table"
                style="border-collapse: collapse; width:100%;"
              >
                <thead>
                  <tr>
                    <td />
                    <td align="center">
                      <strong>Folio</strong>
                    </td>
                    <td align="center">
                      <strong>Journal</strong>
                    </td>
                    <td align="center">
                      <strong>Barcode</strong>
                    </td>
                    <td align="center">
                      <strong>Folio de Impresion</strong>
                    </td>
                    <td align="center">
                      <strong>Fecha de Impresión</strong>
                    </td>
                    <td align="center">
                      <strong>Nombre del Boleto</strong>
                    </td>
                    <td align="center">
                      <strong>Alias del Boleto</strong>
                    </td>
                    <td align="center">
                      <strong>Parador Turistico</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(ticketTransac, index2) in itemTransactionData"
                    :key="`sitioReportTicketsTransac-${ticketTransac.id}-${index2}`"
                  >
                    <td align="center">
                      <strong>{{ index2 + 1 }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.folioimp ? ticketTransac.folio : '' }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.NJOURNALNO }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.brcode }}&nbsp;</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.folioimp }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.fecha_creacion ? $formatFecha(ticketTransac.fecha_creacion, '/') : '' }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.descripcion }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.alias }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ ticketTransac.shortname }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-slide-y-reverse-transition>
</template>

<script>
export default {
  name: 'ImpressionHistoryTicketsCorps',
  props: ['value', 'loading', 'nowDate', 'itemTransaction', 'itemTransactionData', 'selectedCorp'],
  data () {
    return {
      attrsSearching: {
        label: '',
        clearable: true,
        dense: true,
        color: 'orange',
        'prepend-icon': 'mdi-magnify',
        class: 'search-text-field mr-4',
        'hide-details': true
      },
      search: '',
      headers: [
        { text: 'Folio', value: 'folio', align: 'center' },
        { text: 'Journal', value: 'NJOURNALNO', align: 'center' },
        { text: 'Barcode', value: 'brcode', align: 'center' },
        { text: 'Folio de Impresión', value: 'folioimp', align: 'center' },
        { text: 'Fecha de Impresión', value: 'fecha_creacion', align: 'center' },
        { text: 'Nombre del Boleto', value: 'descripcion', align: 'center' },
        { text: 'Alias del Boleto', value: 'alias', align: 'center' },
        { text: 'Parador Turistico', value: 'shortname', align: 'center' }
      ]
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    idPrint: function () {
      return `history-tickets-generated-by-transaction-${this.itemTransaction.linearef}`
    },
    fileName: function () {
      return `${this.selectedCorp.name}-historial_impresiones_de_${this.itemTransaction.linearef}_a_${this.$formatFecha(this.nowDate, '-')}`
    }
  },
  methods: {
    exportTable: function (event) {
      this.$exportExcelFromJs(event, this.fileName, this.idPrint)
    }
  }
}
</script>

<style scoped>
.container-print-history {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
}

.dialog-print-history {
  min-width: 100%;
  min-height: 100%;
}

.contaienr-card-history-transactions {
  height: calc(100vh - var(--h-navbar) - 45px - 55px);
  overflow: hidden;
  overflow-y: auto;
}

.search-text-field {
  max-width: 400px !important;
}

.place-name {
  background: white;
  color: black;
  padding: 0 !important;
  padding-left: 5px !important;
  font-size: 17px !important;
  font-weight: bold;
}
</style>

<style scoped src="../../../assets/css/style-tables-dark.css">
</style>
