export default {
  methods: {
    getDateCode: async function (dateTransaction, errorResponse = null) {
      try {
        if (!dateTransaction) {
          if (errorResponse) throw new Error(errorResponse)
          return Promise.resolve(false)
        }

        let date = dateTransaction.split('-')
        if (date.length === 1) date = date.split('/')
        if (date[2].length === 4) date = date.reverse()
        for (let x = 0; x < date.length; x++) date[x] = Number(date[x])

        const yearCode = (date[0] - 2013) * 372
        const monthCode = (date[1] - 1) * 31
        const dayCode = date[2] - 1
        let dateCode = ''
        const numericDateCode = '' + Number(yearCode + monthCode + dayCode)

        for (let x = 0; x < 4; x++) {
          if (numericDateCode[x]) {
            dateCode = dateCode + numericDateCode[x]
          } else {
            dateCode = '0' + dateCode
          }
        }
        return Promise.resolve(dateCode)
      } catch (error) {
        if (errorResponse) throw new Error(errorResponse)
        return Promise.resolve(false)
      }
    },
    getTotalCode: async function (totalTransaction = '', errorResponse = false) {
      try {
        if (!totalTransaction || isNaN(totalTransaction)) {
          if (errorResponse) throw new Error(errorResponse)
          return Promise.resolve(false)
        }
        const total = this.$formatPrice(totalTransaction).replaceAll(',', '')
        let totalCode = ''
        let weights = ''

        if (total.length < 12) {
          weights = '71371371.37'
          for (let x = 0; x < 11; x++) {
            if (total[x]) {
              totalCode = totalCode + total[x]
            } else {
              totalCode = '0' + totalCode
            }
          }
        } else {
          totalCode = total
          let counter = 0
          for (let x = 0; x < total.length; x++) {
            if (total[x] === '.') {
              weights = weights + '.'
              continue
            }
            switch (counter) {
              case 0:
                weights += '7'
                counter = 1
                break
              case 1:
                weights += '1'
                counter = 2
                break
              case 2:
                weights += '3'
                counter = 0
                break
            }
          }
        }

        let totalValue = 0
        for (let x = 0; x < weights.length; x++) {
          if (totalCode[x] !== '.') {
            totalValue += parseInt(totalCode[x]) * parseInt(weights[x])
          }
        }

        let realTotalCode = 0
        let validateDoWhile = true

        do {
          const y = totalValue % 10
          if (y === realTotalCode) {
            validateDoWhile = false
          } else {
            realTotalCode = y
          }
        } while (validateDoWhile)
        const finalTotalCode = '' + realTotalCode
        return Promise.resolve(finalTotalCode)
      } catch (error) {
        if (errorResponse) throw new Error(errorResponse)
        return Promise.resolve(false)
      }
    },
    getReferenceCode: async function (RefOriX, constante, residuo, errorResponse = false) {
      try {
        if (!RefOriX || RefOriX.length < 24) {
          if (errorResponse) throw new Error(errorResponse)
          return Promise.resolve(false)
        }

        let constant = 2
        const RefOri = RefOriX.substring(0, 24) + constant
        let ponde = []
        let con = 11
        let Zuma = 0

        for (let x = 0; x < RefOri.length; x++) {
          ponde.push(con)
          con = con + constant
          constant = constant + constante

          if (con >= 25) {
            con = 11
            constant = constante
          }
          if (constant === 6) {
            constant = constante
          }
        }
        ponde = ponde.reverse()

        for (let x = 0; x < ponde.length; x++) {
          Zuma = Zuma + parseInt(RefOri[x]) * parseInt(ponde[x])
        }

        let y = (Zuma % residuo) + 1
        y = (y < 10 ? '0' : '') + parseInt(y)

        const data = { Refclave: y, folioRef: RefOriX.substring(0, 19) }
        return Promise.resolve(data)
      } catch (error) {
        if (errorResponse) throw new Error(errorResponse)
        return Promise.resolve(false)
      }
    }
  }
}
