<template>
  <SecctionFormStyled subtitle="Datos de usuario">
    <v-form
      ref="formUserData"
      lazy-validation
    >
      <FormUserData
        :formUser="formUserComputed"
        :isAddUser="false"
      />

      <ActionsButtonsForms
        :show="showButtons"
        acceptText="Guardar cambios"
        @cancel="cancelAccountSetting"
        @accept="saveAccountSetting"
      />
    </v-form>
  </SecctionFormStyled>
</template>

<script>
// Components
import SecctionFormStyled from '../../tools/SecctionFormStyled.vue'
import FormUserData from '../FormUserData.vue'
import ActionsButtonsForms from '../../tools/ActionsButtonsForms.vue'

export default {
  name: 'UserDataSettings',
  components: { SecctionFormStyled, FormUserData, ActionsButtonsForms },
  props: {
    formUser: {
      type: Object,
      require: true,
      default () {
        return {
          active: 1,
          clave: '',
          email: '',
          id: '',
          name: '',
          name2: '',
          name3: '',
          rol: 0
        }
      }
    }
  },
  data () {
    return {
      firtChange: true,
      showButtons: false
    }
  },
  computed: {
    formUserComputed: function () {
      return this.formUser
    }
  },
  watch: {
    formUser: function () {
      this.firtChange = true
      this.showButtons = false
    },
    formUserComputed: {
      handler: function () {
        if (this.firtChange) {
          this.firtChange = false
          return
        }
        if (!this.showButtons) this.showButtons = true
      },
      deep: true
    }
  },
  methods: {
    resetForm: function () {
      this.$refs?.formUserData?.reset()
      this.$refs?.formUserData?.resetValidation()
    },
    cancelAccountSetting: function () {
      this.$emit('cancel')
      this.resetForm()
    },
    saveAccountSetting: function () {
      if (!this.$refs.formUserData.validate()) return this.$Toast('error', 'Verifique los campos')

      this.$root.post('/wholesalers/updateUser', this.formUser)
        .then((response) => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            this.$emit('cancel')
            return
          }

          this.$swalAlert('success', 'Datos modificados', null)
          this.$emit('success')
        })
    }
  }
}
</script>

<style>

</style>
