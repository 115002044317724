<template>
  <div id="ReportSalesWholesalersTable">
    <StyledTableReport
      :headers="headersReport"
      :details="detailsReport"
      :title="titleReport"
      :showTotals="false"
      :titleColspan="headersDetailsSalePlace.length"
      center
    >
      <tr
        v-for="cashierData in detUserMayo"
        :key="'cashier-data-'+cashierData.name"
      >
        <td>
          {{ cashierData.name }} {{ cashierData.name2 }} {{ cashierData.name3 }}
        </td>
        <td
          align="center"
          class="text-center"
        >
          {{ $formatNum(cashierData.operaciones) }}
        </td>
        <td
          align="right"
          class="text-right"
        >
          ${{ $formatPrice(cashierData.total) }}
        </td>
      </tr>
      <tr class="spacer-styled-table">
        <th
          align="right"
          class="text-right"
          style="border-top: 2px double black;"
        >
          Totales:
        </th>
        <th
          align="center"
          class="text-center"
          style="border-top: 2px double black;"
        >
          {{ $formatNum(getTotalOperaciones) }}
        </th>
        <th
          align="right"
          class="text-right"
          style="border-top: 2px double black;"
        >
          ${{ $formatPrice(totalMayo) }}
        </th>
      </tr>
    </StyledTableReport>

    <v-divider class="mb-2" />
    <StyledTableReport
      :headers="headersDetailsSalePlace"
      title="Detalle de ventas por sitio"
      :showTable="false"
      center
    />
    <StyledTableReport
      v-for="(place, index) in detMayo"
      :key="`item-details-sales-by-place-${place.sitioId}`"
      :titleTable="place.sitio"
      :headers="headersDetailsSalePlace"
      :includeCajes="false"
      :spacer="!!index"
      :totals="{totalTickets : place.Cantidad, total: place.total}"
    >
      <tr
        v-for="ticket in place.data"
        :key="`item-details-sales-place-${place.sitioId}-ticket-${ticket.id_ticket}`"
      >
        <td>{{ ticket.nombre }}</td>
        <td align="center">
          {{ $formatNum(ticket.cantidad) }}
        </td>
        <td align="right">
          ${{ $formatPrice(ticket.costo) }}
        </td>
        <td align="right">
          ${{ $formatPrice(ticket.total) }}
        </td>
      </tr>
    </StyledTableReport>

    <v-divider class="mb-2" />
    <StyledTableReport
      :headers="headersDetailsSalePlace"
      title="Detalle de ventas por cajero"
      :showTable="false"
      center
    />
    <StyledTableReport
      v-for="(cashier, index) in detailCashier"
      :key="`item-details-sales-by-cashier-${cashier.cajero}`"
      :spacer="!!index"
      :headers="headersReportSaleCashier"
      :showTotals="false"
    >
      <tr>
        <td align="center">
          {{ cashier.name }} {{ cashier.name2 }} {{ cashier.name3 }}
        </td>
        <td align="center">
          {{ $formatNum(cashier.cantidadCajero) }}
        </td>
        <td align="right">
          ${{ $formatPrice(cashier.totalCajero) }}
        </td>
      </tr>
      <tr>
        <td :colspan="headersReportSaleCashier.length">
          <StyledTableReport
            v-for="(place, indexPlace) in cashier.sitios"
            :key="`item-details-sales-by-cashier-${cashier.cajero}-place-${place.sitioId}`"
            :titleTable="place.sitioName"
            :headers="headersDetailsSalePlace"
            :includeCajes="false"
            :spacer="!!indexPlace"
            :totals="{totalTickets : place.cantidadSitio, total: place.total}"
          >
            <tr
              v-for="ticket in place.detalleSitio"
              :key="`item-details-sales-place-${place.sitioId}-ticket-${ticket.id_ticket}`"
            >
              <td>{{ ticket.nombre }}</td>
              <td align="center">
                {{ $formatNum(ticket.cantidad) }}
              </td>
              <td align="right">
                ${{ $formatPrice(ticket.costo) }}
              </td>
              <td align="right">
                ${{ $formatPrice(ticket.total) }}
              </td>
            </tr>
          </StyledTableReport>
        </td>
      </tr>
    </StyledTableReport>
  </div>
</template>

<script>
// Components
import StyledTableReport from '../tools/reports/StyledTableReport.vue'

export default {
  name: 'ReportSalesWholesalersTable',
  components: { StyledTableReport },
  props: [
    'dateStart',
    'dateEnd',
    'titleReport',
    'detMayo',
    'detUserMayo',
    'totalMayo',
    'detailCashier'
  ],
  data () {
    return {
      headersReport: [
        { name: 'Cajero', attrs: { } },
        { name: 'Total de transacciones', attrs: { } },
        { name: 'Total', attrs: { } }
      ],
      detailsReport: [
        {
          key: 'Fecha',
          value: `${this.$formatFecha(this.dateStart)} al ${this.$formatFecha(this.dateEnd)}`,
          attrs: { class: 'pt-2' }
        }
      ],
      headersDetailsSalePlace: [
        { name: 'Nombre del boleto', attrs: { width: '40%' } },
        { name: 'Cantidad', attrs: { width: '20%' } },
        { name: 'Precio unitario', attrs: { width: '20%' } },
        { name: 'Costo', attrs: { width: '20%' } }
      ],
      headersReportSaleCashier: [
        { name: 'Cajero', attrs: { width: '40%' } },
        { name: 'Cantidad de boletos', attrs: { width: '30%' } },
        { name: 'Total', attrs: { width: '30%' } }
      ]
    }
  },
  computed: {
    getTotalOperaciones: function () {
      let total = 0
      this.detUserMayo.forEach((element) => {
        total += element.operaciones
      })
      return total
    }
  }
}
</script>

<style>

</style>
