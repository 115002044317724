var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"box-shadow":"none !important"}},[_c('v-data-table',{attrs:{"headers":_vm.headers[_vm.tab],"items":_vm.items,"item-key":"id","sort-by":['fecha'],"sort-desc":[true],"search":_vm.search,"multi-sort":"","mobile-breakpoint":"Infinity"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return undefined},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [(!items.length)?_c('tbody',[_c('td',{attrs:{"colspan":"20"}},[_c('v-alert',{staticClass:"mb-0 text",attrs:{"align":"center"}},[_vm._v(" Sin datos para esta búsqueda ")])],1)]):_c('tbody',[_vm._l((items),function(cartTransaction,index){return [_c('tr',{key:("cart-transaction-item-" + (cartTransaction.id)),staticClass:"row-transaction-data",style:({ 'background-color': _vm.$backgroundRowTable(index) }),on:{"click":function($event){return _vm.clickrow(cartTransaction.id)}}},[_c('td',{staticStyle:{"max-width":"10px"}},[_c('v-icon',{staticClass:"ma-2"},[_vm._v(" mdi-"+_vm._s(cartTransaction.id === _vm.expanded ? 'minus' : 'plus')+" ")])],1),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(cartTransaction.linearef)+" ")]),_c('td',{style:(_vm.tab === 4 ? 'color: red; font-weight: bold': '')},[_vm._v(" "+_vm._s(cartTransaction.fvenci)+" ")]),_c('td',{style:(_vm.tab === 1 ? 'color: green; font-weight: bold' : '')},[_vm._v(" "+_vm._s(cartTransaction.fecha)+" ")]),_c('td',{attrs:{"align":"right"}},[_c('strong',[_vm._v("$"+_vm._s(_vm.$formatPrice(cartTransaction.total))+" "),_c('ExchangeCurrency')],1)]),(_vm.tab === 1 || _vm.tab === 2)?_c('td',{style:(_vm.tab === 1 ? 'color: green; font-weight: bold' : ''),attrs:{"align":"center"}},[_vm._v(" "+_vm._s(cartTransaction.solicito)+" ")]):_vm._e(),(_vm.tab === 2 || _vm.tab === 3)?_c('td',{attrs:{"align":"center"}},[_c('strong',{style:(_vm.tab === 2 ? 'color: blue' : '')},[_vm._v(" "+_vm._s(cartTransaction.f_autorizacion)+" ")])]):_vm._e(),(_vm.tab === 1)?_c('td',{staticStyle:{"min-width":"170px"},attrs:{"align":"center"}},[(_vm.canEdit)?_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","dark":""},on:{"click":function($event){return _vm.print(cartTransaction)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}],null,true)},[_c('span',[_vm._v("Imprimir orden de pago")])]):_vm._e()],1):_vm._e(),(_vm.tab === 2)?_c('td',{attrs:{"align":"center"}},[(_vm.canEdit)?_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","dark":""},on:{"click":function($event){return _vm.print(cartTransaction)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}],null,true)},[_c('span',[_vm._v("Imprimir recibo de pagos")])]):_vm._e()],1):_vm._e(),(_vm.tab === 3)?_c('td',{attrs:{"align":"center"}},[_c('strong',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(cartTransaction.f_bloqueo))])]):_vm._e()]),(cartTransaction.id === _vm.expanded)?_c('tr',{key:("cart-transaction-details-" + (cartTransaction.id)),staticStyle:{"background-color":"white !important"}},[_c('TicketsDetailsTransactions',{attrs:{"placeDetails":cartTransaction.place,"transactionId":cartTransaction.id}})],1):_vm._e()]})],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }