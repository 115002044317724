<template>
  <div id="GenerateTickets">
    <v-alert
      v-if="!availableTickets.length"
      icon="mdi-folder-alert-outline"
      prominent
      text
      type="warning"
      align="left"
    >
      No hay boletos disponibles para generar
    </v-alert>
    <div v-else>
      <div
        v-if="canPrint"
        class="text-center text-h5 font-weight-bold black--text"
      >
        Máximo de
        <span class="red--text text--lighten-1">
          {{ maxTicketsForPrint }}
        </span>
        boletos por transacción
      </div>
      <v-simple-table
        v-for="(placeData, index) in availableTickets"
        :key="'sitios-available-tickets-' + index"
        class="mb-4"
      >
        <thead>
          <tr>
            <td class="place-title">
              {{ placeData.name }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr style="background-color: rgba(255, 165, 0, 0.4)">
            <td>
              <v-simple-table>
                <thead>
                  <tr style="background: rgb(253 142 48 / 22%); color: black">
                    <td width="15%">
                      <strong>Tipo de boleto</strong>
                    </td>
                    <td width="30%">
                      <strong>Nombre del Boleto</strong>
                    </td>
                    <td
                      align="center"
                      style="width:15%"
                    >
                      <strong>Fecha Limite de uso</strong>
                    </td>
                    <td
                      align="center"
                      width="15%"
                    >
                      <strong>Disponibles(s)</strong>
                    </td>
                    <td
                      v-if="canPrint"
                      align="center"
                      style="min-width: 213px"
                    >
                      <strong>Utilizar</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="sitioTicket in placeData.data"
                    :key="`table-available-tickets-place-item-${sitioTicket.id_ticket}-${sitioTicket.persontype}-${sitioTicket.npool}`"
                  >
                    <td align="center">
                      <strong>{{ sitioTicket.id_ticket }}</strong>
                    </td>
                    <td>
                      <strong>{{ sitioTicket.descripcion }} </strong>
                      <span v-if="sitioTicket.alias"> ({{ sitioTicket.alias }})</span>
                    </td>
                    <td
                      align="center"
                      class="red--text font-weight-bold"
                    >
                      {{ sitioTicket.Ffin }}
                    </td>
                    <td
                      align="center"
                      class="font-weight-bold"
                    >
                      {{ sitioTicket.max }}
                    </td>
                    <td
                      v-if="canPrint"
                      align="center"
                    >
                      <InputAmountTickets
                        v-model="sitioTicket.cantidad"
                        :checkFirtsPress="true"
                        :checkMaxValue="true"
                        :max="sitioTicket.max"
                        :amountAddOrLess="1"
                        @change="changeOnTicketsAvailable(sitioTicket)"
                      />
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <DialogPrintDetailsForm
      v-model="dialogPrintDetails"
      :ticketsForPrint="ticketsForPrint"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// Components
import DialogPrintDetailsForm from '../../components/wholesalers/DialogPrintDetailsForm.vue'

export default {
  name: 'GenerateTickets',
  components: {
    DialogPrintDetailsForm
  },
  props: {
    value: {
      type: Number,
      required: true,
      default: 0
    },
    availableTickets: {
      type: Array,
      required: true,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      maxTicketsForPrint: 100,
      ticketsForPrint: [],
      dialogPrintDetails: false
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData'
    }),
    canPrint () {
      return this.userData?.subrol < 2
    },
    totalForGenerate: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    changeOnTicketsAvailable: function (ticketChanged) {
      const totalWithoutChanged = this.getTotalWithoutChanged(ticketChanged)
      const amountTicketChanged = Number(ticketChanged.cantidad) || 0
      const maxForTicket = String(this.maxTicketsForPrint - totalWithoutChanged)

      if ((totalWithoutChanged + amountTicketChanged) > this.maxTicketsForPrint) {
        ticketChanged.cantidad = maxForTicket
        this.$Toast('warning', `Máximo de ${this.maxTicketsForPrint} boletos por transacción`)
      }

      let totalTickets = 0
      this.ticketsForPrint = []
      this.availableTickets.forEach((place) => {
        const { data, name: nameSitio } = place

        data.forEach((ticket) => {
          const { descripcion: name, sitio, persontype, id_ticket, npool, NTARIFBLATTNR, NTARIFBLATTGUELTNR } = ticket
          const cantidad = Number(ticket.cantidad) || 0

          if (cantidad === 0) return
          totalTickets += cantidad
          this.ticketsForPrint.push({
            id_ticket,
            name,
            sitio,
            cantidad,
            nameSitio,
            persontype,
            npool,
            NTARIFBLATTNR,
            NTARIFBLATTGUELTNR
          })
        })
      })
      this.totalForGenerate = totalTickets
    },
    getTotalWithoutChanged: function (ticketChanged) {
      let totalTickets = 0
      this.ticketsForPrint.forEach((ticket) => {
        const cantidad = Number(ticket.cantidad) || 0
        if (ticketChanged.id_ticket === ticket.id_ticket) return

        totalTickets += cantidad
      })

      return totalTickets
    },
    confirmPrintTickets: function () {
      this.dialogPrintDetails = true
    }
  }
}
</script>

<style scoped>
.place-title {
  background: white;
  padding: 5px !important;
  height: 40px;
  font-size: 20px !important;
  font-weight: bold;
}
</style>
