<template>
  <div id="UsersWholesalers">
    <v-card>
      <v-card-title>
        <v-btn
          small
          color="success"
          @click="showUserSpecifications = true"
        >
          <v-icon class="mr-2">
            mdi-eye
          </v-icon>
          Especificaciones de usuarios
        </v-btn>

        <v-spacer />
        <v-text-field
          v-if="users.length"
          v-model="search"
          class="pt-0"
          label=""
          color="orange"
          placeholder="Buscar"
          prepend-icon="mdi-magnify"
          clearable
          single-line
          hide-details
        />
      </v-card-title>
      <v-card-text v-if="loading">
        <v-skeleton-loader
          class="ma-4"
          type="table"
        />
      </v-card-text>
      <v-card-text v-else>
        <v-alert
          v-if="!users.length"
          icon="mdi-folder-alert-outline"
          prominent
          text
          type="warning"
          align="left"
        >
          Aún no hay usuarios
        </v-alert>
        <v-data-table
          v-else
          :headers="headers"
          :items="users"
          item-key="id"
          class="styled-table m-4 mt-2"
          :sort-desc="[true, true]"
          :search="search"
          multi-sort
          mobile-breakpoint="Infinity"
        >
          <template v-slot:body="{ items }">
            <tbody v-if="!items.length">
              <td colspan="20">
                <v-alert
                  class="mb-0 text"
                  align="center"
                >
                  Sin datos para esta búsqueda
                </v-alert>
              </td>
            </tbody>
            <tbody v-else>
              <tr
                v-for="(user, index) in items"
                :key="`item-user-${user.id}-${index}`"
                :style="{ 'background-color': $backgroundRowTable(index), 'border-top':'2px solid #75695f' }"
                @click="addOrEditUser(user)"
              >
                <td>{{ user.email }}</td>
                <td>{{ hiddePasswords(user.clave) }}</td>
                <td>{{ user.rolDescription }}</td>
                <td>
                  {{ `${user.name} ${user.name2} ${user.name3}` }}
                </td>
                <td align="center">
                  <v-tooltip
                    bottom
                    color="black"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        elevation="1"
                        dark
                        :color="user.active ? 'green' : '#f07f3a !important'"
                        v-bind="attrs"
                        @click="changeStatus(user)"
                        v-on="on"
                      >
                        <v-icon style="font-size: 25px">
                          mdi-account
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ user.active ? 'Desactivar': 'Activar' }} usuario</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <DialogUsersSpecifications v-model="showUserSpecifications" />
    <DialogUserForm
      v-model="showUserForm"
      :userForEdit="userForEdit"
      :roles="roles"
      @handleAddOrEditUser="handleAddOrEditUser"
    />

    <v-btn
      dark
      fab
      fixed
      bottom
      right
      @click="addOrEditUser(null)"
    >
      <v-icon>
        mdi-account-plus
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

// Mixins
import WHOLESALERS from '../../minixs/wholesalers'

// Components
import DialogUsersSpecifications from '../../components/wholesalers/DialogUsersSpecifications.vue'
import DialogUserForm from '../../components/wholesalers/DialogUserForm.vue'

export default {
  name: 'UsersWholesalers',
  components: {
    DialogUsersSpecifications,
    DialogUserForm
  },
  mixins: [WHOLESALERS],
  data () {
    return {
      headers: [
        { text: 'Correo', value: 'email' },
        { text: 'Contraseña', value: 'clave', sortable: false },
        { text: 'Rol', value: 'rolDescription' },
        { text: 'Nombre', value: 'name', align: 'left' },
        { text: 'Activo/Desactivo', value: 'active', align: 'center', sortable: false }
      ],
      search: '',
      loading: false,
      flag: true,
      users: [],
      roles: [],
      showUserSpecifications: false,
      showUserForm: false,
      userForEdit: null
    }
  },
  computed: {
    canEdit () {
      return this.userData?.subrol < 1
    }
  },
  mounted () {
    if (!this.canEdit) {
      this.$router.push({ name: 'Mayoristas' })
      return
    }
    this.getUsers()
  },
  methods: {
    ...mapMutations({
      setLoadingMainApp: 'globalStates/setLoadingMainApp'
    }),
    getUsers: function () {
      this.loading = true
      this.userForEdit = null

      this.$root.post('/wholesalers/usersList')
        .then(
          (response) => {
            const data = response.data

            if (data.success === 'FALSE') {
              this.$swalAlert('error', 'Error', data.message)
              return
            }

            this.users = data?.[0]
            this.roles = data?.[1]
          }
        ).finally(() => { this.loading = false })
    },
    addOrEditUser: function (user) {
      if (!this.flag) {
        this.flag = true
        return
      }

      this.showUserForm = true
      this.userForEdit = JSON.parse(JSON.stringify(user))
    },
    changeStatus: async function (user) {
      this.flag = false
      const response = await this.$ConfirmBox(
        'warning',
        '¿Esta seguro?',
        `Desea ${user.active ? 'desactivar' : 'activar'} usuario`
      )
      if (!response.isConfirmed) return
      this.loading = true

      user.active = Number(!user.active)
      this.$root.post('/wholesalers/updateOrSaveUser', user)
        .then((response) => {
          const data = response?.data
          const dataStore = data?.[0]

          if (data?.success === 'FALSE' || dataStore?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data.message || dataStore?.message)
            return
          }

          this.$Toast('success', data.message ?? dataStore?.message)
          this.getUsers()
        }).finally(() => { this.loading = false })
    },
    handleAddOrEditUser: function (needRefresh) {
      if (needRefresh) this.getUsers()
      this.userForEdit = null
    },
    hiddePasswords: function (pass) {
      let hiddePass = ''
      for (let index = 0; index < pass.length; index++) {
        hiddePass += '●'
      }
      return hiddePass
    }
  }
}
</script>

<style scoped>
#UsersWholesalers {
  position: relative;
  min-height: calc(100vh - var(--h-navbar));
  padding: 40px;
  background-color: var(--bg-gray);
}

@media (max-width: 960px) {
  #UsersWholesalers {
    padding: 20px;
  }
}

@media (max-width: 500px) {
  #UsersWholesalers {
    padding: 20px 10px;
  }
}
</style>

<style scoped src="../../assets/css/style-tables-dark.css">
</style>
