<template>
  <div id="TicketSetup">
    <v-card
      flat
      class="mx-8 my-3 style-card"
    >
      <v-card-title>
        {{ title }}
        <v-spacer />
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              dark
              color="primary"
              v-bind="attrs"
              @click="clearFilter"
              v-on="on"
            >
              <v-icon class="mr-1">
                mdi-broom
              </v-icon>Filters
            </v-btn>
          </template>
          <span>Clear all filters</span>
        </v-tooltip>

        <v-tooltip
          v-if="showAxessTickets"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="showAxessTickets"
              class="mx-2"
              small
              dark
              :loading="loadingBtn"
              v-bind="attrs"
              @click="exportAxessConfig"
              v-on="on"
            >
              <v-icon class="mr-1">
                mdi-database-export
              </v-icon>Export
            </v-btn>
          </template>
          <span>Export to Promtec config </span>
        </v-tooltip>

        <v-tooltip
          v-if="showAxessTickets"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="showAxessTickets"
              small
              dark
              :loading="loadingBtn"
              v-bind="attrs"
              @click="reLoadAxessConfig"
              v-on="on"
            >
              <v-icon class="mr-1">
                mdi-database-refresh
              </v-icon>Reload
            </v-btn>
          </template>
          <span>Reload Table</span>
        </v-tooltip>
      </v-card-title>

      <tableMultifilter
        v-if="!showAxessTickets"
        ref="tableList"
        :headers="headersPromtec"
        :desserts="configPromtec"
        :idkey="'row_num'"
        :showSelect="showSelect"
        :loading="loadingList"
        @clicRowTable="clicRowTable"
      />

      <tableMultifilter
        v-else
        ref="tableRowCheck"
        :headers="headersAxess"
        :desserts="configAxess"
        :idkey="'row_num'"
        :showSelect="true"
        :loading="loadingBtn"
        @getRowCheck="getRowCheck"
      />
    </v-card>

    <v-dialog
      ref="formDialog"
      v-model="dialog"
      max-width="900px"
    >
      <v-card>
        <v-card-title style="background: #272727; color: white;">
          <span class="text-h7">Editar Registro</span>
        </v-card-title>
        <v-card-text>
          <formularyPromtec
            ref="formPromtec"
            :ticketInfo2="ticketInfo"
            :entities="entities"
            :sites="sites"
            @dialogClose="dialogClose"
            @formPromtecSave="formPromtecSave"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Vuex
import { mapMutations, mapState } from 'vuex'

// Components
import tableMultifilter from '../../components/tableMultifilter.vue'
import formularyPromtec from '../../components/Promtec/TicketSetup/formularyPromtec.vue'

export default {
  name: 'TicketSetup',
  components: {
    tableMultifilter,
    formularyPromtec
  },
  data () {
    return {
      showSelect: false,
      loadingList: false,
      loadingBtn: false,
      dialog: false,
      ticketInfo: null,
      headersPromtec: [
        { text: 'Pool', align: 'left', value: 'pool', width: '250px' },
        { text: 'Ticket Type', value: 'tickettype', width: '250px' },
        { text: 'Person Type', value: 'persontype', width: '250px' },
        { text: 'Season', value: 'season', width: '150px' },
        { text: 'Entidad', value: 'nentidad', width: '130px' },
        { text: 'Sucursal', value: 'sitio', width: '180px' },
        { text: 'Comision', value: 'promtec', align: 'right', width: '150px' },
        { text: 'Affy name', value: 'aafy_name', width: '200px' },
        { text: 'wholesalers', value: 'mayo', align: 'center', width: '140px' }
      ],
      headersAxess: [
        { text: 'Pool', align: 'left', value: 'pool', width: '250px' },
        { text: 'Ticket Type', value: 'tickettype', width: '250px' },
        { text: 'Person Type', value: 'persontype', width: '250px' },
        { text: 'Season', value: 'season', width: '150px' },
        { text: 'Day', value: 'day', width: '150px' },
        { text: 'Time', value: 'time', width: '150px' },
        { text: 'Price', value: 'FTARIF', align: 'right', width: '150px' },
        { text: 'Active', value: 'BTARIFAKTIV', align: 'center', width: '150px' }
      ],
      configPromtec: [],
      configAxess: [],
      sites: [],
      entities: []
    }
  },
  computed: {
    ...mapState('dashboard', ['showAxessTickets']),
    title () {
      return this.showAxessTickets ? 'Axess Tickets' : 'Promtec Tickets'
    }
  },
  watch: {
    showAxessTickets (val) {
      this.clearFilter()
      if (val) {
        this.loadConfigurations()
      } else {
        this.initialize()
      }
    }
  },
  mounted () {
    this.setShowAxessTickets(false)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setShowAxessTickets: 'dashboard/setShowAxessTickets'
    }),
    exportAxessConfig () {
      this.$refs.tableRowCheck.getRowCheck()
    },
    reLoadAxessConfig () {
      this.loadConfigurations()
    },
    loadConfigurations () {
      this.loadingBtn = true
      this.configAxess = []

      this.$root.post('/CC/PersonTypeData')
        .then((response) => {
          this.configAxess = response.data
        })
        .finally(() => {
          this.loadingBtn = false
        })
    },
    initialize () {
      this.loadingList = true
      this.configPromtec = []
      this.sites = []
      this.entities = []

      this.$root.post('/CC/Lista')
        .then(response => {
          const data = response.data
          if (data.success === 'FALSE') {
            this.$swalAlert('error', '', data.message)
            return
          }
          this.configPromtec = data[0]
          this.sites = data[1]
          this.entities = data[2]
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingList = false
        })
    },
    clicRowTable (val) {
      this.ticketInfo = val
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.formPromtec.formPromtecLoad()
      })
    },
    getRowCheck (val) {
      if (val.length === 0) {
        this.$swalAlert('warning', null, 'Select a ticket to export')
        return false
      }
      this.loadingBtn = true
      this.$root.post('/CC/exportTicketAxess', { tickets: val })
        .then(() => {
          this.loadConfigurations()
          this.initialize()
        })
    },
    dialogClose () {
      this.dialog = false
    },
    formPromtecSave (ticketInfo) {
      this.$root.post('/CC/save', ticketInfo)
        .then(() => {
          this.$Toast('success', 'SAVE')
          this.initialize()
          this.dialogClose()
        })
    },
    clearFilter () {
      if (this.$refs.tableRowCheck) {
        this.$refs.tableRowCheck.clearFilters()
      } else {
        this.$refs.tableList.clearFilters()
      }
    }
  }
}
</script>

<style scoped>
.style-card {
  height: calc(100vh - 100px);
}

.v-menu__content {
  font-size: 12px !important;
}
</style>
