<template>
  <v-card
    id="SalesByWeekChart"
    class="pa-2 pb-4"
  >
    <v-skeleton-loader
      v-if="loading"
      type="image"
      style="padding:20px;"
    />
    <BarChart
      v-if="!loading"
      :chartData="chartData"
      :options="options"
    />
  </v-card>
</template>

<script>
// Components
import { BarChart } from 'vue-chart-3'

export default {
  name: 'SalesByWeekChart',
  components: { BarChart },
  props: ['placeSelected'],
  data () {
    return {
      loading: false,
      chartData: {
        toggleLegend: true,
        labels: ['Día'],
        datasets: [
          {
            label: 'Lunes',
            data: [],
            backgroundColor: '#FFD750'
          },
          {
            label: 'Martes',
            data: [],
            backgroundColor: '#FFD700'
          },
          {
            label: 'Miercoles',
            data: [],
            backgroundColor: '#FFA500'
          },
          {
            label: 'Jueves',
            data: [],
            backgroundColor: '#FF8C00'
          },
          {
            label: 'Viernes',
            data: [],
            backgroundColor: '#FF8C70'
          },
          {
            label: 'Sabado',
            data: [],
            backgroundColor: '#FF4500'
          },
          {
            label: 'Domingo',
            data: [],
            backgroundColor: '#FF4550'
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: this.toggleLegend ? 'top' : 'bottom'
          },
          title: {
            display: true,
            text: 'Ventas X Semana',
            font: {
              size: 20
            }
          }
        }
      }
    }
  },
  watch: {
    placeSelected: function (place) {
      if (!place) return

      this.getSalesByWeek(place)
    }
  },
  methods: {
    getSalesByWeek: function (placeSelected) {
      this.loading = true

      const data = { placeSelected }
      this.$root.post('/dashboard/getSalesByWeek', data)
        .then((response) => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data?.message)
            return
          }

          const JSontem = JSON.parse(JSON.stringify(this.chartData.datasets))

          if (data) {
            for (let z = 0; z < data.length; z++) {
              JSontem[z].data[0] = data[z].total
            }
          } else {
            for (let z = 0; z < JSontem.length; z++) {
              JSontem[z].data[0] = 0
            }
          }

          this.chartData.datasets = JSON.parse(JSON.stringify(JSontem))
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style>

</style>
