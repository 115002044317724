<template>
  <div id="ReportCommissions">
    <TitleReportsCard
      title="Reporte General De Comisiones Estacionamiento"
      :loading="loading"
      @generateReport="generateReport"
    >
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateStart"
          :newMax="dateEnd"
          label="Seleccione una fecha de inicio"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateEnd"
          label="Seleccione una fecha de fin"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
    </TitleReportsCard>

    <ContentReportsCard
      :loading="loading"
      :generated="generated"
      :noData="!detSitios.length && !detMayo.length"
      selectTypeExport
      :fileName="fileName"
      @exportReport="exportReport"
    >
      <ReportCommissionsParkingTable
        ref="ReportCommissionsTable"
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :detTotales="detTotales"
        :totalCuota="totalCuota"
        :detSitios="detSitios"
        :detMayo="detMayo"
        :detUserMayo="detUserMayo"
        :totalCuotaMayo="totalCuotaMayo"
      />
    </ContentReportsCard>
  </div>
</template>

<script>
// Components
import TitleReportsCard from '../tools/reports/TitleReportsCard.vue'
import InputDatePickerReport from '../tools/reports/InputDatePickerReport.vue'
import ContentReportsCard from '../tools/reports/ContentReportsCard.vue'
import ReportCommissionsParkingTable from './ReportCommissionsParkingTable.vue'

export default {
  name: 'ReportCommissions',
  components: { InputDatePickerReport, TitleReportsCard, ContentReportsCard, ReportCommissionsParkingTable },
  data () {
    return {
      dateStart: '',
      dateEnd: '',
      loading: false,
      generated: false,
      fileName: '',
      detTotales: [],
      totalCuota: 0,
      detSitios: [],
      detMayo: [],
      detUserMayo: [],
      totalCuotaMayo: 0
    }
  },
  mounted () {
    this.dateStart = this.$sumarDias(new Date(), -1)
    this.dateEnd = this.$sumarDias(new Date(), -1)
  },
  methods: {
    generateReport: function () {
      this.loading = true
      this.generated = false

      this.fileName = 'comisiones_estacionamiento_del_' + this.dateStart + '_hasta_' + this.dateEnd

      const data = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd
      }
      this.$root.post('/reports/promtecCommissionsParking', data)
        .then(response => {
          const data = response.data
          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }

          for (let x = 0; x < data.sitios.length; x++) {
            let Cantidad = 0
            let Canje = 0
            for (let y = 0; y < data.sitios[x].tickets.length; y++) {
              Cantidad += data.sitios[x].tickets[y].Cantidad
              Canje += data.sitios[x].tickets[y].Canje
            }
            data.sitios[x].Cantidad = Cantidad
            data.sitios[x].Canje = Canje
            data.sitios[x].Total = Cantidad + Canje
          }

          for (let x = 0; x < data.mayo.length; x++) {
            let cantidad = 0
            for (let y = 0; y < data.mayo[x].tickets.length; y++) {
              cantidad += data.mayo[x].tickets[y].cantidad
            }
            data.mayo[x].Cantidad = cantidad
          }

          this.detTotales = data.totales
          this.totalCuota = data.totalCuota
          this.detSitios = data.sitios
          this.detMayo = data.mayo
          this.detUserMayo = data.user
          this.totalCuotaMayo = data.cuotaMayo
        })
        .finally(() => {
          this.loading = false
          this.generated = true
        })
    },
    exportReport: async function (event) {
      if (!this.detSitios.length && !this.detMayo.length) {
        this.$swalAlert('warning', 'Genera el reporte!')
        return
      }

      const self = this
      const response = await this.$swal.fire({
        title: 'En que formato desea imprimir?',
        input: 'text',
        inputPlaceholder: 'Número de entrega',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonColor: '#3085d6',
        denyButtonColor: '#4caf50',
        cancelButtonColor: '#dd6b55',
        confirmButtonText: 'PDF de entrega',
        denyButtonText: 'Excel',
        cancelButtonText: 'Cancelar',
        preConfirm: (value) => {
          if (!value) {
            self.$swal.showValidationMessage(
              'Se necesita un número de entrega!'
            )
          }
        }
      })
      if (response.isDenied) {
        this.$exportExcelFromJs(event, this.fileName)
      }
      if (response.isConfirmed) {
        this.$refs.ReportCommissionsTable?.convertTablesOnPDF(response.value, this.fileName)
      }
    }
  }
}
</script>

<style scoped>
</style>
