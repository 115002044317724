<template>
  <v-dialog
    id="DialogStateCompany"
    v-model="dialog"
    max-width="750px"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        <span class="text-h6">Cambiar estado de la empresa</span>
        <v-spacer />
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        class="py-3"
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="companyComputed.rfc"
              append-icon="mdi-badge-account-horizontal"
              label="RFC"
              readonly
              hide-details="auto"
              disabled
              :rules="[$rules.required, $rules.isRFC]"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="companyComputed.name"
              append-icon="mdi-domain"
              label="Nombre"
              readonly
              hide-details="auto"
              disabled
              :rules="[$rules.required]"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="companyComputed.address"
              append-icon="mdi-map-marker"
              label="Dirección"
              readonly
              hide-details="auto"
              disabled
              :rules="[$rules.required]"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="companyComputed.phone"
              append-icon="mdi-phone"
              label="Telefono"
              readonly
              hide-details="auto"
              disabled
              :rules="[$rules.required, $rules.isPhone]"
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="companyComputed.state"
              :items="states"
              label="Estatus"
              outlined
              hide-details="auto"
              item-value="value"
              item-text="text"
              :rules="[$rules.requiredOrZero]"
              @change="handleChangeCompanyStatus"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="companyComputed.sentdocument"
              :items="sentdocumentStatesComputed"
              label="Costancia de situación fiscal"
              outlined
              hide-details="auto"
              item-value="value"
              item-text="text"
              :rules="[$rules.requiredOrZero]"
              @change="show = true"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <ActionsButtonsForms
        :loading="loading"
        :show="show"
        class="pb-4"
        @cancel="dialog = false"
        @accept="changeCompanyStatus"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import ActionsButtonsForms from '../../tools/ActionsButtonsForms.vue'
export default {
  name: 'DialogStateCompany',
  components: { ActionsButtonsForms },
  props: ['value', 'company', 'states'],
  data () {
    return {
      show: false,
      loading: false
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        if (!val) {
          this.show = false
          this.loading = false
        }
        this.$emit('input', val)
      }
    },
    sentdocumentStatesComputed () {
      const states = [
        { value: 0, text: 'No enviada' },
        { value: 1, text: 'Enviada' }
      ]

      return states
    },
    companyComputed () {
      return this.company
    }
  },
  methods: {
    changeCompanyStatus () {
      this.loading = true

      const { idcorp, state, sentdocument } = this.companyComputed
      this.$root.post('/wholesalers/changeCompanyStatus', { idcorp, state, sentdocument })
        .then((response) => {
          const data = response.data

          if (data.success === 'FALSE') return this.$swalAlert('error', null, data.message)

          this.$swalAlert('success', null, data.message)
          this.dialog = false
          this.$emit('refreshData')
        })
        .catch(() => {
          this.$swalAlert('error', null, 'No se pudo cambiar el estado de la empresa')
          this.$emit('refreshData')
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleChangeCompanyStatus: function (status) {
      this.show = true
      if (status !== 1) this.companyComputed.sentdocument = 0
    }
  }
}
</script>

<style>

</style>
